import { bg, cyan, inputBorderColorDefault, makeStyles } from "@health/ui";

export const useCommunicationItemStyles = makeStyles()({
  root: {
    cursor: "pointer",
  },
  root1: {
    padding: 15,
    display: "flex",
    fontSize: 14,
    borderBottom: `1px solid ${bg}`,
  },
  avatar: {
    width: 60,
    height: 60,
    borderRadius: "10px",
    background: bg,
    border: `1px solid ${bg}`,
  },
  container: {
    padding: "0px 10px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  selected: {
    background: inputBorderColorDefault,
  },
  countUnReadMsg: {
    margin: "auto 0px",
    background: cyan,
    color: "#fff",
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    borderRadius: 25,
    alignItems: "center",
    fontWeight: 700,
    boxShadow: "0px 3px 6px #00000029",
  },
});
