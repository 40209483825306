import { getMediaLink } from "@health/common";
import React, { FC } from "react";
import { useVoiceMessageStyles } from "./VoiceMessage.styles";
import { VoiceMessageProps } from "./VoiceMessage.types";

export const VoiceMessage: FC<VoiceMessageProps> = ({ record }) => {
  const { classes } = useVoiceMessageStyles();

  return (
    <audio className={classes.root} src={getMediaLink(record?.file)} controls>
      <track kind='captions' />
    </audio>
  );
};
