const usersManagement = "users-management";
const newUser = "new";
const editUser = `edit/:id`;

export const UsersManagementRoutes = {
  path: usersManagement,
  name: "Users Management",
  fullPath: usersManagement,
  users: {
    name: "Users",
    new: {
      Name: "new User",
      path: [usersManagement, newUser].join("/"),
      fullPath: [usersManagement, newUser].join("/"),
    },
    edit: {
      Name: "Edit User",
      path: [usersManagement, editUser].join("/"),
      fullPath: [usersManagement, editUser].join("/"),
    },
  },
};
