import { CardMedia, UserIcon, white } from "@health/ui";
import React, { FC } from "react";

export const SenderImage: FC<any> = ({ avatar }) => {
  return (
    <React.Fragment>
      {avatar?.length > 0 ? (
        <CardMedia sx={{ width: "40px", height: "40px", cursor: "pointer" }} image={avatar} />
      ) : (
        <UserIcon
          fill={white}
          sx={{
            height: "16px",
            width: "16px",
          }}
        />
      )}
    </React.Fragment>
  );
};
