import { useTranslation } from "@health/i18n";
import { Typography } from "@health/ui";
import React, { FC } from "react";
import { ImageMessage } from "../ImageMessage/ImageMessage.component";
import { VoiceMessage } from "../VoiceMessage/VoiceMessage.component";
import { AttachmentsProps } from "./Attachments.types";

export const Attachments: FC<AttachmentsProps> = ({ attachments }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {attachments.map(attachment => {
        const key = `${attachment.id}`;
        if (attachment.contentType.includes("audio")) {
          return <VoiceMessage key={key} record={attachment} />;
        } else if (attachment.contentType.includes("image")) {
          return <ImageMessage key={key} image={attachment} />;
        } else {
          return <Typography key={attachment.id}>{t("Content type not supported")}</Typography>;
        }
      })}
    </React.Fragment>
  );
};
