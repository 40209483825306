import { useTranslation } from "@health/i18n";
import { Button, CustomIcon, orange, white } from "@health/ui";
import React from "react";

export const ChatButton = () => {
  const { t } = useTranslation("vendor");

  return (
    <Button
      startIcon={<CustomIcon icon='chatCloud' color={white} />}
      sx={{
        background: orange,
        color: white,
        margin: 5,
      }}
    >
      {t("You have a chat request")}
    </Button>
  );
};
