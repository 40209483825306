import { useTranslation } from "@health/i18n";
import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { OrdersCard } from "./OrdersCard";
import { useOrdersCardStyles } from "./OrdersCard.styles";

type OrdersEmptyProps = {
  color: string;
  length: number;
};

export const OrdersEmpty: FC<OrdersEmptyProps> = props => {
  const { children, color, length } = props;
  const { t } = useTranslation("vendor");
  const { classes } = useOrdersCardStyles({ color });

  return (
    <>
      {!length ? (
        <Box margin='10px'>
          <OrdersCard color={color}>
            <Typography align='center' className={classes.text} m={"20px"}>
              {t("There are no orders")}
            </Typography>
          </OrdersCard>
        </Box>
      ) : (
        children
      )}
    </>
  );
};
