import { combineErrors } from "@health/common";
import { i18n, useTranslation } from "@health/i18n";
import { AppRoleTypes, Clinician, formatGraphQLError, useGetClinicianLazyQuery } from "@health/queries";
import { useToasts } from "@health/ui";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "shared/constants";

export const useUserFormHook = ({ errors, user, onDone, isEditMode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const { ...methods } = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors: errorsForm },
  } = methods;
  const { addToast } = useToasts();

  const appRole = watch("appRole");

  const doYouHaveData = isEditMode ? user != undefined : true;
  const [getClinician, { loading: isUserByNatIdLoading }] = useGetClinicianLazyQuery({
    onCompleted: response => {
      if (response.getClinician) {
        handleNatIdDataResponse(response.getClinician as Clinician);
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedErrors = formatGraphQLError(graphQLErrors);
      addToast(t(formattedErrors), {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });
  const handleNatIdDataResponse = natIdFullData => {
    if (!natIdFullData) return;
    setValue("firstName", natIdFullData?.firstName);
    setValue("lastName", natIdFullData?.lastName);
    setValue("email", natIdFullData?.email);
    setValue("mobile", isNaN(natIdFullData?.phone || "--") ? "" : natIdFullData?.phone);
    setValue("password", natIdFullData?.tempPassword);
    setValue("healthLicenseNumber", natIdFullData?.license);
    setValue("healthLicenseStartDate", natIdFullData?.licenseStart);
    setValue("healthLicenseEndDate", natIdFullData?.licenseEnd);
    setValue("appRole", { value: AppRoleTypes.User, name: "User" });
  };

  const handleSearchByNatId = natId => {
    getClinician({
      variables: {
        nationalId: natId,
      },
    });
  };
  const onSave = data => {
    const { healthLicenseStartDate, healthLicenseEndDate, appRole, branches, ...rest } = data;
    let request = {
      ...rest,
      appRole: appRole?.value,
      branches: branches?.map(item => item.id),
      healthLicenseStartDate:
        typeof healthLicenseStartDate == "string"
          ? healthLicenseStartDate
          : moment(healthLicenseStartDate).locale(i18n.language).format("YYYY-MM-DD"),
      healthLicenseEndDate:
        typeof healthLicenseEndDate == "string"
          ? healthLicenseEndDate
          : moment(healthLicenseEndDate).locale(i18n.language).format("YYYY-MM-DD"),
    };
    request = Object.fromEntries(
      Object.keys(request)
        .filter(k => request[k] !== "")
        .map(k => [k, request[k]])
    );

    onDone(request);
  };

  const handleCancel = () => {
    navigate("/" + ROUTE_PATHS?.usersManagement.fullPath);
  };
  const formErrors = combineErrors(errorsForm, errors);

  return {
    appRole,
    methods,
    doYouHaveData,
    formErrors,
    isUserByNatIdLoading,
    watch,
    reset,
    onSave,
    setValue,
    handleSubmit,
    handleCancel,
    handleSearchByNatId,
  };
};
