/* eslint-disable no-nested-ternary */
import { getMediaLink } from "@health/common";
import { Message } from "@health/queries";

export const getMessageAvatar = (message: Message, client: any | undefined) =>
  getMediaLink(
    message.sender.id == (client?.id || client?.me?.id)
      ? message.sender.avatar
      : message?.branch?.vendor?.logo
      ? message?.branch?.vendor?.logo
      : undefined
  );

export const textTruncate = (text: string, maxCharacters: number) =>
  text?.length > maxCharacters ? `${text.substring(0, maxCharacters)}...` : text;
