import { getMediaLink } from "@health/common";
import { Box, InfiniteScroll } from "@health/ui";
import React, { FC } from "react";
import { CommunicationItem } from "../CommunicationItem/CommunicationItem.component";
import { CommunicationListProps } from "./CommunicationList.types";

export const CommunicationList: FC<CommunicationListProps> = ({ client, data, onClick, onFetchMoreCustomers, hasMoreCustomers }) => {
  const handleItemClicked = item => () => {
    onClick && onClick(item);
  };
  return (
    <React.Fragment>
      <InfiniteScroll
        dataLength={data?.length || 0}
        next={onFetchMoreCustomers}
        hasMore={hasMoreCustomers}
        loader={<h4>Loading...</h4>}
        scrollableTarget='scrollableCustomersDiv'
      >
        {data.map((item: any) => {
          return (
            <Box key={item.id} onClick={handleItemClicked(item)}>
              <CommunicationItem
                pharmacyName={item.title}
                lastMessage={item?.lastMessage?.content || "Attachments"}
                time={item?.lastMessage?.created}
                avatar={getMediaLink(item.icon || item?.avatar)}
                haveNewMsg={!(item?.lastMessage?.seenDate || item?.id === item?.lastMessage?.recipient?.id)}
                unReadMessages={item?.unReadMessagesCountForWithBranch}
                selectedItem={client?.id === item?.id}
              />
            </Box>
          );
        })}
      </InfiniteScroll>
    </React.Fragment>
  );
};
