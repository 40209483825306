import { bg, makeStyles, white } from "@health/ui";

export const useMessageStyles = makeStyles()({
  message: {
    padding: 8,
    minHeight: 58,
  },
  sender: {
    height: 40,
    width: 40,
    margin: "0px 8px",
    border: "1px solid #E8EBEF",
    backgroundColor: bg,
    borderRadius: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  bubble: {
    padding: "8px 16px",
    maxWidth: "max-content",
    alignSelf: "center",
    lineBreak: "anywhere",
  },
  bubbleLeft: {
    backgroundColor: white,
    border: "1px solid #E8EBEF",
    borderRadius: 15,
    borderTopLeftRadius: 2,
  },
  bubbleRight: {
    borderRadius: 15,
    borderBottomRightRadius: 2,
    background: bg,
  },
});
