import { useReactiveVar } from "@apollo/client";
import { UserContext } from "@health/common";
import { AppRoleTypes, useBranchesQuery } from "@health/queries";
import { useAutocompleteCustomHook } from "@health/ui";
import { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { pharmacyUserPreferencesVar } from "./pharmacyBranchReactiveVar";

export const usePharmacyBranchForm = () => {
  const [showInActiveBranches, setShowInActiveBranches] = useState(false);

  const {
    data: branchesData,
    loading: branchesLoading,
    onFetchMoreData: handleFetchMoreBranches,
    refetch: refetchBranches,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useBranchesQuery,
    input: {
      first: 10,
      filter: {
        isActive: !showInActiveBranches,
      },
    },
    dataAccessor: "branches",
  });

  const branches = branchesData?.branches?.edges?.map(item => item.node);
  const pageInfoBranches = branchesData?.branches?.pageInfo;
  const { branches: branchesUserAdmin, user } = useContext(UserContext);

  const pharmacyUserPreferences = useReactiveVar(pharmacyUserPreferencesVar);

  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();

  const handleSearchBranchInput = name => {
    user.appRole !== AppRoleTypes.User && refetchBranches({ filter: { name_Icontains: name, isActive: !showInActiveBranches }, first: 10 });
  };
  const handleChangeInActiveBranchVisibility = shouldShowInActiveBranches => {
    setShowInActiveBranches(shouldShowInActiveBranches);
    refetchBranches({ filter: { isActive: !shouldShowInActiveBranches }, first: 10 });
  };

  return {
    pharmacyUserPreferences,
    branchesLoading,
    control,
    branches,
    pageInfoBranches,
    refetch: refetchBranches,
    formErrors,
    branchesUserAdmin,
    user,
    showInActiveBranches,
    handleFetchMoreBranches,
    handleSearchBranchInput,
    handleChangeInActiveBranchVisibility,
  };
};
