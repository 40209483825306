import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "@health/i18n";
import { Box, Button, CustomIcon, lightGrey, paths } from "@health/ui";
import { FC, useEffect } from "react";
import { pharmacyUserPreferencesVar } from "shared/components";
import { useOrderPageContext } from "../state";
import { mapStatusToColor, mapStatusToName, ORDERS_STATUS } from "../statuses";
import { buttons } from "./buttons";
import { useOrdersTabStyles } from "./OrdersTab.styles";

export const OrdersTab: FC = () => {
  const { isDeliveringColumnVisible } = useOrderPageContext();

  const newTabs = buttons
    ?.map(item => (item.id === ORDERS_STATUS.DELIVERING ? { ...item, isVisible: isDeliveringColumnVisible } : { ...item, isVisible: true }))
    .filter(item => item.isVisible);

  return (
    <Box
      display='flex'
      justifyContent='center'
      sx={{
        background: "white",
        borderTop: `1px solid ${lightGrey}`,
      }}
    >
      {newTabs.map(item => (
        <OrderTab key={item.id} item={item} />
      ))}
    </Box>
  );
};

const OrderTab: FC<{ item: { id: string; icon: string } }> = ({ item }) => {
  const { classes } = useOrdersTabStyles({ color: mapStatusToColor[item.id] });
  const { t, i18n } = useTranslation("vendor");
  const name = t(mapStatusToName(item.id));
  const userPreferences = useReactiveVar(pharmacyUserPreferencesVar);

  const { data, selectedTab, onChangeSelectedTab, fetchDataByStatus, filter } = useOrderPageContext();

  useEffect(() => {
    fetchDataByStatus(
      item.id,
      filter,
      userPreferences?.branch?.map(b => b.id)
    );
  }, [item.id, filter, userPreferences?.branch?.map(b => b.id)?.join(",")]);

  const handleChangeSelectedTab = status => () => {
    onChangeSelectedTab(status);
  };

  return (
    <Button
      className={selectedTab === item.id ? `${classes.button} ${classes.selected}` : classes.button}
      startIcon={
        <div className={classes.icon}>
          <CustomIcon icon={item.icon as keyof typeof paths} />
        </div>
      }
      variant='text'
      onClick={handleChangeSelectedTab(item?.id)}
    >
      {name}
      <span className={classes.count}>
        {Intl.NumberFormat(i18n?.language, {
          notation: "compact",
          maximumFractionDigits: 2,
        }).format(data?.[item.id]?.totalCount)}
      </span>
    </Button>
  );
};
