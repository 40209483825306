import { makeStyles } from "@health/ui";

export const useHeaderCardStyles = makeStyles<{ color: string }>()((_, { color }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    fontSize: 13,
    whiteSpace: "nowrap",
    overflow: "hidden !important",
  },
  text: { color: color, textTransform: "capitalize" },
}));
