import { makeStyles } from "@health/ui";

const usePharmacyUserDashboardStyles = makeStyles()(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
  },
  iframe: {
    height: "100%",
    width: "100%",
    border: "none",
  },
}));

export default usePharmacyUserDashboardStyles;
