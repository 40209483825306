export type IAddress = null | {
  area?: string | null;
  city?: { name?: string | null } | null;
  postalCode?: string | null;
  streetAddress1?: string | null;
  streetAddress2?: string | null;
};

export const formatAddressFull = (address?: IAddress) => {
  return [address?.streetAddress1, address?.streetAddress2, address?.area, address?.city?.name, address?.postalCode]
    .filter(Boolean)
    .join(", ");
};
