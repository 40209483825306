import { User } from "@health/queries";
import { ActiveFilters, CustomTable, MainListActions, PageWrapper, StaticFilterDialog } from "@health/ui";
import React, { FC } from "react";
import { useUsersListHook } from "./UsersList.hook";

const UsersList: FC = () => {
  const {
    users,
    columns,
    totalCount,
    pageInfo,
    pageSize,
    isLoading,
    pagesCount,
    handleGoToPrevious,
    handleGotoNext,
    handlePageSizeChange,
    handleSortData,
    handleAddNew,
    handleEdit,
    handleDeleteRow,
    fields,
    handleApplyFilters,
    handleToggleFilters,
    isFilterOpened,
    activeFilters,
    doesLoggedInUserHaveMorePermissionToDoDeleteActions,
    doesLoggedInUserHaveMorePermissionToDoUpdateActions,
  } = useUsersListHook();

  return (
    <PageWrapper
      start={<ActiveFilters onOpenFilters={handleToggleFilters} activeFilters={activeFilters ? activeFilters : []} />}
      actions={
        <>
          <StaticFilterDialog
            isOpen={isFilterOpened}
            onToggleDialog={handleToggleFilters}
            activeFilters={activeFilters}
            filterFields={fields}
            onApplyFilters={handleApplyFilters}
          />
          <MainListActions hasAddNew onAddNewItem={handleAddNew} />
        </>
      }
    >
      <CustomTable
        data={users}
        columns={columns}
        pageIndex={1}
        isDeleteVisible
        isEditVisible
        isLoading={isLoading}
        pageSize={pageSize}
        pagesCount={pagesCount}
        totalCount={totalCount}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        onSortColumn={handleSortData}
        onGoToNext={handleGotoNext}
        onGoToPrevious={handleGoToPrevious}
        onPageSizeChange={handlePageSizeChange}
        onEditRow={handleEdit}
        onDeleteRow={handleDeleteRow}
        isRowDeletable={(row: User) => doesLoggedInUserHaveMorePermissionToDoDeleteActions(row)}
        isRowEditable={(row: User) => doesLoggedInUserHaveMorePermissionToDoUpdateActions(row)}
      />
    </PageWrapper>
  );
};
export default UsersList;
