const orderHistory = "Orders-history";

export const ordersRoutes = {
  path: orderHistory,
  fullPath: orderHistory,
  name: "Orders History",
};

export const ordersManagementRoutes = {
  name: "Orders",
  path: "orders",
  fullPath: "orders",
  orders: {
    name: "Orders",
    path: "orders",
    fullPath: ["orders", "orders"].join("/"),
  },
};
