import { useTranslation } from "@health/i18n";
import { Order, useOrderLifeCycleVendorQuery } from "@health/queries";
import { useState } from "react";

export const useLifeCycleHooks = ({ orderId }: { orderId: string }) => {
  const { t, i18n } = useTranslation("vendor");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const { data, loading } = useOrderLifeCycleVendorQuery({
    variables: { id: orderId },
    skip: !open,
  });

  const order = data?.order as Order;

  return {
    open,
    loading,
    order,
    t,
    i18n,
    handleClickOpen,
    handleClickClose,
  };
};
