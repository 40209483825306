import { getIsNotificationActiveKey, setIsNotificationActiveKey } from "@health/common";
import { useTranslation } from "@health/i18n";
import { CustomToggleButton, FormControlLabel, useAddToast } from "@health/ui";
import { FC, useEffect, useState } from "react";

const NotificationForm: FC = () => {
  const { t } = useTranslation("vendor");
  const { succeeded, failed } = useAddToast();
  const [isActive, setIsActive] = useState(getIsNotificationActiveKey());

  useEffect(() => {
    if (!("Notification" in window)) {
      updateIsNotificationActiveKey(false);
    } else if (Notification.permission === "granted") {
      updateIsNotificationActiveKey(true);
    } else {
      updateIsNotificationActiveKey(false);
    }
  }, []);

  function updateIsNotificationActiveKey(_value: boolean) {
    setIsNotificationActiveKey(_value);
    setIsActive(_value);
  }

  const handleChange = () => {
    const activationMessage = isActive
      ? t("You have Successfully turned Notification off")
      : t("You have Successfully turned Notification on");

    if (!("Notification" in window)) {
      updateIsNotificationActiveKey(false);
      failed(t("This browser does not support desktop notification"));
    } else {
      switch (Notification.permission) {
        case "denied":
          updateIsNotificationActiveKey(false);
          return isActive ? succeeded(activationMessage) : failed(t("Please enable this website notification from browser"));
        case "default":
          return Notification.requestPermission().then(v => {
            v === "granted" ? succeeded(t(activationMessage)) : failed(t("Please enable this website notification from browser"));
            updateIsNotificationActiveKey(v === "granted");
          });
        case "granted":
          updateIsNotificationActiveKey(!isActive);
          succeeded(t(activationMessage));
      }
    }
  };

  return (
    <FormControlLabel
      sx={{ flexDirection: "row-reverse", mx: 2, my: 1 }}
      control={<CustomToggleButton onChange={handleChange} checked={isActive} />}
      label={t("Desktop Notifications")}
    />
  );
};
export default NotificationForm;
