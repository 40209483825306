import { useTranslation } from "@health/i18n";
import { AppRoleTypes, AppTypes, Branch, formatGraphQLError, formatMessageErrors, useVendorUserUpdateMutation } from "@health/queries";
import { CustomToggleButton, useAddToast } from "@health/ui";
import React, { FC } from "react";
type ActiveToggleProps = {
  id: string;
  isActive: boolean;
  branches: Branch[];
  appRole: AppRoleTypes;
  appType: AppTypes;
};

export const ActiveToggleButton: FC<ActiveToggleProps> = ({ id, isActive, branches, appRole, appType }) => {
  const { t } = useTranslation("vendor");
  const [checked, setChecked] = React.useState(isActive);
  const { succeeded, failed } = useAddToast();
  const branchesIds = branches?.map(branch => branch?.id);

  const [updateUser, { loading }] = useVendorUserUpdateMutation({
    onCompleted: data => {
      const errors = data?.vendorUserUpdate?.accountErrors;
      if (errors && errors.length > 0) {
        const error = formatMessageErrors(errors);
        failed(t(error));
      } else {
        setChecked(data?.vendorUserUpdate?.user?.isActive as boolean);
        succeeded(t("User Updated Successfully"));
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedMessage = formatGraphQLError(graphQLErrors);
      failed(t(formattedMessage));
    },
  });

  const handleChangeActivity = event => {
    const isChecked: boolean = event.target.checked;
    const isVendorUser = appRole === AppRoleTypes.User && appType === AppTypes.Vendor;

    updateUser({
      variables: {
        id: id,
        input: {
          isActive: isChecked,
          branches: isVendorUser ? branchesIds : [],
        },
      },
    });
  };

  return <CustomToggleButton checked={checked} onChange={handleChangeActivity} disabled={loading} />;
};
