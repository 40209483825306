import { getRequiredValidation } from "@health/common";
import { useTranslation } from "@health/i18n";
import { AutocompleteController, CustomToggleButton, FormControlLabel, getTranslatedNameField, Grid } from "@health/ui";
import React, { FC } from "react";
import { usePharmacyBranchForm } from "./usePharmacyBranchForm";

const PharmacyBranchForm: FC = () => {
  const { t } = useTranslation("vendor");
  const {
    pharmacyUserPreferences,
    showInActiveBranches,
    control,
    formErrors,
    branches,
    pageInfoBranches,
    branchesLoading,
    handleFetchMoreBranches,
    handleSearchBranchInput,
    handleChangeInActiveBranchVisibility,
  } = usePharmacyBranchForm();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AutocompleteController
          ControllerProps={{
            name: "branch",
            control: control,
            defaultValue: pharmacyUserPreferences?.branch,
            rules: { required: getRequiredValidation(t, true) },
          }}
          getOptionLabel={option => getTranslatedNameField(option)}
          options={branches || []}
          multiple
          loading={branchesLoading}
          onSearchInput={handleSearchBranchInput}
          // eslint-disable-next-line react/jsx-handler-names
          fetchMoreData={handleFetchMoreBranches}
          hasMore={pageInfoBranches?.hasNextPage}
          TextFieldProps={{
            placeholder: t("Branch"),
            label: t("Branch"),
            error: Boolean(formErrors?.branch?.message),
            helperText: t(formErrors?.branch?.message),
          }}
        />

        <FormControlLabel
          sx={{
            mx: -1,
            my: "4px",
          }}
          control={
            <CustomToggleButton onChange={e => handleChangeInActiveBranchVisibility(e.target.checked)} checked={showInActiveBranches} />
          }
          label={t<string>("Show Inactive Branches")}
        />
      </Grid>
    </Grid>
  );
};

export default PharmacyBranchForm;
