const productsManagement = "products-management";
const newProduct = "new-Product";
const updateProduct = "update/:id";

export const productsManagementVendorRoutes = {
  name: "Products Management",
  path: productsManagement,
  fullPath: productsManagement,
  products: {
    name: "Products",
    new: {
      name: "new Product",
      path: [productsManagement, newProduct].join("/"),
      fullPath: [productsManagement, newProduct].join("/"),
    },
    edit: {
      name: "Update Product",
      path: [productsManagement, updateProduct].join("/"),
      fullPath: [productsManagement, updateProduct].join("/"),
    },
  },
};
