import { formGirdBreakPoints } from "@health/common";
import { AppRoleTypes } from "@health/queries";
import { BranchesAutocomplete } from "@health/smart-ui";
import { Grid } from "@health/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FromProps } from "./UserForm.types";

export const Branches: FC<FromProps> = ({ defaultValues, errors }) => {
  const { control, watch, setValue } = useFormContext();
  const appRole = watch("appRole");
  const branchesValues = defaultValues?.branches;
  useEffect(() => {
    (appRole?.value === AppRoleTypes.User || defaultValues?.appRole === AppRoleTypes.User) && setValue("branches", branchesValues);
  }, [JSON.stringify(appRole), JSON.stringify(branchesValues), defaultValues]);

  return (
    <Grid item {...formGirdBreakPoints}>
      <BranchesAutocomplete errors={errors} branchValue={branchesValues} control={control} name='branches' />
    </Grid>
  );
};
