import { createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { getEnvVariable, getTokenFromLocalStorage } from "@health/common";

const linkOptions = {
  credentials: "omit",
  uri: () => getEnvVariable("APOLLO_SERVER_URL", process.env.REACT_APP_APOLLO_SERVER_URL),
};
export const link = createHttpLink({ ...linkOptions, credentials: "" });

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getTokenFromLocalStorage();
  const locale = localStorage.getItem("locale") || "en";
  // return the headers to the context so httpLink can read them
  const definition = getMainDefinition(_.query);
  const _hBase = {
    ...headers,
    "Accept-Language": definition.kind === "OperationDefinition" && definition.operation === "query" ? locale : "en",
  };
  const _h = token
    ? {
        ..._hBase,
        authorization: `JWT ${token}`,
      }
    : _hBase;
  return {
    headers: _h,
  };
});
