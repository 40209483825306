import { useTranslation } from "@health/i18n";
import { formatMessageErrors, useOrderGetInsuranceApprovalMutation } from "@health/queries";
import { Button, useAddToast } from "@health/ui";
import { FC } from "react";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderActionStyles } from "./OrderAction.styles";

export const GetWasfatyApprovalButton: FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation("vendor");
  const { classes } = useOrderActionStyles();
  const { failed, succeeded } = useAddToast();

  const [orderGetInsuranceApproval, { loading }] = useOrderGetInsuranceApprovalMutation({
    onCompleted: data => {
      if (data?.orderGetErxHubApproval?.orderErrors?.length === 0) {
        succeeded(t("Order insurance approval request sent successfully"));
      } else {
        const msg = formatMessageErrors(data?.orderGetErxHubApproval?.orderErrors);
        failed(msg);
      }
    },
    onError: () => {
      failed(t("Get Insurance Approval Failed"));
    },
  });

  const handleOrderGetInsuranceApproval = (): void => {
    orderGetInsuranceApproval({
      variables: {
        id,
      },
    });
  };

  return (
    <CircularIntegration loading={loading}>
      <Button variant='outlined' className={classes.outlinedAccept} onClick={handleOrderGetInsuranceApproval}>
        {t("Get Wasfaty Approval")}
      </Button>
    </CircularIntegration>
  );
};
