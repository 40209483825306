import { bg, Box, darkBlue, IconButton, SendIcon, TextField, white } from "@health/ui";
import React, { FC, useState } from "react";
import { ImageUploadButton } from "../ImageUploadButton/ImageUploadButton.component";
import { Recorder } from "../Recorder/Recorder.component";
import { useSendFieldStyles } from "./SendField.styles";
import { SendFieldProps } from "./SendField.types";

export const SendField: FC<SendFieldProps> = ({ hasAttachments = false, onSend, onImageUpload, onVoiceUpload, ...props }) => {
  const { classes } = useSendFieldStyles();
  const [content, setContent] = useState<string>("");

  const handleChange = e => {
    setContent(e.target.value);
  };

  const handleUploadImage = e => {
    const { files } = e.target;
    onImageUpload && onImageUpload(files);
  };
  const handleRecordVoice = (record: Blob) => {
    const file = new File([record], `${Date.now()}.webm`, { type: record.type });
    onVoiceUpload && onVoiceUpload(file);
  };

  const handleSendContent = e => {
    if ((hasAttachments || content.length > 0) && e.keyCode === 13) {
      onSend && onSend(content);
      setContent("");
    }
  };
  const handleSendContentClicked = () => {
    if (hasAttachments || content.length > 0) {
      onSend && onSend(content);
      setContent("");
    }
  };

  return (
    <Box display='flex' justifyContent='center' alignItems='center' p='10px' sx={{ background: white, borderRadius: "0 0 15px 15px" }}>
      <ImageUploadButton onChange={handleUploadImage} />
      <TextField
        fullWidth
        variant='outlined'
        className={classes.root}
        value={content}
        onChange={handleChange}
        onKeyDown={handleSendContent}
        InputProps={{
          endAdornment: (
            <React.Fragment>
              {!!content || hasAttachments ? (
                <IconButton
                  onClick={handleSendContentClicked}
                  sx={{
                    background: darkBlue,
                    color: white,
                    width: "35px",
                    height: "35px",
                    borderRadius: "40px",
                    "&:hover": { color: darkBlue, background: bg },
                  }}
                >
                  <SendIcon fill='inherit' />
                </IconButton>
              ) : (
                <Recorder onRecorded={handleRecordVoice} />
              )}
            </React.Fragment>
          ),
        }}
        {...props}
      />
    </Box>
  );
};
