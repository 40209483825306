import { useOpenStateController } from "@health/common";
import { useTranslation } from "@health/i18n";
import { useGetShipmentOrderForDeliveryShipmentQuery } from "@health/queries";
import { Box, Button, CircularProgress, DeliveringIcon, Dialog, DialogTitle, Divider, Typography, WasfatyLogo } from "@health/ui";
import QRCode from "qrcode.react";
import React, { FC, useEffect, useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useDispenseModelStyles } from "./DispenseModel.styles";
import OrderQR from "./OrderQR.component";

type NewDispenseModelProps = {
  number: number | string | null;
  id?: string;
  hasAwb?: boolean;
  type?: string | null;
};

export const ViewQRButton: FC<NewDispenseModelProps> = props => {
  const { open, handleOpen, handleClose } = useOpenStateController();

  const { number, id, type, hasAwb = false } = props;
  const { t } = useTranslation("vendor");
  const { classes } = useDispenseModelStyles();
  const [toPrint, setToPrint] = useState<Date | undefined>();
  const [printReady, setPrintReady] = useState<Date | undefined>();
  const [isQRReady, setIsQRReady] = useState<boolean>(false);

  useEffect(() => {
    if (printReady) handlePrint();
  }, [printReady]);

  useEffect(() => {
    let timer;
    if (type === "HOME_DELIVERY") {
      timer = setTimeout(() => {
        setIsQRReady(true);
      }, 2000);
    } else {
      setIsQRReady(true);
    }
    return () => timer && clearTimeout(timer);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
  });
  const componentRef = useRef<HTMLDivElement>(null);
  const handleClick = () => {
    setToPrint(new Date());
  };
  const { data, loading } = useGetShipmentOrderForDeliveryShipmentQuery({
    variables: {
      dispenseOrderId: id,
    },
    skip: !open || !hasAwb,
    fetchPolicy: "no-cache",
  });

  const awb = data?.getShipmentOrderForDeliveryShipment?.data?.awb || "";
  const isAwbAUrl = awb.startsWith("http");

  return (
    <>
      {!isQRReady && <CircularProgress size={20} />}
      <Button onClick={handleOpen} className={classes.button} disabled={!isQRReady}>
        {t("View QR")}
      </Button>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          classes={{
            paper: classes.paper,
          }}
        >
          {!isAwbAUrl && (
            <DialogTitle>
              <Typography className={classes.title} gutterBottom>
                <DeliveringIcon sx={{ mx: 2, my: 0, height: "100%" }} />
                {t("Order QR Code")}
              </Typography>
              <div className={classes.width}>
                <Divider light className={classes.line} />
              </div>
            </DialogTitle>
          )}
          {isAwbAUrl ? (
            <>
              <Box
                component='iframe'
                sx={
                  isAwbAUrl
                    ? { border: "none", height: 762, display: "flex", width: "calc( 100% )", mx: 0 }
                    : { border: "none", height: 364, mt: -2, display: "flex", width: "calc( 100% - 32px)", mx: 2, mb: 2 }
                }
                src={awb}
              />
            </>
          ) : (
            <>
              <Box className={classes.box}>
                <Typography className={classes.id} gutterBottom>
                  {t("Order ID")} - #{number}
                </Typography>
                <div className={classes.container}>
                  <div className={classes.logo}>
                    <WasfatyLogo height={47} width={73} />
                  </div>
                  <QRCode id={String(number)} value={String(number)} size={300} level={"H"} includeMargin={true} />
                </div>
              </Box>

              {hasAwb && (
                <Box className={classes.box}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {awb ? (
                        <Typography className={classes.id} gutterBottom>
                          {t("AWB Number")} - {awb}
                        </Typography>
                      ) : (
                        <Typography className={classes.id} gutterBottom>
                          {t("No AWB Number")}
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              )}
            </>
          )}

          <Box className={classes.action}>
            <ReactToPrint
              trigger={() => (
                <Button className={classes.print} onClick={handleClick}>
                  {t("Print")}
                </Button>
              )}
              content={() => componentRef?.current}
            />
            <OrderQR setPrintReady={setPrintReady} toPrint={toPrint} refForPrint={componentRef} number={String(number)} awb={awb} />
            <Button onClick={handleClose} variant='text'>
              {t("Ok")}
            </Button>
          </Box>
        </Dialog>
      )}
    </>
  );
};

ViewQRButton.defaultProps = {
  id: undefined,
  hasAwb: false,
  type: undefined,
};
