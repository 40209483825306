import { CircularProgress } from "@health/ui";
import { useEffect, useState } from "react";

export const OrderCircularProgress = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + 20));
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <CircularProgress
      variant='determinate'
      value={progress}
      sx={{ marginInline: "8px", height: "20px !important", width: "20px !important" }}
      color='inherit'
    />
  );
};
