import { Box, Grid, Typography } from "@health/ui";
import moment from "moment";
import React, { FC, memo } from "react";
import { Attachments } from "../Attachments/Attachments.component";
import { SenderImage } from "../SenderImage/SenderImage.component";
import { TextMessage } from "../TextMessage/TextMessage.component";
import { useMessageStyles } from "./Message.styles";
import { MessageProps } from "./Message.types";

const Message: FC<MessageProps> = ({ message = "", isSender = false, style = {}, attachments = [], avatar, created }) => {
  const { classes } = useMessageStyles();

  const messageMarginRight = message.length > 0 && isSender ? 68 : 0;
  const messageMarginLeft = message.length > 0 && !isSender ? 68 : 0;

  return (
    <Grid item xs={12} className={classes.message} sx={style}>
      <Grid container direction='row' alignItems='flex-start' dir={isSender ? "ltr" : "rtl"}>
        <Grid item className={`${classes.sender}`}>
          <SenderImage avatar={avatar} />
        </Grid>
        <Grid item xs={7}>
          {message && (
            <div className={`${classes.bubble} ${isSender ? classes.bubbleLeft : classes.bubbleRight}`}>
              <TextMessage value={message} created={created} />
            </div>
          )}
          {attachments?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                marginRight: messageMarginRight,
                marginLeft: messageMarginLeft,
              }}
            >
              <Attachments attachments={attachments} />
            </Box>
          )}
          <Typography
            variant='caption'
            sx={{
              direction: isSender ? "rtl" : "ltr",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ direction: "ltr" }}>{moment(created).fromNow()}</Box>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(Message);
