import React, { FC } from "react";
import { IconProps } from "../../icons/icons/types";

export const WasfatyGrayLogo: FC<IconProps> = ({ width, height, viewBox }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "70.946"}
      height={height ? height : "39.883"}
      viewBox={viewBox ? viewBox : "0 0 70.946 39.883"}
    >
      <g id='Group_5823' data-name='Group 5823' transform='translate(-786 -361.925)'>
        <path
          id='Path_3854'
          data-name='Path 3854'
          d='M14.075,261.91c-.5,1.409-.9,2.536-.937,2.675q-.168.586-.329,1.119c-.107.355-.207.681-.3.976s-.178.55-.249.764q-.106-.373-.276-.95c-.113-.384-.234-.808-.364-1.27s-.269-.956-.417-1.483l-.513-1.832H8.21c-.268.959-.518,1.848-.57,2.028q-.178.613-.364,1.287t-.364,1.288c-.119.409-.213.719-.284.933q-.125-.373-.3-.959c-.045-.147-.642-2.127-1.38-4.576H2.01c.818,2.657,1.511,4.892,1.6,5.118q.337.861.755,1.874t.862,1.989H7.977c.059-.178.149-.465.266-.862s.245-.829.382-1.3.272-.938.409-1.412.258-.877.364-1.207c.107.332.228.728.365,1.19s.272.926.409,1.393.263.9.382,1.306.213.7.285.888h2.735q.462-.976.976-2.246t.986-2.5c.138-.362.812-2.212,1.545-4.229H14.075Z'
          transform='translate(783.99 127.191)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3855'
          data-name='Path 3855'
          d='M424.824,260.794a3.176,3.176,0,0,0-1.048-1.2,4.3,4.3,0,0,0-1.572-.64,9.361,9.361,0,0,0-1.616-.179l-2.471-.007v2.289h2.454a2.5,2.5,0,0,1,1.252.341,1.129,1.129,0,0,1,.506.976c-.165-.024-.376-.042-.63-.053s-.572-.018-.95-.018a6.1,6.1,0,0,0-3.321.7,2.6,2.6,0,0,0-1.048,2.335,3.093,3.093,0,0,0,.213,1.146,2.235,2.235,0,0,0,.746.95,4.071,4.071,0,0,0,1.42.648,8.746,8.746,0,0,0,2.238.24q.8,0,1.518-.035t1.279-.088q.56-.055.933-.1a3.027,3.027,0,0,0,.479-.08v-5.363a4.073,4.073,0,0,0-.382-1.867m-2.477,5.416a3.059,3.059,0,0,1-.515.062q-.391.027-.853.027a3.648,3.648,0,0,1-.941-.1,1.475,1.475,0,0,1-.532-.24.646.646,0,0,1-.231-.32,1.174,1.174,0,0,1-.053-.337,1.2,1.2,0,0,1,.071-.409.8.8,0,0,1,.258-.346,1.4,1.4,0,0,1,.524-.239,3.507,3.507,0,0,1,.87-.088q.621,0,.933.027a3.757,3.757,0,0,1,.47.062v1.9Z'
          transform='translate(412.707 130.005)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3856'
          data-name='Path 3856'
          d='M259.35,263.849a2.329,2.329,0,0,0-.691-.817,3.274,3.274,0,0,0-.994-.489,8.165,8.165,0,0,0-1.164-.258l-1.616-.231a3.641,3.641,0,0,1-.853-.213.434.434,0,0,1-.285-.426.558.558,0,0,1,.311-.489,2.343,2.343,0,0,1,1.111-.186,7.528,7.528,0,0,1,.869.048l.159.02.108.018a8.6,8.6,0,0,1,.939.212,5.629,5.629,0,0,1,.76.276s1.008.453,1.009.452v-2.281c-.158-.075-.367-.164-.635-.263-.069-.026-.14-.051-.214-.076l-.062-.022-.014,0-.063-.021a9.462,9.462,0,0,0-2.552-.444,7.228,7.228,0,0,0-2.274.249,3.825,3.825,0,0,0-1.314.657,2.539,2.539,0,0,0-.755.976,3.053,3.053,0,0,0-.239,1.217,2.662,2.662,0,0,0,.284,1.3,2.386,2.386,0,0,0,.773.843,3.676,3.676,0,0,0,1.128.5,10.446,10.446,0,0,0,1.332.258l.923.107a4.02,4.02,0,0,1,.968.221.487.487,0,0,1,.311.489.48.48,0,0,1-.373.47,3.441,3.441,0,0,1-1.1.133,7.243,7.243,0,0,1-1.217-.1,8.761,8.761,0,0,1-1.03-.231,5.864,5.864,0,0,1-.786-.285s-1.043-.469-1.043-.468v2.36c.163.077.381.171.657.272q.106.041.221.078l.064.023.014,0,.066.022a10.564,10.564,0,0,0,3.25.471,5.221,5.221,0,0,0,3.143-.835,2.721,2.721,0,0,0,1.137-2.326,2.627,2.627,0,0,0-.261-1.207'
          transform='translate(560.988 130.112)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3857'
          data-name='Path 3857'
          d='M353.471,226.022a1.372,1.372,0,0,1,.39-1.066,1.524,1.524,0,0,1,1.066-.356,2.949,2.949,0,0,1,.933.151,1.868,1.868,0,0,1,.665.346c.009-.005.019-.012.029-.018l.469-1.931a1.479,1.479,0,0,0-.338-.29,3.035,3.035,0,0,0-.967-.364,6.533,6.533,0,0,0-1.306-.115,5.146,5.146,0,0,0-1.555.223,3.182,3.182,0,0,0-1.2.675,3.01,3.01,0,0,0-.773,1.164,4.659,4.659,0,0,0-.276,1.688v9.342h2.859v-5.337h2.858v-2.664h-2.858v-1.446Z'
          transform='translate(471.639 162.611)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3858'
          data-name='Path 3858'
          d='M599.178,261.142v4.565a1.98,1.98,0,0,1-.444,1.42,1.643,1.643,0,0,1-1.243.462,1.788,1.788,0,0,1-1.252-.373,1.615,1.615,0,0,1-.4-1.226v-4.849H592.98v5.434a3.7,3.7,0,0,0,.293,1.537,2.886,2.886,0,0,0,.817,1.075,3.451,3.451,0,0,0,1.225.63,5.4,5.4,0,0,0,1.518.2,4.2,4.2,0,0,0,1.385-.24,2.244,2.244,0,0,0,1.03-.648,2.615,2.615,0,0,1-.6,1.935,2.761,2.761,0,0,1-1.97.586,6.82,6.82,0,0,1-.933-.062c-.3-.042-.571-.088-.808-.142s-.435-.106-.6-.159a2.907,2.907,0,0,1-.311-.115l-.692,2.1a2.35,2.35,0,0,0,.356.142,7.817,7.817,0,0,0,.8.221q.5.115,1.154.2a10.778,10.778,0,0,0,1.439.088,6.933,6.933,0,0,0,2.024-.276,4.041,4.041,0,0,0,1.563-.87,3.879,3.879,0,0,0,1.013-1.545,6.586,6.586,0,0,0,.355-2.3v-7.8h-2.859Z'
          transform='translate(254.469 127.881)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3859'
          data-name='Path 3859'
          d='M157.149,259.465a4.3,4.3,0,0,0-1.57-.638,9.336,9.336,0,0,0-1.614-.179l-2.283-.007v2.285h2.266a2.175,2.175,0,0,1,1.25.34,1.184,1.184,0,0,1,.506,1.007q-.248-.036-.63-.053t-.949-.018a6.1,6.1,0,0,0-3.317.7,2.6,2.6,0,0,0-1.047,2.332,3.1,3.1,0,0,0,.212,1.144,2.224,2.224,0,0,0,.746.949,4.037,4.037,0,0,0,1.419.647,8.723,8.723,0,0,0,2.235.239q.8,0,1.517-.035t1.277-.088q.558-.055.932-.1a3.185,3.185,0,0,0,.479-.08v-5.356a4.2,4.2,0,0,0-.382-1.895,3.165,3.165,0,0,0-1.046-1.2M155.72,266.1a3.061,3.061,0,0,1-.515.062q-.391.027-.852.027a3.638,3.638,0,0,1-.94-.1,1.5,1.5,0,0,1-.532-.239.649.649,0,0,1-.231-.319,1.175,1.175,0,0,1-.053-.337,1.2,1.2,0,0,1,.071-.408.791.791,0,0,1,.258-.346,1.4,1.4,0,0,1,.523-.239,3.5,3.5,0,0,1,.869-.088q.621,0,.932.027a3.79,3.79,0,0,1,.47.062Z'
          transform='translate(651.604 130.121)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3860'
          data-name='Path 3860'
          d='M522.229,244.771a2.6,2.6,0,0,1-.907.151,2.629,2.629,0,0,1-.489-.045.99.99,0,0,1-.427-.186,1.132,1.132,0,0,1-.311-.382,1.418,1.418,0,0,1-.125-.649v-2.789h2.856v-2.666h-2.856V235.54h-2.846c-.005.058-.015,8.743-.015,8.743a3.066,3.066,0,0,0,.942,2.354,3.674,3.674,0,0,0,2.612.88,5.273,5.273,0,0,0,1.119-.115,6.989,6.989,0,0,0,.96-.276,4.757,4.757,0,0,0,.728-.338,3.1,3.1,0,0,0,.462-.319l-1.119-1.955a3.724,3.724,0,0,1-.584.258'
          transform='translate(322.451 150.819)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3861'
          data-name='Path 3861'
          d='M25.945,213.87a1.325,1.325,0,1,0,1.325,1.325,1.326,1.326,0,0,0-1.325-1.325'
          transform='translate(763.73 170.236)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3862'
          data-name='Path 3862'
          d='M55.135,213.87a1.325,1.325,0,1,0,1.325,1.325,1.326,1.326,0,0,0-1.325-1.325'
          transform='translate(737.576 170.236)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3863'
          data-name='Path 3863'
          d='M595.537,76.243a4.765,4.765,0,0,0-1.1-1.542,5.2,5.2,0,0,0-1.629-1.027,5.539,5.539,0,0,0-4,0,5.17,5.17,0,0,0-1.638,1.027,4.8,4.8,0,0,0-1.1,1.542,4.923,4.923,0,0,0,0,3.88,4.764,4.764,0,0,0,1.1,1.542,5.193,5.193,0,0,0,1.638,1.027,5.253,5.253,0,0,0,1.24.317l.345.047-2.863,3.5h3.188q.516-.671,1.031-1.341l2.024-2.635c.472-.615.953-1.225,1.417-1.845a3.668,3.668,0,0,0,.629-1.454,5.392,5.392,0,0,0,.108-1.1,4.683,4.683,0,0,0-.4-1.94m-2.5,2.951a2.3,2.3,0,0,1-.505.813,2.464,2.464,0,0,1-.758.532,2.452,2.452,0,0,1-1.924,0,2.434,2.434,0,0,1-.768-.533h0a2.317,2.317,0,0,1-.505-.814,3.046,3.046,0,0,1,0-2.021,2.313,2.313,0,0,1,.505-.813,2.445,2.445,0,0,1,.768-.533,2.357,2.357,0,0,1,.957-.2,2.326,2.326,0,0,1,.967.2,2.462,2.462,0,0,1,.758.532,2.312,2.312,0,0,1,.505.813,2.91,2.91,0,0,1,.173,1.011,2.862,2.862,0,0,1-.173,1.013'
          transform='translate(261.012 296.189)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3864'
          data-name='Path 3864'
          d='M228.135,45.119a1.325,1.325,0,1,0-1.325-1.325,1.326,1.326,0,0,0,1.325,1.325'
          transform='translate(582.564 323.813)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3865'
          data-name='Path 3865'
          d='M198.935,45.119a1.325,1.325,0,1,0-1.325-1.325,1.326,1.326,0,0,0,1.325,1.325'
          transform='translate(608.729 323.813)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3866'
          data-name='Path 3866'
          d='M316.015,36.479a1.325,1.325,0,1,0-1.325-1.325,1.326,1.326,0,0,0,1.325,1.325'
          transform='translate(503.822 331.556)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3867'
          data-name='Path 3867'
          d='M56.447,73.28H51.9A4.548,4.548,0,0,0,48.3,74.594a3.79,3.79,0,0,0-.269.345c-.029.035-.058.07-.085.105V73.28H45.116v5.25c-.007.137-.019.266-.035.386a1.44,1.44,0,0,1-.178.542.845.845,0,0,1-.391.338,1.693,1.693,0,0,1-.684.115H40a4.608,4.608,0,1,0-8.276,0h-3.83a1.693,1.693,0,0,1-.684-.115.839.839,0,0,1-.391-.338,1.416,1.416,0,0,1-.178-.542c-.017-.12-.028-.25-.035-.388v-4.3H23.777v4.3c-.007.141-.019.273-.035.4a1.44,1.44,0,0,1-.178.542.85.85,0,0,1-.391.338,1.7,1.7,0,0,1-.684.115H19.054V77.884A3.664,3.664,0,0,0,18.7,76.24a3.441,3.441,0,0,0-.969-1.2,4.315,4.315,0,0,0-1.439-.728,6.319,6.319,0,0,0-3.509,0,4.5,4.5,0,0,0-1.44.728,3.4,3.4,0,0,0-.977,1.2,3.663,3.663,0,0,0-.355,1.644v3.234a2.982,2.982,0,0,1-.791,2.008,2.658,2.658,0,0,1-1.876.715,2.724,2.724,0,0,1-1.678-.7,3.048,3.048,0,0,1-.88-2.3l.054-3.666H2.01v3.78A7.963,7.963,0,0,0,2.076,82c0,.018.006.034.008.053.012.089.026.179.042.265s.031.155.048.231c.005.024.009.05.015.074a5,5,0,0,0,.276.84,4.646,4.646,0,0,0,1.219,1.7,4.783,4.783,0,0,0,1.779.961,7.121,7.121,0,0,0,1.207.245,6.938,6.938,0,0,0,.76.048l.083,0h0c.149,0,.3-.008.448-.021a7.843,7.843,0,0,0,1.055-.176c.135-.031.266-.066.4-.105l.012,0a4.783,4.783,0,0,0,1.779-.961c.034-.029.064-.062.1-.094s.079-.074.115-.111.068-.062.1-.1a1.5,1.5,0,0,0,.164-.177l.046-.06c.01-.012.018-.027.027-.04a4.724,4.724,0,0,0,.339-.485c.005-.009.011-.017.017-.026a5.337,5.337,0,0,0,.323-.629,5.182,5.182,0,0,0,.349-1.324c0-.009,0-.019,0-.028.006-.047.014-.094.02-.141h0a7.821,7.821,0,0,0,.06-.789s0-2.61,0-2.61q0-1.842,1.671-1.845a1.6,1.6,0,0,1,1.226.453,1.975,1.975,0,0,1,.426,1.4V82.4h6.777a4,4,0,0,0,1.3-.213,1.821,1.821,0,0,0,.977-.782,1.851,1.851,0,0,0,.915.782,3.488,3.488,0,0,0,1.253.213H61.2V78.349a5.524,5.524,0,0,0-1.15-3.753,4.525,4.525,0,0,0-3.6-1.316M35.859,75.609a2.278,2.278,0,1,1-2.277,2.278,2.277,2.277,0,0,1,2.277-2.278m22.483,4.3h-9.2V78.671a3.142,3.142,0,0,1,.653-2.133,2.584,2.584,0,0,1,2.049-.747h3.8a2.581,2.581,0,0,1,2.048.747,3.137,3.137,0,0,1,.654,2.133v1.239Z'
          transform='translate(783.99 296.207)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3868'
          data-name='Path 3868'
          d='M2.02,7.237V1.67H3.9A2.936,2.936,0,0,1,5.113,1.9a1.7,1.7,0,0,1,.773.658A1.946,1.946,0,0,1,6.152,3.6a1.85,1.85,0,0,1-.161.776,1.764,1.764,0,0,1-.454.607,2.078,2.078,0,0,1-.7.392,2.838,2.838,0,0,1-.91.137,3.645,3.645,0,0,1-.565-.047V7.236H2.02ZM3.36,2.807V4.335a3.6,3.6,0,0,0,.5.04,1.03,1.03,0,0,0,.678-.2.718.718,0,0,0,.239-.584.734.734,0,0,0-.231-.58.982.982,0,0,0-.671-.2Z'
          transform='translate(783.982 360.371)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3869'
          data-name='Path 3869'
          d='M48.01,7.237V1.67h1.364V6.029h2.2V7.236H48.01Z'
          transform='translate(742.773 360.371)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3870'
          data-name='Path 3870'
          d='M90.441,7.393a2.811,2.811,0,0,1-1.015-.173,2.2,2.2,0,0,1-.776-.494,2.152,2.152,0,0,1-.5-.773,2.791,2.791,0,0,1-.173-1.008V1.67h1.364V4.885a1.51,1.51,0,0,0,.137.667,1.019,1.019,0,0,0,.385.435,1.221,1.221,0,0,0,1.183,0,1.03,1.03,0,0,0,.384-.435,1.51,1.51,0,0,0,.137-.667V1.67h1.364V4.947a2.747,2.747,0,0,1-.177,1.008,2.147,2.147,0,0,1-1.29,1.266A2.927,2.927,0,0,1,90.441,7.393Z'
          transform='translate(706.961 360.371)'
          fill='#c9d5e2'
        />
        <path
          id='Path_3871'
          data-name='Path 3871'
          d='M143.319,6.351a2.465,2.465,0,0,1-.964-.184,1.991,1.991,0,0,1-.737-.525,1.848,1.848,0,0,1-.408-.819l1.262-.251a.842.842,0,0,0,.855.62.841.841,0,0,0,.518-.153.473.473,0,0,0,.2-.4.431.431,0,0,0-.192-.361,1.839,1.839,0,0,0-.647-.243L143,3.992a2.355,2.355,0,0,1-1.247-.62,1.469,1.469,0,0,1-.4-1.035,1.669,1.669,0,0,1,.153-.717,1.723,1.723,0,0,1,.423-.565,1.946,1.946,0,0,1,.639-.372,2.321,2.321,0,0,1,.792-.133,2.238,2.238,0,0,1,.91.18,1.831,1.831,0,0,1,.682.514,1.859,1.859,0,0,1,.376.8l-1.238.251a.906.906,0,0,0-.29-.435.724.724,0,0,0-.454-.145.71.71,0,0,0-.454.141.444.444,0,0,0-.18.368.376.376,0,0,0,.106.279.776.776,0,0,0,.294.173,3.884,3.884,0,0,0,.423.122l.2.047A2.582,2.582,0,0,1,145,3.451a1.437,1.437,0,0,1,.416,1.059,1.737,1.737,0,0,1-.157.745,1.762,1.762,0,0,1-.439.584,2.012,2.012,0,0,1-.662.381A2.58,2.58,0,0,1,143.319,6.351Z'
          transform='translate(659.266 361.375)'
          fill='#c9d5e2'
        />
      </g>
    </svg>
  );
};
