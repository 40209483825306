import { useTranslation } from "@health/i18n";
import { useCurrentUserQuery } from "@health/queries";
import { CircularProgress } from "@health/ui";
import React from "react";
import usePharmacyUserDashboardStyles from "shared/components/PharmacyUserDashboard/PharmacyUserDashboard.styles";

const PharmacyUserDashboard = () => {
  const { data, loading } = useCurrentUserQuery({
    variables: {
      isVendorApp: true,
      isAdminApp: false,
      isClientApp: false,
    },
  });

  const { t } = useTranslation();
  const { classes } = usePharmacyUserDashboardStyles();

  return (
    <div className={classes.wrapper}>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <iframe className={classes.iframe} src={data?.me?.metabaseIFrameUrl || ""} title={t("Pharmacy User Dashboard")} />
      )}
    </div>
  );
};

export default PharmacyUserDashboard;
