import { Grid } from "@health/ui";
import React, { FC } from "react";
import { AttachmentSendPreviewer } from "../AttachmentSendPreviewer";
import { Conversation } from "../Conversation";
import { SendField } from "../SendField";
import { ConversationStudioProps } from "./ConversationStudio.types";

export const ConversationStudio: FC<ConversationStudioProps> = props => {
  const {
    client,
    attachment,
    messages,
    isLoadingMessages,
    isFetchingMoreMessages,
    hasNextPage,
    onSendMessage,
    onUploadImage,
    onUploadRecord,
    onLoadMoreMessages,
    onDiscardAttachment,
    onDiscardAttachmentItem,
  } = props;

  const handleUploadImage = files => {
    onUploadImage?.(files);
  };

  const handleUploadRecord = record => {
    onUploadRecord?.(record);
  };

  const handleDiscardAttachment = () => {
    onDiscardAttachment?.();
  };
  const handleDiscardAttachmentItem = (id: string) => {
    onDiscardAttachmentItem?.(id);
  };

  const handleSendMessage = message => {
    onSendMessage?.(message);
  };

  const handleLoadMoreMessages = () => {
    onLoadMoreMessages?.();
  };

  const height = `calc(85vh - 240px - ${attachment?.length > 0 ? "100px" : "0px"})`;

  return (
    <Grid container direction='column'>
      <Grid item xs={12}>
        <Conversation
          client={client}
          height={height}
          hasMore={hasNextPage}
          isFetchingMore={isFetchingMoreMessages}
          messages={messages as any}
          loading={isLoadingMessages}
          onLoadMore={handleLoadMoreMessages}
        />
      </Grid>
      <Grid item xs={12}>
        {attachment && attachment.length > 0 && (
          <AttachmentSendPreviewer
            attachment={attachment}
            loadingAttachment={false}
            onDiscardAttachment={handleDiscardAttachment}
            onDiscardAttachmentItem={handleDiscardAttachmentItem}
          />
        )}
        <SendField
          hasAttachments={!!attachment?.length}
          onSend={handleSendMessage}
          onImageUpload={handleUploadImage}
          onVoiceUpload={handleUploadRecord}
        />
      </Grid>
    </Grid>
  );
};
