import { Branch, BranchesQuery, useBranchesQuery, VendorBranchTypeEnum } from "@health/queries";
import { Grid } from "@health/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { WarehouseProps } from "./Warehouse.type";
import { WarehouseRaw } from "./WarehouseRaw.component";

const Warehouse: FC<WarehouseProps> = ({ defaultData, onRemoveRow, fields }) => {
  const {
    data: Branches,
    fetchMore,
    refetch,
  } = useBranchesQuery({
    variables: {
      first: 10,
      filter: {
        type: [VendorBranchTypeEnum.Pharmacy],
        isActive: true,
      },
    },
  });
  const branches = Branches?.branches?.edges?.map(branch => branch?.node) as Array<Branch>;
  const pageInfo = Branches?.branches?.pageInfo;
  const { watch } = useFormContext();
  const stocks = watch("stocks");
  const handleFetchBranches = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 5,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev: BranchesQuery, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            branches: {
              ...fetchMoreResult.branches,
              edges: [...(prev?.branches?.edges || []), ...(fetchMoreResult?.branches?.edges || [])],
            },
          });
        },
      });
    }
  };
  const onSearchBranch = (inputValue: string) => {
    refetch({
      filter: {
        name_Icontains: inputValue,
      },
    });
  };
  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        {fields?.map((item, index: number) => {
          const watchedBranch = watch(`stocks.${index}`);
          const hasRemove =
            defaultData?.vendorProductVariant?.stocks?.[index]?.branch?.id !== null &&
            defaultData?.vendorProductVariant?.stocks?.[index]?.quantity !== null &&
            !!watchedBranch?.id;
          return (
            <React.Fragment key={item.id}>
              <Grid item xs={12} container spacing={2}>
                <WarehouseRaw
                  branches={branches}
                  defaultData={defaultData}
                  stocks={stocks}
                  index={index}
                  handleRemoveRow={onRemoveRow}
                  hasRemove={hasRemove}
                  hasMoreBranches={Boolean(pageInfo?.hasNextPage)}
                  onFetchMoreBranches={handleFetchBranches}
                  handleSearchBranch={onSearchBranch}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
};
export default Warehouse;
