import { useReactiveVar } from "@apollo/client";
import { UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import { AppRoleTypes } from "@health/queries";
import { Box, Button, CustomIcon, Dialog, Divider, getTranslatedNameField, IconButton, Typography } from "@health/ui";
import React, { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { usePharmacyBranchDialogStyles } from "./PharmacyBranchDialog.styles";
import PharmacyBranchForm from "./PharmacyBranchForm";
import { pharmacyUserPreferencesVar } from "./pharmacyBranchReactiveVar";
import { usePharmacyBranchHook } from "./usePharmacyBranchHook";
import { setSelectedBranchToLocalStorage } from "./utils";

export const PharmacyBranchDialog = () => {
  const { t } = useTranslation("vendor");
  const pharmacyUserPreferences = useReactiveVar(pharmacyUserPreferencesVar);
  const { open, handleToggle } = usePharmacyBranchHook();
  const { classes } = usePharmacyBranchDialogStyles();
  const { user } = useContext(UserContext);

  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const onSubmit = data => {
    const preferences = {
      branch: data?.branch?.map(branch => ({
        id: branch?.id,
        name: branch?.name,
        nameAr: branch?.nameAr,
      })),
      city: data?.city && { id: data?.city?.id, name: getTranslatedNameField(data?.city) || data?.city?.name },
    };
    pharmacyUserPreferencesVar(preferences);
    setSelectedBranchToLocalStorage(JSON.stringify(preferences));
    data?.branch && user.appRole === AppRoleTypes.User && handleToggle();
    user.appRole !== AppRoleTypes.User && handleToggle();
  };

  const showClose = user?.appRole === AppRoleTypes.Admin || pharmacyUserPreferences?.branch;
  return (
    <Dialog
      open={Boolean(open)}
      classes={{
        paper: classes.paper,
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.box}>
            <Box className={classes.header}>
              <Typography className={classes.title}>{t("Select Pharmacy Branch")}</Typography>
              {showClose && (
                <IconButton
                  onClick={handleToggle}
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                >
                  <CustomIcon icon='miniClose' viewBox='0 0 22 22' />
                </IconButton>
              )}
            </Box>
            <Divider light className={classes.line} />

            <Box
              sx={{
                margin: "15px 0px",
              }}
            >
              <PharmacyBranchForm />
            </Box>
          </Box>
          <Box className={classes.action}>
            <Button className={classes.selectBtn} type='submit'>
              {t("Select")}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Dialog>
  );
};
