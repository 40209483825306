/* eslint-disable max-lines */
import { ChatPage } from "@health/chat";
import { getUserPermissions, hasPermission } from "@health/common";
import { useTranslation } from "@health/i18n";
import { MessageFragmentFragmentDoc, PermissionEnum } from "@health/queries";
import { MarketplaceOrdersTrackingScreen, useSiteSettingsContext } from "@health/smart-ui";
import {
  BarChartIcon,
  BlankOrderIcon,
  OrdersIcon,
  PrescriptionIcon,
  primary,
  ProductIcon,
  ProviderIcon,
  SettingsIcon,
  ShopIcon,
  UsersGroupIcon,
} from "@health/ui";
import { BlankOrderNewPage, BlankOrderPage } from "pages/BlankOrder";
import { BranchesScreen, EditBranchPage, NewBranchPage } from "pages/Branches";
import { OrdersScreen } from "pages/Orders";
import { PrescriptionsScreen } from "pages/Prescriptions";
import ProductsFormEditPage from "pages/product/edit/ProductsEditPage.screen";
import ProductsList from "pages/product/home/Products.screen";
import { SettingsPage } from "pages/Settings";
import { UsersList } from "pages/Users";
import { UserFormEditPage } from "pages/Users/edit";
import { UserFormNewPage } from "pages/Users/new";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import PharmacyUserDashboard from "shared/components/PharmacyUserDashboard/PharmacyUserDashboard";
import { ROUTE_PATHS } from "../../constants/route";
import RouteItem from "./routeTypes";

export const useAppRoutes = (): RouteItem[] => {
  const { t } = useTranslation("vendor");
  const navigate = useNavigate();
  const userPermissions = getUserPermissions();
  const { settings, isMarketPlaceEnabled } = useSiteSettingsContext();
  return useMemo(
    () =>
      [
        {
          id: "dashboard",
          route: "dashboard",
          fullPath: "/dashboard",
          text: t("Dashboard"),
          icon: <BarChartIcon />,
          element: <PharmacyUserDashboard />,
          onClick: route => navigate(route),
        },
        isMarketPlaceEnabled && {
          id: "Product Management",
          text: t(ROUTE_PATHS?.productsManagementVendorRoutes?.name),
          icon: <ProductIcon />,
          // route: ROUTE_PATHS?.productsManagementVendorRoutes?.path,
          // fullPath: ROUTE_PATHS?.productsManagementVendorRoutes?.fullPath,
          // onClick: route => navigate(route),
          isProhibited: !hasPermission(PermissionEnum.ManageProductStocks),
          subItems: [
            {
              id: "products",
              text: ROUTE_PATHS.productsManagementVendorRoutes.products.name,
              route: ROUTE_PATHS.productsManagementVendorRoutes.path,
              fullPath: ROUTE_PATHS.productsManagementVendorRoutes.fullPath,
              element: <ProductsList />,
              onClick: route => navigate(route),
            },

            {
              id: "update-Product",
              text: ROUTE_PATHS.productsManagementVendorRoutes.products?.edit?.name,
              route: ROUTE_PATHS.productsManagementVendorRoutes.products?.edit?.path,
              fullPath: ROUTE_PATHS.productsManagementVendorRoutes.products?.edit.fullPath,
              element: <ProductsFormEditPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
          ],
        },
        {
          id: "Medical Delivery Request",
          text: t("Medical Delivery Request"),
          icon: <BlankOrderIcon />,
          route: ROUTE_PATHS?.medicalDeliveryRequest?.path,
          fullPath: ROUTE_PATHS?.medicalDeliveryRequest?.fullPath,
          onClick: route => navigate(route),
          element: <BlankOrderPage />,
          hidden: false,
          isProhibited: !hasPermission(PermissionEnum.ManageMedicalDeliveryRequests),
        },
        {
          id: "New Delivery Request",
          text: t("New Delivery Request"),
          icon: <BlankOrderIcon />,
          route: ROUTE_PATHS?.medicalDeliveryRequest?.medicalDeliveryRequest?.new?.path,
          fullPath: ROUTE_PATHS?.medicalDeliveryRequest?.medicalDeliveryRequest?.new?.fullPath,
          onClick: route => navigate(route),
          element: <BlankOrderNewPage />,
          hidden: true,
          isProhibited: !hasPermission(PermissionEnum.ManageMedicalDeliveryRequests),
        },
        {
          id: "orders",
          text: t(ROUTE_PATHS?.orders?.name),
          icon: <OrdersIcon />,
          route: ROUTE_PATHS?.orders?.path,
          fullPath: ROUTE_PATHS?.orders?.fullPath,
          onClick: route => navigate(route),
          element: <OrdersScreen />,
          isProhibited: !hasPermission(PermissionEnum.ManageOrders),
        },
        {
          id: "prescriptions",
          text: t("Prescriptions Tracking"),
          route: "prescriptions",
          fullPath: "/prescriptions",
          icon: <PrescriptionIcon />,
          onClick: route => navigate(route),
          element: <PrescriptionsScreen />,
          isProhibited: !hasPermission(PermissionEnum.ManagePrescriptions),
        },
        isMarketPlaceEnabled && {
          id: "marketplace orders tracking",
          text: t("Marketplace Orders Tracking"),
          route: "marketplace-orders-tracking",
          fullPath: "/marketplace-orders-tracking",
          icon: <ShopIcon fill={primary} />,
          onClick: route => navigate(route),
          element: <MarketplaceOrdersTrackingScreen />,
          isProhibited: !hasPermission(PermissionEnum.ManagePrescriptions),
        },
        {
          id: "branches",
          text: t("Branches"),
          route: ROUTE_PATHS?.branches?.path,
          fullPath: ROUTE_PATHS?.branches?.fullPath,
          icon: <ProviderIcon />,
          onClick: route => navigate(route),
          element: <BranchesScreen />,
          isProhibited: !hasPermission(PermissionEnum.ManageBranches),
        },
        {
          id: "new-branch",
          text: ROUTE_PATHS.branches?.branches?.new.name,
          route: ROUTE_PATHS.branches?.branches?.new.path,
          fullPath: ROUTE_PATHS.branches?.branches?.new.fullPath,
          icon: <ProviderIcon />,
          onClick: route => navigate(route),
          element: <NewBranchPage />,
          hidden: true,
          isProhibited: !hasPermission(PermissionEnum.ManageBranches),
        },
        {
          id: "edit-branch",
          text: ROUTE_PATHS.branches?.branches?.edit.name,
          route: ROUTE_PATHS.branches?.branches?.edit.path,
          fullPath: ROUTE_PATHS.branches?.branches?.edit.fullPath,
          icon: <ProviderIcon />,
          onClick: route => navigate(route),
          element: <EditBranchPage />,
          hidden: true,
          isProhibited: !hasPermission(PermissionEnum.ManageBranches),
        },
        {
          id: "Users",
          text: t(ROUTE_PATHS.usersManagement.name),
          route: ROUTE_PATHS.usersManagement.path,
          fullPath: ROUTE_PATHS.usersManagement.fullPath,
          icon: <UsersGroupIcon />,
          onClick: route => navigate(route),
          element: <UsersList />,
          isProhibited: !hasPermission(PermissionEnum.ManageUsers),
        },
        {
          id: "new-user",
          text: ROUTE_PATHS.usersManagement.users.new.Name,
          route: ROUTE_PATHS.usersManagement.users.new.path,
          fullPath: ROUTE_PATHS.usersManagement.users.new.fullPath,
          onClick: route => navigate(route),
          element: <UserFormNewPage />,
          hidden: true,
          isProhibited: !hasPermission(PermissionEnum.ManageUsers),
        },
        {
          id: "edit-user",
          text: t(ROUTE_PATHS.usersManagement.users.edit.Name),
          route: ROUTE_PATHS.usersManagement.users.edit.path,
          fullPath: ROUTE_PATHS.usersManagement.users.edit.fullPath,
          onClick: route => navigate(route),
          element: <UserFormEditPage />,
          hidden: true,
          isProhibited: !hasPermission(PermissionEnum.ManageUsers),
        },
        // {
        //   id: "order history",
        //   text: t(ROUTE_PATHS.orderHistory.name),
        //   route: ROUTE_PATHS.orderHistory.path,
        //   fullPath: ROUTE_PATHS.orderHistory.fullPath,
        //   icon: <LocationIcon />,
        //   onClick: route => navigate(route),
        //   element: <OrderHistory />,
        //   isProhibited: !hasPermission(PermissionEnum.ManageOrders),
        // },//TODO removed due ticket ISSUE-3163
        {
          id: "settings",
          text: t(ROUTE_PATHS?.settings.name),
          icon: <SettingsIcon />,
          route: ROUTE_PATHS?.settings?.path,
          fullPath: ROUTE_PATHS?.settings?.fullPath,
          element: <SettingsPage />,
          onClick: route => navigate(route),
          isProhibited: !hasPermission(PermissionEnum.ManagePharmacyCredentials),
        },
        {
          id: "chat",
          text: t(ROUTE_PATHS?.chat.name),
          icon: <SettingsIcon />,
          route: ROUTE_PATHS?.chat?.path,
          fullPath: ROUTE_PATHS?.chat?.fullPath,
          element: <ChatPage />,
          hidden: true,
          onClick: route => navigate(route),
          isProhibited: !hasPermission(PermissionEnum.ManageChat),
        },
      ].filter(Boolean) as RouteItem[],
    [settings, userPermissions, navigate, t]
  );
};

export const handleAddClientNewMessage = (cache, message, branch) => {
  cache.modify({
    id: cache.identify(branch),
    fields: {
      messages: handleAddNewMessage(cache, message),
    },
  });
};
const handleAddNewMessage =
  (cache, message) =>
  (existingMessages, { readField }) => {
    const newMessageRef = cache.writeFragment({
      data: message,
      fragment: MessageFragmentFragmentDoc,
    });
    const newEdgeRef = {
      __typename: "MessageCountableEdge",
      node: newMessageRef,
    };
    if (existingMessages?.edges.some(ref => readField("id", ref.node) === message?.id)) {
      return existingMessages;
    }
    return {
      ...existingMessages,
      edges: [newEdgeRef, ...existingMessages.edges],
    };
  };
