import { darkBlue, lightGrey, makeStyles } from "@health/ui";

export const useImageUploadButtonStyles = makeStyles()({
  root: {
    flex: "0 0 auto",
    color: darkBlue,
    padding: 4,
    textAlign: "center",
    borderRadius: "50%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: lightGrey,
    },
  },
  label: {
    cursor: "pointer",
    display: "flex",
  },
  input: { display: "none" },
});
