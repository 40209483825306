import { Box, CircularProgress } from "@health/ui";
import { FC } from "react";

export const Loading: FC<{ color: string }> = ({ color }) => {
  return (
    <Box display='flex' justifyContent='center' m='8px'>
      <CircularProgress
        sx={{
          color,
        }}
      />
    </Box>
  );
};
