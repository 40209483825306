import { debounce } from "@health/common";
import { Grid, InfiniteScrollContainer, OrdersEmpty } from "@health/ui";
import { FC, useLayoutEffect, useState } from "react";
import { useOrderPageContext } from "../state";
import { mapStatusToColor } from "../statuses";
import { Loading } from "./Loading";
import { Order } from "./Order.component";
import { OrdersLoading } from "./OrdersLoading.component";
import { useOrdersBodyStyles } from "./useOrdersBodyStyles";

type IOrdersBody = {
  status: string;
};

export const OrdersBody: FC<IOrdersBody> = ({ status }) => {
  const { classes } = useOrdersBodyStyles();
  const { data, selectedView } = useOrderPageContext();
  const [windowHeight, setWindowHeight] = useState(document.body.offsetHeight - 230);
  const debouncedHandleResize = debounce(setWindowHeight, 100);
  const column = data?.[status];
  const color = mapStatusToColor[status];
  const { data: orders, hasNextPage: hasNext, fetchMore: onFetchMore, loading } = column;
  useLayoutEffect(() => {
    window.addEventListener("resize", () => debouncedHandleResize(document.body.offsetHeight - 230));
    return () => {};
  }, []);
  return (
    <OrdersLoading loading={loading} color={color}>
      <OrdersEmpty length={orders?.length} color={color}>
        <InfiniteScrollContainer
          dataLength={orders?.length}
          hasMore={Boolean(hasNext)}
          next={onFetchMore}
          loader={<Loading color={color} />}
          height={selectedView === "Queue" ? windowHeight : undefined}
          classes={{
            infinite: classes.infinite,
          }}
          scrollableTarget='layout'
        >
          <Grid container spacing={1}>
            {orders?.map(order => (
              <Grid item xs={12} key={String(order.id) + "-container"}>
                <Order order={order} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScrollContainer>
      </OrdersEmpty>
    </OrdersLoading>
  );
};
