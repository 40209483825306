import { useTranslation } from "@health/i18n";
import { Maybe } from "@health/queries";
import { Box, Button, Typography } from "@health/ui";
import React, { FC } from "react";
import { OrderCircularProgress } from "../components/OrderCircularProgress";
import { useHeaderCardStyles } from "./HeaderCard.styles";

type HeaderCardProps = {
  title: string;
  color: string;
  subTitle?: string;
  buttonTitle?: string;
  onClick?: () => void;
  deliveryStatus?: Maybe<string> | undefined;
  isOrderLoading?: boolean;
};

export const HeaderCard: FC<HeaderCardProps> = props => {
  const { title, subTitle, color, buttonTitle, onClick: handleClick, deliveryStatus, isOrderLoading, children } = props;

  const { classes } = useHeaderCardStyles({ color });
  const { t } = useTranslation("vendor");

  return (
    <div className={classes.root}>
      <div>
        <Box display='flex'>
          <Typography fontSize='14px' fontFamily='Airbnb Cereal App Medium, Arab Kufi Medium' className={classes.text}>
            {title}
          </Typography>
          {isOrderLoading && <OrderCircularProgress />}
        </Box>
        {subTitle && (
          <Typography fontSize='12px' className={classes.text}>
            {subTitle}
          </Typography>
        )}
        {deliveryStatus && (
          <>
            <Typography fontSize='14px' className={classes.text}>
              {t("Order delivery status")}:{" "}
            </Typography>
            <Typography fontSize='14px' className={classes.text} fontFamily='Airbnb Cereal App Medium, Arab Kufi Medium'>
              {deliveryStatus}
            </Typography>
          </>
        )}
      </div>
      <>{children}</>
      {buttonTitle && (
        <Button className={classes.button} onClick={handleClick}>
          {buttonTitle}
        </Button>
      )}
    </div>
  );
};

HeaderCard.defaultProps = {
  buttonTitle: "",
  subTitle: "",
  deliveryStatus: undefined,
  onClick: () => {},
  isOrderLoading: false,
};
