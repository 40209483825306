import { useTranslation } from "@health/i18n";
import { VendorBranchTypeEnum } from "@health/queries";

export const useVendorBranchTypesEnumOptions = () => {
  const optionsTypes = [
    VendorBranchTypeEnum?.PartnerWarehouse,
    VendorBranchTypeEnum?.Pharmacy,
    VendorBranchTypeEnum?.Phc,
    VendorBranchTypeEnum?.ReferencePharmacy,
  ];
  const { t } = useTranslation("vendor");
  const enhancedOptions = optionsTypes.map(o => {
    const [name, value] = Object.entries(VendorBranchTypeEnum).find(([, v]) => v === o)!;
    return {
      name,
      value,
      translatedName: t(name, { context: "en" }),
    };
  });

  const getEnumForTranslatedLabel = (label: string) => {
    if (!label) return label;
    return enhancedOptions.find(({ translatedName }) => translatedName === label?.trim())!.value;
  };
  return {
    optionsTypes,
    enhancedOptions,
    getEnumForTranslatedLabel,
  };
};
