const Branches = "branches";
const newBranch = "new";
const editBranch = `edit/:id`;

export const branchesRoutes = {
  path: Branches,
  name: "Branches Management",
  fullPath: Branches,
  branches: {
    name: "Branches",
    new: {
      name: "new Branch",
      path: [Branches, newBranch].join("/"),
      fullPath: [Branches, newBranch].join("/"),
    },
    edit: {
      name: "Edit Branch",
      path: [Branches, editBranch].join("/"),
      fullPath: [Branches, editBranch].join("/"),
    },
  },
};
