import { useOpenStateController } from "@health/common";
import { useTranslation } from "@health/i18n";
import { formatMessageErrors, OrderStatus, useVendorOrderCancelMutation } from "@health/queries";
import { Box, Button, Checkbox, CustomDialog, CustomIcon, FormControlLabel, TextField, Tooltip, Typography, useAddToast } from "@health/ui";
import React, { FC, useState } from "react";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderCancelModelStyles } from "./OrderCancelModel.styles";

export const OrderCancelButton: FC<{
  id: string;
  patientName: string | null;
  patientMobile: string;
  isMarketPlaceOrder: boolean;
  status: OrderStatus;
}> = props => {
  const { t } = useTranslation("vendor");
  const { id, patientName, patientMobile, isMarketPlaceOrder, status } = props;
  const { succeeded, failed } = useAddToast();
  const { classes } = useOrderCancelModelStyles();
  const cancelText = isMarketPlaceOrder ? t("Cancel Order") : t("Cancel eRx");
  const { open, handleOpen, handleClose } = useOpenStateController();
  const [cancellationReason, setCancellationReason] = useState("");
  const [checked, setChecked] = useState(false);

  const [fetchVendorOrderCancel, { loading }] = useVendorOrderCancelMutation({
    onCompleted: data => {
      if (data?.vendorOrderCancel?.orderErrors?.length === 0) {
        succeeded(t("Order Canceled Successfully"));
        handleClose();
        setChecked(false);
        setCancellationReason("");
      } else {
        const msg = formatMessageErrors(data?.vendorOrderCancel?.orderErrors);
        failed(t(msg));
      }
    },
    onError: ({ graphQLErrors, message }) => {
      failed(formatMessageErrors(graphQLErrors) || formatMessageErrors(message));
    },
  });
  const handleCancellationReasonChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setCancellationReason(e.target.value);
  };

  const handleChangeChecked = e => {
    setChecked(e.target.checked);
  };

  const handleVendorOrderCancel = () => {
    if (!isMarketPlaceOrder && status === OrderStatus.InProgress) {
      failed(t("Can't cancel an order with status in progress"));
      handleClose();
    } else {
      fetchVendorOrderCancel({
        variables: {
          id,
          cancellationReason,
        },
      });
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: isMarketPlaceOrder ? undefined : classes.paper,
      }}
      maxWidth={isMarketPlaceOrder ? "xs" : undefined}
      title={isMarketPlaceOrder ? t("Confirm Cancel Order") : t("eRx Cancellation")}
      button={
        <Tooltip title={cancelText}>
          <Button color='error' variant='outlined' className={classes.cancel} onClick={handleOpen}>
            {isMarketPlaceOrder ? t("Cancel") : t("Cancel eRx")}
          </Button>
        </Tooltip>
      }
      footerComponent={
        <>
          <CircularIntegration loading={loading}>
            <Button
              className={classes.reject}
              disabled={!isMarketPlaceOrder && (!checked || !cancellationReason)}
              onClick={handleVendorOrderCancel}
            >
              {cancelText}
            </Button>
          </CircularIntegration>
          <Button onClick={handleClose} variant='outlined'>
            {t("Close")}
          </Button>
        </>
      }
      onCloseModal={handleClose}
    >
      {isMarketPlaceOrder ? (
        <>
          <Typography fontSize={20} marginTop={3} marginBottom={1} color={"#973149"} textAlign='center'>
            {t("Are you sure you want to cancel this order?")}
          </Typography>
          <TextField
            fullWidth
            value={cancellationReason}
            label={t("Cancellation reason")}
            placeholder={t("Cancellation Reason")}
            onChange={handleCancellationReasonChange}
          />
        </>
      ) : (
        <>
          <Typography className={`${classes.title} ${classes.mobile}`} gutterBottom>
            {patientName}
          </Typography>

          <Box className={classes.box}>
            <a href={`tel:${patientMobile}`} className={classes.call}>
              <CustomIcon icon='call' transform='rotate(90deg)' viewBox='-2 -2 27 27' width={40} height={40} />
            </a>
            <Typography className={`${classes.title}`} gutterBottom>
              {patientMobile}
            </Typography>
          </Box>

          <TextField
            fullWidth
            size='small'
            value={cancellationReason}
            label={t("Cancellation reason")}
            placeholder={t("Cancellation Reason")}
            onChange={handleCancellationReasonChange}
          />

          <FormControlLabel
            className={classes.formControlLabel}
            control={<Checkbox onChange={handleChangeChecked} checked={checked} />}
            label={t<string>("I contacted the patient to confirm the cancellation")}
          />
        </>
      )}
    </CustomDialog>
  );
};
