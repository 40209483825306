/* eslint-disable sonarjs/no-duplicate-string */
import { getMediaLink } from "@health/common";
import { useTranslation } from "@health/i18n";
import { OrderDeliveryStatusEnum, OrderLine } from "@health/queries";
import { Avatar, Box, TruncateTypography, Typography } from "@health/ui";
import { FC } from "react";
import { OrderSection } from "./OrderSection";
import { useOrdersFromCheckoutStyles } from "./ordersFromCheckout.styles";

type OrdersFromCheckoutProps = {
  city: string;
  number: number;
  color: string;
  products: OrderLine[];
  deliveryStatus: OrderDeliveryStatusEnum;
  branchName: string;
};

export const OrdersFromCheckout: FC<OrdersFromCheckoutProps> = props => {
  const { number, deliveryStatus, products, city, color, branchName } = props;
  const { t } = useTranslation("vendor");
  const { classes } = useOrdersFromCheckoutStyles();

  return (
    <>
      <Box sx={{ borderTop: "1px solid #DFE8F2" }} />
      <OrderSection title={t("Order ID")} subTitle={String(number)!} color={color} />
      <Box sx={{ borderTop: "1px solid #DFE8F2" }} />
      <OrderSection title={t("Branch")} subTitle={branchName} color={color} />
      {deliveryStatus && (
        <Box sx={{ borderTop: "1px solid #DFE8F2" }}>
          <OrderSection title={t("Delivery Status")} subTitle={deliveryStatus} color={color} />
        </Box>
      )}
      <OrderSection hasBG title={t("Products")} color={color}>
        {products?.map(line => (
          <Box key={line?.id} className={classes.box}>
            <Avatar variant='square' src={getMediaLink(line?.thumbnail?.url!)} className={classes.avatar} />
            <div>
              <TruncateTypography text={line?.productName} className={classes.text} />
              <Typography className={classes.text}>
                {t("Qty")}: {line.quantity}
              </Typography>
            </div>
          </Box>
        ))}
      </OrderSection>
      <OrderSection title={t("Delivery address")} subTitle={city} color={color} />
    </>
  );
};
