import { ApolloClient } from "@apollo/client";

export const handleDeleteEntityFromCache = (typeName: string, id: string | undefined | null, client: ApolloClient<object>) => {
  if (!id) return;
  const normalizedId = client.cache.identify({ id, __typename: typeName });
  if (!normalizedId) return;
  client.cache.evict({
    id: normalizedId,
  });
  client.cache.gc();
};

export const readItems = <T>(client: ApolloClient<object>, typeName: string): T[] => {
  const items: T[] = Object.entries(client.cache?.["data"]?.data)
    .filter(([key]) => key.includes(typeName))
    .map<T>(([, val]) => val as T);

  return items;
};

export const getItemByPredicate = <T>(client: ApolloClient<object>, typeName: string, predicate?: (item: T) => boolean): T => {
  return readItems<T>(client, typeName)?.find(predicate);
};
