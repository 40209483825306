import { i18n } from "@health/i18n";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import React, { Children, cloneElement, FC, isValidElement, ReactElement, useState } from "react";
import { ExpandIcon } from "../../icons";
import { useOrdersCardStyles } from "./OrdersCard.styles";
import { OrdersCardProps } from "./OrdersCard.types";
import { OrdersDialog } from "./OrdersDialog";
import { isOpenOrderCardDialog, OrderColorEnum } from "./utils";

export const OrdersCard: FC<OrdersCardProps> = ({ children, hasExpand = false, color = OrderColorEnum.primary }) => {
  const { classes } = useOrdersCardStyles({ color });
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    isOpenOrderCardDialog(!open);
    setOpen(!open);
  };

  const ChildrenComponent = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child as ReactElement, { open });
    }
  });
  return (
    <Card className={`${classes.card} ${classes.border}`}>
      {hasExpand && (
        <IconButton onClick={handleToggle} className={i18n.language == "ar" ? `${classes.icon} ${classes.iconRtl}` : classes.icon}>
          <ExpandIcon />
        </IconButton>
      )}
      {open && (
        <OrdersDialog open={open} color={color} onToggle={handleToggle}>
          {ChildrenComponent}
        </OrdersDialog>
      )}

      {children}
    </Card>
  );
};
