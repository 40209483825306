import React, { FC } from "react";
import BranchForm from "../BranchForm.component";
import { useBranchNewPageHook } from "./BranchesNewPage.hook";

const NewBranch: FC = () => {
  const { errors, handleCreateBranch } = useBranchNewPageHook();

  return <BranchForm errors={errors} onSubmit={handleCreateBranch} />;
};

export default NewBranch;
