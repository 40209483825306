import { useConsumerViewPreferenceQuery, useConsumerViewPreferenceSaveMutation } from "@health/queries";

export const useTablePreferences = (view: string, staticColumns: any) => {
  const {
    data,
    loading: isColumnsLoading,
    refetch,
  } = useConsumerViewPreferenceQuery({
    variables: {
      view: view,
    },
  });

  const tablePreferences = data?.consumerViewPreference?.data && JSON.parse(data?.consumerViewPreference?.data);

  const [saveTablePreferences] = useConsumerViewPreferenceSaveMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const columns = tablePreferences?.columns?.map(item => {
    return { ...staticColumns?.find(static_c => static_c.key === item?.key), isHidden: item?.isHidden };
  });

  return {
    isColumnsLoading,
    sortBy: tablePreferences?.sortBy,
    columns,
    saveTablePreferences,
  };
};
