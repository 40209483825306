import { useTranslation } from "@health/i18n";
import { Autocomplete, getTranslatedNameField, Grid, Paper, TextField, Typography } from "@health/ui";
import React, { FC } from "react";
import { Communications, CommunicationSelectedUser, ConversationStudio } from "../../components";
import { useVendorChatPageHooks } from "../../hooks/useVendorChatPageHooks";
import { useStyles } from "../styles";
import { ListBoxComponent } from "./ListBoxComponent";

export const ChatPage: FC = () => {
  const { classes } = useStyles();
  const {
    branches,
    customers,
    hasMoreCustomers,
    isCustomersLoading,
    isBranchesLoading,
    isMessagesFetchingMore,
    branchMessages,
    branch,
    client,
    hasMoreMessages,
    isMessagesLoading,
    hasMoreBranches,
    attachment,
    handleUploadRecord,
    handleLoadMoreMessages,
    handleSendMessage,
    handleDiscardAttachmentItem,
    onFetchMoreBranches,
    handleSelectClient,
    handleFetchMoreCustomers,
    handleUploadImage,
    handleDiscardAttachment,
    handleBranchChanged,
  } = useVendorChatPageHooks();

  const { t } = useTranslation();

  return (
    <Grid container spacing={2} p='40px'>
      <Grid item xs={3}>
        <Paper square={true} className={classes.clientsPaper}>
          {!isBranchesLoading && branches && (
            <Autocomplete
              loading={isBranchesLoading}
              options={branches || []}
              getOptionLabel={branchItem => getTranslatedNameField(branchItem)}
              onChange={handleBranchChanged}
              defaultValue={branches[0] || null}
              disableClearable
              className={classes.auto}
              ListboxComponent={listBoxProps => {
                return (
                  <ListBoxComponent
                    {...listBoxProps}
                    options={branches?.length || 0}
                    fetchMoreData={onFetchMoreBranches}
                    hasMore={hasMoreBranches}
                    customFilter={""}
                  />
                );
              }}
              renderInput={props => <TextField variant='outlined' placeholder={t("Select Branch", "Select Branch")} {...props} />}
            />
          )}
          <Communications
            selectedClient={client}
            clients={customers}
            isCustomersLoading={isCustomersLoading}
            onSelectClient={handleSelectClient}
            onFetchMoreCustomers={handleFetchMoreCustomers}
            hasMoreCustomers={hasMoreCustomers}
            branchId={branch?.id}
          />
        </Paper>
      </Grid>
      <Grid item xs={9}>
        <div className={classes.contain}>
          {client && (
            <CommunicationSelectedUser
              title={`${client?.firstName} ${client?.lastName}`}
              subTitle={client?.email}
              avatar={client?.avatar}
            />
          )}
          <div className={classes.conversationContainer}>
            {client && branch ? (
              <ConversationStudio
                client={client}
                isLoadingMessages={isMessagesLoading}
                isFetchingMoreMessages={isMessagesFetchingMore}
                attachment={attachment}
                messages={branchMessages as any}
                onLoadMoreMessages={handleLoadMoreMessages}
                hasNextPage={hasMoreMessages}
                onSendMessage={handleSendMessage}
                onUploadImage={handleUploadImage}
                onUploadRecord={handleUploadRecord}
                onDiscardAttachment={handleDiscardAttachment}
                onDiscardAttachmentItem={handleDiscardAttachmentItem}
              />
            ) : (
              <div className={classes.noClientBranch}>
                <Typography variant={"h4"}>{t("Please select branch and client", "Please select branch and client")}</Typography>
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
