import { bg, makeStyles, primary, white } from "@health/ui";

export const BOX_SHADOW = "0px 7px 29px #64646f33";

export const useStyles = makeStyles()({
  root: {
    height: "calc(60vh- 70px)",
    width: "100%",
    background: white,
    borderRadius: "0 0 15px 15px",
  },
  container: {
    padding: "0px 15px",
    backgroundColor: "#ccc",
  },
  clientsPaper: {
    width: "100%",
    border: `1px solid #E1E8F1`,
    borderRadius: 10,
  },
  contain: {
    border: "1px solid #E1E8F1",
    borderRadius: "15px",
  },
  auto: {
    borderBottom: "1px solid #E1E8F1",
    "& .MuiFormControl-root": {
      padding: "10px !important",
    },
  },
  initialView: {
    display: "flex",
    height: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  filterChat: {
    padding: 10,
    background: white,
    height: 105,
    zIndex: 20,
    borderBottom: "1px solid #60779526",
  },
  conversationContainer: {},
  noClientBranch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(94vh - 150px)",
    width: "100%",
  },
  height: {
    height: "69vh",
  },
  search: {
    width: 30,
    height: 30,
    "&:hover": {
      backgroundColor: bg,
      color: primary,
    },
  },
  text: {
    opacity: 0.2,
    fontSize: 20,
    textAlign: "center",
  },
  input: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 25,
      height: "40px",
    },
    "& .MuiOutlinedInput-input": {
      borderRadius: 25,
    },
  },
  box: {
    backgroundColor: "#fff",
    overflow: "auto",
    borderRadius: "15px",
    border: "1px solid #DAE0E7",
  },
  grid: {
    height: "100%",
    width: "100%",
  },
});
