import { getMaxLengthValidation, getMinLengthValidation, patternEmail, patternMobile } from "@health/common";

export const generalInformationFields = (t, defaultValue) => [
  {
    name: "name",
    label: t("Branch English Name"),
    defaultValue: defaultValue?.name,
    maxLength: getMaxLengthValidation(t, 255),
    minLength: getMinLengthValidation(t, 3),
  },
  {
    name: "nameAr",
    label: t("Branch Arabic Name"),
    defaultValue: defaultValue?.nameAr,
    maxLength: getMaxLengthValidation(t, 255),
    minLength: getMinLengthValidation(t, 3),
  },
  {
    name: "description",
    label: t("Description"),
    defaultValue: defaultValue?.description,
  },
  {
    name: "contactEmail",
    label: t("Contact Email"),
    defaultValue: defaultValue?.contactEmail,
    pattern: patternEmail,
  },
  {
    name: "contactNumber",
    label: t("Contact Number"),
    defaultValue: defaultValue?.contactNumber,
    pattern: patternMobile,
  },

  {
    name: "healthLicense",
    label: t("Health License"),
    defaultValue: defaultValue?.healthLicense,
    maxLength: getMaxLengthValidation(t, 255),
    minLength: getMinLengthValidation(t, 3),
  },
];
