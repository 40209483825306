/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { useTranslation } from "@health/i18n";
import {
  formatGraphQLError,
  formatMessageErrors,
  useVendorProductVariantQuery,
  useVendorProductVariantStocksCreateMutation,
  useVendorProductVariantStocksUpdateMutation,
  VendorProductVariantDocument,
  VendorProductVariantStocksCreateMutation,
  VendorProductVariantStocksUpdateMutation,
} from "@health/queries";
import { useAddToast } from "@health/ui";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const generateRandomId = length => {
  const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let randomId = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }

  return randomId;
};

export const useProductFormState = () => {
  const { t } = useTranslation("vendor");
  const navigate = useNavigate();

  const params = useParams();
  const productVariantId = params?.id as string;
  const { ...methods } = useForm({
    mode: "onChange",
  });
  const { handleSubmit, control, watch } = methods;

  const { fields, remove, prepend } = useFieldArray({
    control,
    name: "stocks",
  });

  const { succeeded, failed } = useAddToast();

  const { data, loading: vendorProductVariantLoading } = useVendorProductVariantQuery({
    variables: {
      id: productVariantId,
    },
    skip: !productVariantId,
  });
  const vendorProductVariantId = data?.productVariant?.vendorProductVariant?.id;
  const productVariant = data?.productVariant;

  const [vendorProductVariantStocksCreate] = useVendorProductVariantStocksCreateMutation({
    onCompleted: (response: VendorProductVariantStocksCreateMutation) => {
      const errors = response?.vendorProductVariantStocksCreate?.stockErrors;
      if (errors?.length === 0) {
        succeeded(t("Product Variant Stocks Created Successfully"));
        navigate(-1);
      } else if (errors && errors?.length > 0) {
        const formattedMessage = formatMessageErrors(errors);
        failed(formattedMessage);
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedMessage = formatGraphQLError(graphQLErrors);
      console.error(formattedMessage);
      failed(t("Error while saving changes, please try again later"));
    },
    refetchQueries: [
      {
        query: VendorProductVariantDocument,
        variables: {
          id: productVariantId,
        },
      },
    ],
  });
  const [ProductVariantStocksUpdate] = useVendorProductVariantStocksUpdateMutation({
    onCompleted: (response: VendorProductVariantStocksUpdateMutation) => {
      const errors = response?.vendorProductVariantStocksUpdate?.stockErrors;
      if (errors && errors?.length > 0) {
        console.error(formatMessageErrors(errors), failed(formatMessageErrors(errors)));
      } else {
        succeeded(t("Product Variant Stocks Updated Successfully"));
        navigate(-1);
      }
    },
    onError: () => {
      failed(t("Product Variant Updated Failed"));
    },
    refetchQueries: [
      {
        query: VendorProductVariantDocument,
        variables: {
          id: productVariantId,
        },
      },
    ],
  });

  const update = input => {
    ProductVariantStocksUpdate({
      variables: {
        ...input,
      },
    });
  };
  const create = input => {
    vendorProductVariantStocksCreate({
      variables: {
        input: {
          ...input,
        },
      },
    });
  };
  const onSubmit = (data: any) => {
    const { stocks, ...rest } = data;
    const formattedStocks = stocks.map((stock: any) => ({
      branch: stock.branch?.id,
      quantity: Number(stock.quantity),
    }));
    const request = {
      ...rest,
      stocks: formattedStocks,
      productVariant: productVariantId,
    };

    const input = {
      id: productVariantId,
      input: {
        ...rest,
        stocks: formattedStocks,
        productVariant: productVariantId,
      },
    };
    if (vendorProductVariantId === undefined) {
      create(request);
    } else {
      update(input);
    }
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const isLoading = vendorProductVariantLoading;

  const insertNewEmptyBranchQuantity = () => {
    prepend({
      branch: null,
      quantity: null,
    });
  };
  const getDefaultVariants = () => {
    if (productVariant?.vendorProductVariant?.stocks?.length !== 0 && !vendorProductVariantLoading) {
      productVariant?.vendorProductVariant?.stocks &&
        productVariant?.vendorProductVariant?.stocks.forEach(stock => {
          prepend({
            id: generateRandomId(8),
            branch: stock?.branch,
            quantity: stock?.quantity,
          });
        });
    } else {
      insertNewEmptyBranchQuantity();
    }
  };
  useEffect(() => {
    const hasVendorProductVariant =
      productVariant?.vendorProductVariant?.id &&
      productVariant?.vendorProductVariant?.stocks &&
      productVariant?.vendorProductVariant?.stocks?.length > 0;

    if (!vendorProductVariantLoading) {
      if (!hasVendorProductVariant) {
        fields?.length === 0 && insertNewEmptyBranchQuantity();
      } else {
        getDefaultVariants();
      }
    }
  }, [productVariant, vendorProductVariantLoading]);
  const handleAddRow = () => {
    insertNewEmptyBranchQuantity();
  };

  const handleRemoveRow = (index: number) => {
    const watchedData = watch(`stocks.${index}`);
    const hasDefaultValue = productVariant?.vendorProductVariant?.stocks?.[index]?.branch?.id === watchedData?.branch?.id;

    if (!hasDefaultValue) {
      remove(index);
    }
  };

  const handleAddWarehouseRow = () => {
    handleAddRow();
  };

  return {
    create,
    update,
    onSubmit,
    isLoading,
    productVariant,
    vendorProductVariantId,
    handleCancel,
    methods,
    handleSubmit,
    handleAddRow,
    handleRemoveRow,
    handleAddWarehouseRow,
    fields,
  };
};
