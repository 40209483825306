import { MessageFragmentFragmentDoc } from "@health/queries";

export const handleAddClientNewMessage = (cache, message, user) => {
  cache.modify({
    id: cache.identify(user),
    fields: {
      unReadMessagesCount(cachedValue) {
        return Number(cachedValue) + 1;
      },
      messages: handleAddNewMessage(cache, message),
    },
  });
};

export const handleClientMarkAsSeen = (cache, user, branchId, updatedCount) => {
  cache.modify({
    id: cache.identify(user),
    fields: {
      unReadMessagesCount(cachedValue) {
        return Number(cachedValue) - Number(updatedCount);
      },
    },
  });
  cache.modify({
    id: cache.identify(branchId),
    fields: {
      unReadMessagesCountForCurrentUser(cachedValue) {
        return Number(cachedValue) - Number(updatedCount);
      },
    },
  });
};

export const handleAddVendorNewMessage = (cache, message, branch, user) => {
  cache.modify({
    id: cache.identify(user),
    fields: {
      lastMessage: updateLastMessage(cache, message),
    },
  });
  cache.modify({
    id: cache.identify(branch),
    fields: {
      messages: handleAddNewMessage(cache, message),
    },
  });
};

const handleAddNewMessage =
  (cache, message) =>
  (existingMessages, { readField }) => {
    const newMessageRef = cache.writeFragment({
      data: message,
      fragment: MessageFragmentFragmentDoc,
    });
    const newEdgeRef = {
      __typename: "MessageCountableEdge",
      node: newMessageRef,
    };
    if (existingMessages?.edges.some(ref => readField("id", ref.node) === message?.id)) {
      return existingMessages;
    }
    return {
      ...existingMessages,
      edges: [newEdgeRef, ...existingMessages.edges],
    };
  };

const updateLastMessage = (cache, message) => () => {
  const newMessageRef = cache.writeFragment({
    data: message,
    fragment: MessageFragmentFragmentDoc,
  });
  return {
    __typename: "MessageCountableEdge",
    node: newMessageRef,
  };
};
