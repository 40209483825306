import { makeStyles, white } from "@health/ui";

export const useImagesMessageStyles = makeStyles()({
  img: {
    height: 80,
    width: 80,
    borderRadius: 8,
    backgroundColor: white,
    margin: 4,
  },
});
