import { getMediaLink } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Box, Button, CircularProgress, IconButton, TrashIcon, Typography } from "@health/ui";
import React, { FC } from "react";
import { useAttachmentSendPreviewerStyles } from "./AttachmentSendPreviewer.styles";
import { AttachmentSendPreviewerProps } from "./AttachmentSendPreviewer.types";

export const AttachmentSendPreviewer: FC<AttachmentSendPreviewerProps> = props => {
  const { classes } = useAttachmentSendPreviewerStyles();
  const { t } = useTranslation();

  const { attachment, onDiscardAttachment, onDiscardAttachmentItem, loadingAttachment } = props;

  const handleDiscardAttachmentItem = (itemId: string) => () => {
    onDiscardAttachmentItem?.(itemId);
  };

  const handleDiscardAttachment = () => {
    onDiscardAttachment?.();
  };

  return (
    <Box className={classes.root}>
      {loadingAttachment && <CircularProgress size={"40px"} sx={{ margin: 0 }} />}
      <Box sx={{ flex: 1 }}>
        {attachment.map(att => {
          if (att.contentType.includes("audio")) {
            return (
              <audio key={att?.id} className={classes.audio} src={getMediaLink(att?.file)} controls>
                <track kind='captions' />
              </audio>
            );
          } else if (att.contentType.includes("image")) {
            return (
              <div className={classes.imgWrapper}>
                <img key={att?.id} className={classes.img} src={getMediaLink(att?.file)} alt={att?.alt || "image"} />
                <IconButton className={classes.imgRemoveButton} onClick={handleDiscardAttachmentItem(att?.id)} size='small'>
                  <TrashIcon />
                </IconButton>
              </div>
            );
          } else {
            return <Typography>{t("Content type not supported")}</Typography>;
          }
        })}
      </Box>
      {attachment?.length > 0 && (
        <Button
          variant='contained'
          sx={{
            width: 70,
            marginInlineStart: "15px",
          }}
          onClick={handleDiscardAttachment}
        >
          {t("discard", "discard")}
        </Button>
      )}
    </Box>
  );
};
