import { TFunction, useTranslation } from "@health/i18n";
import { OrderStatus, Patient } from "@health/queries";
import { makeStyles } from "@health/ui";
import moment, { Moment } from "moment";
import { FC } from "react";

type BeneficiaryInfoProps = {
  patient: Patient;
  status: string;
};

const useBeneficiaryInfoStyles = makeStyles()(() => ({
  list: {
    margin: 0,
    marginInline: 12,
    padding: 0,
    fontSize: 13,
  },
}));

const getPatientAge = (dateOfBirth: Date | Moment | null | undefined, t: TFunction) => {
  const ageInYears = moment().diff(dateOfBirth, "years");
  const ageWithYear = t("year", { count: ageInYears });
  const ageInMonths = moment().diff(dateOfBirth, "months");
  const ageWithMonth = t("month", { count: ageInMonths });

  const ageInDays = moment().diff(dateOfBirth, "days");
  const ageWithDay = t("day", { count: ageInDays });

  if (ageInYears) return ageWithYear;
  else return ageInMonths ? ageWithMonth : ageWithDay;
};
export const BeneficiaryInfo: FC<BeneficiaryInfoProps> = props => {
  const { patient, status } = props;
  const { t } = useTranslation("vendor");
  const { classes } = useBeneficiaryInfoStyles();

  const gender = patient?.gender === "M" || patient?.gender === "Male" || patient?.gender === "MALE" ? t("Male") : t("Female");

  return (
    <ul className={classes.list}>
      {status !== OrderStatus.NewRequest && status !== OrderStatus.AcceptedByProvider && (
        <>
          {patient?.firstName && (
            <li>
              {t("Name")} : {patient?.firstName + " " + patient?.lastName}
            </li>
          )}
        </>
      )}
      {patient?.dateOfBirth || patient?.gender ? (
        <>
          {patient?.dateOfBirth && (
            <li>
              {t("Age")}: {getPatientAge(patient?.dateOfBirth, t)}
            </li>
          )}
          {patient?.gender && (
            <li>
              {t("Gender")}: {gender}
            </li>
          )}
        </>
      ) : (
        <>-</>
      )}
    </ul>
  );
};
