import { useTranslation } from "@health/i18n";
import { darkGrey, makeStyles, Typography } from "@health/ui";
import { FC } from "react";

type OrderTimeoutProps = {
  title: string;
  time?: number;
  color: string;
};

export const useOrderTimeoutStyles = makeStyles<{ color: string }>()((_, { color }) => ({
  title: {
    fontSize: 12,
    color: darkGrey,
  },
  time: {
    color,
    fontFamily: "Airbnb Cereal App Medium, Arab Kufi Medium",
  },
}));

export const OrderTimeout: FC<OrderTimeoutProps> = props => {
  const { title, time, color } = props;
  const { classes } = useOrderTimeoutStyles({ color });

  const { t } = useTranslation("vendor");

  const timer = Number(time) / 60;
  const timeValue = Number(time) % 60 !== 0 ? timer.toFixed(2) : timer;

  return (
    <>
      {time && (
        <Typography className={classes.title}>
          {title} : <span className={classes.time}>{t("min", { count: Number(timeValue) })}</span>
        </Typography>
      )}
    </>
  );
};

OrderTimeout.defaultProps = {
  time: undefined,
};
