import { useTranslation } from "@health/i18n";
import { formatGraphQLError, useVendorOrderAcceptMutation } from "@health/queries";
import { Button, CircularProgress, Tooltip, useAddToast } from "@health/ui";
import { FC } from "react";

export interface AcceptMarketplaceOrderProps {
  orderId: string;
}

export const AcceptMarketplaceOrder: FC<AcceptMarketplaceOrderProps> = ({ orderId }) => {
  const { t } = useTranslation("vendor");
  const { failed, succeeded } = useAddToast();

  const [orderVendorAccept, { loading }] = useVendorOrderAcceptMutation({
    onCompleted: data => {
      if (data?.vendorOrderAccept?.orderErrors?.length === 0) {
        succeeded(t("Order accepted successfully"));
      } else {
        const msg = data?.vendorOrderAccept?.orderErrors[0];
        failed(msg?.field + " " + msg?.message);
      }
    },
    onError: ({ networkError }) => {
      failed(formatGraphQLError(networkError?.["result"]?.Errors));
    },
  });

  const handleOrderAccepted = (): void => {
    orderVendorAccept({
      variables: {
        id: orderId,
      },
    });
  };

  return (
    <Tooltip title={t("Accept")}>
      <Button disabled={loading} variant={"contained"} color='success' onClick={handleOrderAccepted}>
        {loading && <CircularProgress size={20} />} {t("Accept")}
      </Button>
    </Tooltip>
  );
};
