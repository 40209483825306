import { useTranslation } from "@health/i18n";
import { Box, CustomToggleButton, Typography } from "@health/ui";
import React, { FC } from "react";
import { useOrderPageContext } from "../state";

export const DeliveryTabVisibilityToggle: FC = () => {
  const { t } = useTranslation("vendor");
  const { isDeliveringColumnVisible, onToggleDeliveringColumnVisibility: handleToggleDeliveringColumnVisibility } = useOrderPageContext();

  return (
    <Box display='flex' alignItems='center' paddingRight='20px'>
      <CustomToggleButton checked={isDeliveringColumnVisible} onChange={handleToggleDeliveringColumnVisibility} />
      <Typography noWrap fontSize='14px'>
        {t("Show Delivering Panel")}
      </Typography>
    </Box>
  );
};
