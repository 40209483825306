import { i18next } from "@health/i18n";
import { AppRoleTypes, UserStatus } from "@health/queries";
import { FieldsTypes, FilterField } from "@health/ui";

const options = [AppRoleTypes?.Admin, AppRoleTypes?.User];

const optionsStatus = [UserStatus?.Active, UserStatus?.Deactivated];

export const fieldsFilter = (t: i18next.TFunction, activeFilters: Record<string, any>): FilterField[] => [
  {
    type: FieldsTypes.input,
    name: "search",
    fieldProps: {
      placeholder: t("Search"),
      label: t("Search"),
      name: "search",
      defaultValue: activeFilters?.search,
    },
  },
  {
    type: FieldsTypes.autocomplete,
    name: "status",
    fieldProps: {
      placeholder: t("Status"),
      label: t("Status"),
      name: "status",
      options: optionsStatus,
      defaultValue: activeFilters?.status,
      getOptionLabel: o => t(o),
    },
  },
  {
    type: FieldsTypes.autocomplete,
    name: "appRole",
    fieldProps: {
      placeholder: t("App Role"),
      label: t("App Role"),
      name: "appRole",
      options: options,
      defaultValue: activeFilters?.appRole,
      getOptionLabel: o => t(o),
    },
  },
];
