import { useTranslation } from "@health/i18n";
import { formatMessageErrors, useSetMarketPlaceOrderOutForDeliveryMutation } from "@health/queries";
import { Button, useAddToast } from "@health/ui";
import { FC } from "react";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderActionStyles } from "./OrderAction.styles";

export const SetMarketPlaceOrderReadyForDeliveryButton: FC<{ id: string; submitLabel: string }> = ({ id, submitLabel }) => {
  const { t } = useTranslation("vendor");
  const { classes } = useOrderActionStyles();
  const { failed, succeeded } = useAddToast();

  const [fetchSetMarketPlaceOrderOutForDelivery, { loading }] = useSetMarketPlaceOrderOutForDeliveryMutation({
    onCompleted: data => {
      if (data?.setOrderOutForDelivery?.orderErrors?.length === 0) {
        succeeded(t("Order delivery started successfully"));
      } else {
        const msg = formatMessageErrors(data?.setOrderOutForDelivery?.orderErrors);
        failed(t(msg));
      }
    },
    onError: ({ message }) => {
      failed(t(message));
    },
  });

  const handleOrderVendorFinishProcessing = (): void => {
    fetchSetMarketPlaceOrderOutForDelivery({
      variables: { id },
    });
  };

  const className = classes.accept;

  return (
    <CircularIntegration loading={loading}>
      <Button disabled={loading} className={className} onClick={handleOrderVendorFinishProcessing}>
        {submitLabel}
      </Button>
    </CircularIntegration>
  );
};
