import { Typography } from "@health/ui";
import React, { FC } from "react";
import { useCardContentStyles } from "./CardContent.styles";

type CardContentProps = {
  title: string;
  subTitle?: string | number | null;
};

const CardContent: FC<CardContentProps> = ({ title, subTitle }) => {
  const { classes } = useCardContentStyles();

  return (
    <>
      <Typography className={classes.textBold}>{title}</Typography>
      {subTitle && <Typography className={classes.text}>{subTitle}</Typography>}
    </>
  );
};

CardContent.defaultProps = {
  subTitle: "-",
};

export default CardContent;
