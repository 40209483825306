import { useReactiveVar } from "@apollo/client";
import { openPharmacyBranch } from "@health/ui";

export const usePharmacyBranchHook = () => {
  const open = useReactiveVar<boolean>(openPharmacyBranch);
  const handleToggle = () => {
    openPharmacyBranch(!openPharmacyBranch());
  };

  return {
    open,
    handleToggle,
  };
};
