import { useTranslation } from "@health/i18n";
import { formatMessageErrors, useBranchUpdateMutation } from "@health/queries";
import { CustomToggleButton, useAddToast, WarningConfirmationDialog } from "@health/ui";
import React, { FC, useEffect, useState } from "react";

interface ActiveToggleProps {
  id: string;
  isActive: boolean;
}

export const IsActiveToggleButton: FC<ActiveToggleProps> = ({ id, isActive }) => {
  const { t } = useTranslation("vendor");
  const [active, setActive] = useState(isActive);
  const { succeeded, failed } = useAddToast();
  const [open, setOpen] = React.useState(false);
  const [branchUpdate, { loading }] = useBranchUpdateMutation({
    onCompleted: data => {
      const errors = data?.branchUpdate?.branchErrors;
      if (errors?.length == 0) {
        setActive(data?.branchUpdate?.branch?.isActive as boolean);
        succeeded(t("Branch updated successfully"));
        setOpen(!open);
      } else {
        failed(formatMessageErrors(errors));
      }
    },
    onError: () => {
      failed(t("Branch update error"));
    },
  });
  useEffect(() => {
    setActive(isActive);
  }, [isActive]);
  const handleConfirmToggleActivation = () => {
    branchUpdate({
      variables: {
        id: id,
        branch: {
          isActive: !active,
        },
      },
    });
    handleToggleDialog();
  };

  const handleToggleDialog = () => {
    setOpen(!open);
  };

  const dialogBodyText = active
    ? t("By proceeding with this action, The branch will be inactive.")
    : t("By proceeding with this action, The branch will be active.");

  return (
    <>
      <CustomToggleButton checked={active} disabled={loading} onChange={handleToggleDialog} />
      {open && (
        <WarningConfirmationDialog
          maxWidth='sm'
          isOpen={open}
          cancelText={t("Cancel")}
          confirmText={t("Proceed")}
          bodyText={t(dialogBodyText)}
          subBodyText={t("Are you sure you want to proceed?")}
          titleText={active ? t("Deactivate branch confirmation") : t("Activate branch confirmation")}
          onClose={handleToggleDialog}
          onConfirm={handleConfirmToggleActivation}
        />
      )}
    </>
  );
};
