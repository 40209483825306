import { useOpenStateController } from "@health/common";
import { useTranslation } from "@health/i18n";
import { ActiveFilters, Box, mapActiveFiltersForHeader, PageWrapper } from "@health/ui";
import { FC } from "react";
import { PharmacyBranch } from "shared/components";
import { useOrderPageContext } from "../state";
import { OrdersTab } from "../tabs/OrdersTab.component";
import { formatDeliveryTimeSlot } from "../utils";
import { DeliveryTabVisibilityToggle } from "./DeliveryTabVisibilityToggle";
import { OrdersFilter } from "./OrdersFilter";
import { OrdersViewModeSelect } from "./OrdersViewModeSelect";

export const OrdersPageWrapper: FC = ({ children }) => {
  const { selectedView, filter } = useOrderPageContext();
  const { t, i18n } = useTranslation("vendor");
  const { open, handleToggle } = useOpenStateController();

  const pageHeaderFiltersMappers = {
    deliveryTimeSlot: value => (!value ? undefined : `${formatDeliveryTimeSlot(value?.startTime, value?.endTime, i18n.language)} `),
    source: value => (!value ? undefined : t(value?.name)),
  };
  const headerActiveFilters = mapActiveFiltersForHeader(filter || {}, pageHeaderFiltersMappers);
  headerActiveFilters[t("Order Number")] = headerActiveFilters?.["numbers"];
  headerActiveFilters[t("Order Source")] = headerActiveFilters?.["source"];
  delete headerActiveFilters?.["source"];
  delete headerActiveFilters?.["numbers"];
  return (
    <PageWrapper
      shouldRemoveBottomPadding
      start={
        <Box display='flex'>
          <DeliveryTabVisibilityToggle />
          <OrdersViewModeSelect />
          <ActiveFilters onOpenFilters={handleToggle} activeFilters={headerActiveFilters || {}} i18nNs='vendor' />
        </Box>
      }
      actions={
        <>
          <PharmacyBranch />
          <OrdersFilter open={open} onHandleToggle={handleToggle} />
        </>
      }
      header={selectedView === "Tabs" && <OrdersTab />}
    >
      {children}
    </PageWrapper>
  );
};
