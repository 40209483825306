import {
  formatMessageErrors,
  useCustomerMarkMessagesAsSeenMutation,
  useCustomerSendMessageMutation,
  useUploadAttachmentMutation,
  useVendorMarkMessagesAsSeenMutation,
} from "@health/queries";
import { useAddToast } from "@health/ui";

export const useChatQueries = () => {
  const { failed } = useAddToast();

  const [clientMarkAsSeen] = useCustomerMarkMessagesAsSeenMutation();

  const [vendorMarkAsSeen] = useVendorMarkMessagesAsSeenMutation();

  const [uploadAttachmentMutation, { loading: isAttachmentLoading }] = useUploadAttachmentMutation({
    onCompleted: data => {
      const { uploadAttachment } = data;
      if (uploadAttachment?.chatErrors?.length) {
        failed(formatMessageErrors(uploadAttachment?.chatErrors));
      }
    },
  });

  const [customerSendMessageMutation] = useCustomerSendMessageMutation();

  return { customerSendMessageMutation, clientMarkAsSeen, uploadAttachmentMutation, vendorMarkAsSeen, isAttachmentLoading };
};
