import {
  combineErrors,
  formGirdBreakPoints,
  formGirdSpacing,
  getMaxLengthValidation,
  getMinLengthValidation,
  getRequiredValidation,
} from "@health/common";
import { useTranslation } from "@health/i18n";
import { IntegrationGraphqlError, PharmacyCredentials } from "@health/queries";
import { Grid, OptionalLabel, TextField } from "@health/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const WasfatyCredentialForm: FC<{ errors: IntegrationGraphqlError[]; defaultValues: PharmacyCredentials }> = ({
  errors,
  defaultValues,
}) => {
  const { t } = useTranslation("vendor");
  const {
    register,
    formState: { errors: formErrors },
  } = useFormContext();
  const combinedErrors = combineErrors(formErrors, errors);
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          placeholder={t("Username")}
          label={t("Username")}
          {...register("userName", {
            required: getRequiredValidation(t, true),
            maxLength: getMaxLengthValidation(t, 255),
            minLength: getMinLengthValidation(t, 3),
          })}
          defaultValue={defaultValues?.userName}
          error={Boolean(combinedErrors?.userName?.message)}
          helperText={t(combinedErrors?.userName?.message)}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          placeholder={t("Password")}
          label={t("Password")}
          type='password'
          {...register("password", {
            required: getRequiredValidation(t, true),
            maxLength: getMaxLengthValidation(t, 255),
            minLength: getMinLengthValidation(t, 3),
          })}
          error={Boolean(combinedErrors?.password?.message)}
          helperText={t(combinedErrors?.password?.message)}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          placeholder={t<string>("token")}
          label={<OptionalLabel label={t<string>("token")} />}
          {...register("token", { maxLength: getMaxLengthValidation(t, 255), minLength: getMinLengthValidation(t, 3) })}
          error={Boolean(combinedErrors?.token?.message)}
          helperText={t(combinedErrors?.token?.message)}
        />
      </Grid>
    </Grid>
  );
};
