/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/forbid-component-props */
/* eslint-disable react/forbid-dom-props */
import { InfiniteScroll, primary } from "@health/ui";
import React, { forwardRef } from "react";

export const ListBoxComponent = forwardRef(function ListboxComponentInner({ options, fetchMoreData, hasMore, customFilter, ...rest }: any) {
  return (
    <InfiniteScroll
      height={customFilter == "" ? 200 : "100%"}
      style={{ overflow: "auto" }}
      dataLength={options?.length || 0}
      loader={hasMore && customFilter == "" && <h5 style={{ padding: "0px 20px", color: primary }}>Loading...</h5>}
      hasMore={hasMore}
      next={() => {
        fetchMoreData();
      }}
      initialScrollY={options?.length > 20 ? 20 * options?.length - 1 : 0}
    >
      <span {...rest} style={{ minHeight: "100%" }} />
    </InfiniteScroll>
  );
});
