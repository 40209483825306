import { darkRed, green, makeStyles, white } from "@health/ui";
const displayButton = "inline-block";
const textOverflowEllipsis = "ellipsis";
const overflowHidden = "hidden !important";
export const useOrderActionStyles = makeStyles()(() => ({
  outlinedAccept: {
    color: green,
    border: `1px solid ${green}`,
    whiteSpace: "nowrap",
    display: displayButton,
    textOverflow: textOverflowEllipsis,
    overflow: overflowHidden,
    padding: "7px 20px",
    "&:hover": {
      color: white,
      border: `1px solid ${green}`,
      background: `${green} !important`,
    },
  },
  buttons: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    fontSize: 13,
    whiteSpace: "nowrap",
    display: displayButton,
    textOverflow: textOverflowEllipsis,
    overflow: overflowHidden,
  },
  accept: {
    margin: 5,
    padding: "8px 20px",
    whiteSpace: "nowrap",
    display: displayButton,
    textOverflow: textOverflowEllipsis,
    overflow: overflowHidden,
    backgroundColor: `${green} !important`,
    "&:disabled": {
      color: white,
      opacity: 0.5,
      background: green,
    },
    "&:hover": {
      color: white,
      opacity: 0.9,
      background: green,
    },
  },
  dispense: {
    border: `1px solid ${green} !important`,
    color: `${green} !important`,
    backgroundColor: `${white} !important`,
    "&:disabled": {
      color: green,
      opacity: 0.5,
      background: white,
    },
    "&:hover": {
      backgroundColor: `${green} !important`,
      color: `${white} !important`,
    },
  },
  insurance: {
    margin: 5,
    width: 180,
    background: green,
    "&:hover": {
      color: white,
      opacity: 0.9,
      background: green,
    },
  },
  reject: {
    margin: 5,
    paddingInline: 20,
    backgroundColor: `${darkRed} !important`,
    whiteSpace: "nowrap",
    "&:hover": {
      color: white,
      opacity: 0.9,
      background: darkRed,
    },
  },
  arabic: {
    margin: 5,
    padding: 25,
    background: green,
    "&:hover": {
      color: white,
      opacity: 0.9,
      background: green,
    },
  },
}));
