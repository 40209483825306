import { useTranslation } from "@health/i18n";
import { AppRoleTypes } from "@health/queries";
import { FormActions, FormCard, Grid, PageWrapper } from "@health/ui";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import { Branches } from "./Branches.components";
import { GeneralInformation } from "./GeneralInformation.component";
import { HealthLicense } from "./HealthLicense.components";
import { SearchByNatIdForm } from "./SearchByNatIdForm";
import { useUserFormHook } from "./UserForm.hook";
import { UserFormProps } from "./UserForm.types";

export const UserForm: FC<UserFormProps> = props => {
  const { isEditMode, user, isLoading, onDone, errors } = props;
  const { t } = useTranslation("vendor");

  const { appRole, methods, formErrors, doYouHaveData, isUserByNatIdLoading, onSave, handleSubmit, handleCancel, handleSearchByNatId } =
    useUserFormHook({
      errors,
      user,
      isEditMode,
      onDone,
    });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSave)}>
        <PageWrapper actions={<FormActions hasCancel hasEdit={isEditMode} hasSave={!isEditMode} onNavigation={handleCancel} />}>
          <Grid container spacing={2}>
            {!isEditMode && (
              <Grid item xs={12}>
                <FormCard title={t("National id search")} loading={isLoading} doYouHaveData={doYouHaveData}>
                  <SearchByNatIdForm onSearchByNatId={handleSearchByNatId} />
                </FormCard>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormCard title={t("General Information")} loading={isLoading || isUserByNatIdLoading} doYouHaveData={doYouHaveData}>
                <GeneralInformation defaultValues={user} errors={formErrors} />
              </FormCard>
            </Grid>

            {(appRole?.value !== AppRoleTypes.Admin || user?.appRole === AppRoleTypes.User) && (
              <Grid item xs={12}>
                <FormCard title={t("Branches")} loading={isLoading || isUserByNatIdLoading} doYouHaveData={doYouHaveData}>
                  <Branches defaultValues={user} errors={formErrors} />
                </FormCard>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormCard title={t("Health License")} loading={isLoading || isUserByNatIdLoading} doYouHaveData={doYouHaveData}>
                <HealthLicense defaultValues={user} errors={formErrors} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
