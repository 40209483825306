import { makeStyles } from "@health/ui";

export const useOrdersBodyStyles = makeStyles()(() => ({
  infinite: {
    overflow: "hidden !important",
    margin: "10px 0px !important",
    paddingInline: 7,
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: 20,
    },
    "&:hover": {
      scrollbarColor: "rgba(0,0,0,.1) transparent",
      scrollbarWidth: "thin",
      scrollbarGutter: "stable both-edges",
      overflowY: "auto !important" as any,
      overflow: "overlay !important",
    },
  },
}));
