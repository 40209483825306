import { i18next } from "@health/i18n";
import { ProductVariant } from "@health/queries";
import { CustomTableColumnProps, TruncateTypography } from "@health/ui";
import { ShowProductImages } from "./ShowProductImages.component";
import ShowProductStocks from "./ShowProductStocks.component";
import { formatAttributes, formatPrice } from "./utils";

export const ProductColumns = (t: i18next.TFunction): CustomTableColumnProps<ProductVariant>[] => [
  {
    key: "product-name",
    header: t("Product Name"),
    accessor: ({ product }) => <TruncateTypography text={product?.name || "--"} />,
  },
  {
    key: "variant-name",
    header: t("Variant Name"),
    accessor: ({ name }) => <TruncateTypography text={name || "--"} />,
  },
  {
    key: "sku",
    header: t("Sku"),
    accessor: "sku",
  },
  {
    key: "attributes",
    header: t("Attributes"),
    accessor: ({ attributes }) => (attributes?.length != 0 ? attributes?.map(item => formatAttributes(item)).join(" - ") : "--"),
  },
  {
    key: "productType",
    header: t("Product Type"),
    accessor: ({ product }) => <TruncateTypography text={product?.productType?.name || "--"} />,
  },
  {
    key: "category",
    header: t("Category"),
    accessor: ({ product }) => <TruncateTypography text={product?.category?.name || "--"} />,
  },
  {
    key: "images",
    header: t("Images"),
    accessor: row => <ShowProductImages row={row} />,
  },

  {
    key: "stocks",
    header: t("Stocks"),
    accessor: row => <ShowProductStocks row={row} />,
  },

  {
    key: "price",
    header: t("Price"),
    accessor: ({ vendorProductVariant }) => (vendorProductVariant?.price ? formatPrice(vendorProductVariant?.price, t) : "--"),
  },
];
