import { i18n } from "@health/i18n";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import React, { FC } from "react";
import { CollapseIcon } from "../../icons";
import { useOrdersCardStyles } from "./OrdersCard.styles";
import { OrdersDialogProps } from "./OrdersCard.types";
import { OrderColorEnum } from "./utils";

export const OrdersDialog: FC<OrdersDialogProps> = props => {
  const { children, open, onToggle: handleToggle, color = OrderColorEnum.primary } = props;
  const { classes } = useOrdersCardStyles({ color });

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      open={open}
      onClose={handleToggle}
      classes={{
        paper: `${classes.paper} ${classes.border}`,
      }}
    >
      <IconButton onClick={handleToggle} className={i18n.language == "ar" ? `${classes.icon} ${classes.iconRtl}` : classes.icon}>
        <CollapseIcon />
      </IconButton>
      <div>{children}</div>
    </Dialog>
  );
};
