/* eslint-disable sonarjs/no-identical-functions */
import { TFunction } from "@health/i18n";
import { OrderDataFragment, OrderDeliveryStatusEnum, OrderSource, OrderStatus } from "@health/queries";
import { OrderColorEnum } from "@health/ui";
import React from "react";
import { ActiveMedicationButton } from "../actions/ActiveMedicationButton";
import { HeaderCard } from "../cards/HeaderCard.component";
import { OrderTimeout } from "../cards/OrderTimeout";
import { getColorFromStatus, getTitle } from "./utils";
/**
 *
 * @param  {OrderDataFragment} order
 * @returns
 */
export const getSharedOrderData = order => {
  return {
    id: order?.id,
    status: order?.status,
    number: Number(order.number),
    type: order?.type,
    products: order?.lines,
    branch: order?.branch,
    deliveryDate: order?.deliveryDate,
    deliveryTimeSlot: order?.deliveryTimeSlot,
    iseRxOrder: Boolean(order?.prescription),
    isMarketPlaceOrder: Boolean(order?.source! === OrderSource.Checkout),
    patient: order?.prescription?.patient,
    shippingAddress: order?.shippingAddress,
    diagnosis: order?.prescription?.diagnosis,
    color: getColorFromStatus(order.status),
    medicalAlerts: order?.prescription?.medicalAlerts,
    medications: order?.lines?.map(line => ({ lineId: line?.id, ...line?.medication })),
  };
};

export const mapOrderToNewColumn = (order: OrderDataFragment, time, t) => {
  return {
    ...getSharedOrderData(order),
    timeOutComponent: <OrderTimeout title={t("Time to accept")} color={OrderColorEnum.primary} time={time?.orderAcceptanceTimeout!} />,
  };
};

const getMedication = line => ({ lineId: line.id, ...line?.medication });

export const mapOrderToPendingColumn = (order: OrderDataFragment, time, t) => ({
  ...getSharedOrderData(order),
  referenceNumber:
    order?.status === OrderStatus.Allocated || order?.status === OrderStatus.ErxHubAuthSubmitTimedOut
      ? order?.prescription?.referenceNumber
      : undefined,
  medications: order?.lines?.map(line => ({
    ...getMedication(line),
    display: line?.tradeDrug?.display,
    quantity: line?.quantity,
    img: line?.tradeDrug?.smallIconURL,
  })),
  headerComponent: (
    <HeaderCard
      title={getTitle(t)[String(order?.status)]}
      color={getColorFromStatus(String(order?.status)) || OrderColorEnum.warning}
      isOrderLoading={order?.status === OrderStatus.WaitingErxHubApproval || order?.status === OrderStatus.WaitingErxHubClaimApproval}
    >
      <ActiveMedicationButton patient={order?.prescription?.patient!} />
    </HeaderCard>
  ),
  timeOutComponent: order?.status === OrderStatus.ErxHubAuthSubmitTimedOut && (
    <OrderTimeout title={t("Time to dispense eRx")} color={OrderColorEnum.error} time={time?.orderDispensingTimeout!} />
  ),
});

export const mapOrderToDispensingColumn = (order: OrderDataFragment, time, t) => ({
  ...getSharedOrderData(order),
  medications: order?.lines
    ?.map(
      line =>
        line?.medication && {
          ...getMedication(line),
          display: line?.tradeDrug?.display,
          quantity: line.quantity,
          img: line?.tradeDrug?.smallIconURL,
          authStatus: line?.authStatus,
          rejectionReason: line?.rejectionReason,
          variantName: line?.variantName,
        }
    )
    .filter(Boolean),
  referenceNumber: order?.prescription?.referenceNumber,
  headerComponent: (
    <HeaderCard
      title={getTitle(t)[String(order?.status)]}
      subTitle={order?.status === OrderStatus.ErxHubRejected ? order?.rejectionReason?.reason : ""}
      color={getColorFromStatus(order.status)}
      isOrderLoading={order?.status === OrderStatus.WaitingErxHubApproval || order?.status === OrderStatus.WaitingErxHubClaimApproval}
    />
  ),

  timeOutComponent: (
    <OrderTimeout
      time={order?.status === OrderStatus.ErxHubRejected && time?.orderErxHubApprovalResubmitTimeout!}
      title={order?.status === OrderStatus.ErxHubRejected && t("Time to resubmit eRx")}
      color={getColorFromStatus(order.status)}
    />
  ),
});

export const mapOrderToDeliveredColumn = (order: OrderDataFragment, time?, t?: TFunction) => ({
  ...getSharedOrderData(order),
  medications: order?.lines?.map(line => ({
    ...getMedication(line),
    display: line?.tradeDrug?.display,
    quantity: line?.quantity,
    img: line?.tradeDrug?.smallIconURL,
    authStatus: line?.authStatus,
  })),
  referenceNumber: order?.prescription?.referenceNumber,
  deliveryStatus: order?.deliveryStatus! as OrderDeliveryStatusEnum,
  headerComponent: (
    <HeaderCard
      title={getTitle(t)[String(order?.status)]}
      color={OrderColorEnum.info}
      deliveryStatus={order?.deliveryStatus || null}
      isOrderLoading={order?.status === OrderStatus.WaitingErxHubApproval || order?.status === OrderStatus.WaitingErxHubClaimApproval}
    />
  ),
});
