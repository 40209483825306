import { i18next } from "@health/i18n";
import { VendorBranchTypeEnum } from "@health/queries";
import { FieldsTypes, FilterField } from "@health/ui";
import { useVendorBranchTypesEnumOptions } from "./useVendorBranchTypesEnum";

export const useBranchFields = (t: i18next.TFunction, activeFilters: Record<string, any>): FilterField[] => {
  const { getEnumForTranslatedLabel, optionsTypes } = useVendorBranchTypesEnumOptions();
  return [
    {
      type: FieldsTypes.input,
      name: "name",
      fieldProps: {
        placeholder: t("Name"),
        label: t("Name"),
        name: "name",
        defaultValue: "",
      },
    },
    {
      type: FieldsTypes.input,
      name: "description",
      fieldProps: {
        placeholder: t("Description"),
        label: t("Description"),
        name: "description",
        defaultValue: "",
      },
    },
    {
      type: FieldsTypes.input,
      name: "contactNumber",
      fieldProps: {
        placeholder: t("Contact Number"),
        label: t("Contact Number"),
        name: "contactNumber",
        defaultValue: "",
      },
    },
    {
      type: FieldsTypes.autocomplete,
      name: "type",
      fieldProps: {
        placeholder: t("Type"),
        label: t("Type"),
        name: "type",
        options: optionsTypes,
        getValueFromSelectedOptionLabel: getEnumForTranslatedLabel,
        getOptionLabel: option => {
          const [name] = Object.entries(VendorBranchTypeEnum).find(([n, v]) => v.includes(option) || t(n, { context: "en" }) === option)!;
          return t(name, { context: "en" });
        },
        defaultValue: activeFilters?.type,
      },
    },
    {
      type: FieldsTypes.checkbox,
      name: "isActive",
      fieldProps: {
        defaultChecked: activeFilters?.isActive,
        label: t("Is Active"),
        name: "isActive",
      },
    },
    {
      type: FieldsTypes.checkbox,
      name: "acceptsDelivery",
      fieldProps: {
        defaultChecked: activeFilters?.acceptsDelivery,
        label: t("Accepts Delivery"),
        name: "acceptsDelivery",
      },
    },
    {
      type: FieldsTypes.checkbox,
      name: "acceptsPickup",
      fieldProps: {
        defaultChecked: activeFilters?.acceptsPickup,
        label: t("Accepts Pickup"),
        name: "acceptsPickup",
      },
    },
  ];
};
