/* eslint-disable max-lines */
import { useTranslation } from "@health/i18n";
import { nextSortDirection, useDataGridState, useProductVariantsQuery } from "@health/queries";
import { useToasts } from "@health/ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "shared/constants";
import { useTablePreferences } from "shared/hooks/tablePreferences";
import { ProductColumns } from "../components/Columns";
import useFilterFields from "./ProductFilter";
const useProductsManagementState = () => {
  const { t } = useTranslation("vendor");

  const [isOpen, setIsOpen] = useState(false);
  const { addToast } = useToasts();
  const [activeFilters, setActiveFilters] = useState<Record<string, any>>({});
  const [dataError, setDataError] = useState<any>(undefined);
  const [columns, setColumns] = useState<any>(ProductColumns(t));
  const [hasStocks, setHasStocks] = useState<any>(false);
  const inputs: any = {
    first: 10,
    after: null,
    before: null,
    last: null,
    sortBy: {
      field: "NAME",
      direction: "DESC",
    },
  };
  const { data, isLoading, variables, pageSize, doChangePageSize, doGoToNext, doGoToPrevious, doSort, doFilter } = useDataGridState({
    useCustomQuery: useProductVariantsQuery,
    input: inputs,
    onError: errors => {
      setDataError(errors);
      addToast(t("Failed to get data"), {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });

  const fields = useFilterFields(activeFilters);
  const handleGotoNext = () => {
    doGoToNext();
  };
  const handleGoToPrevious = () => {
    doGoToPrevious();
  };
  const handleSortData = (data: any) => {
    const sortDirection: any = nextSortDirection(variables.sortBy?.direction);
    setColumns((columns: any) =>
      columns.map((column: any) => {
        if (column.key === data.key) {
          column.sortDirection = sortDirection ? sortDirection : null;
        } else {
          column.sortDirection = undefined;
        }
        return column;
      })
    );
    doSort(data.sortColumnEnum);
  };
  const handlePageSizeChange = (size: number): void => {
    doChangePageSize(size);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleModalToggle = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();

  const handleAddNew = () => {
    navigate("/" + ROUTE_PATHS?.productsManagementVendorRoutes?.products?.new?.fullPath);
  };
  const handleEditRow = product => {
    navigate("/" + ROUTE_PATHS.productsManagementVendorRoutes.products?.edit?.fullPath.replace(":id", product?.id));
  };
  const products = (data?.productVariants?.edges.map(item => item.node) as any[]) ?? [];
  const pageInfo = data?.productVariants?.pageInfo;
  const totalCount = data?.productVariants?.totalCount;

  const handleApplyFilters = (data: any) => {
    setActiveFilters(data);
    const categories = data?.categories?.map(cat => cat?.id);
    const branches = data?.branches?.map(branch => branch?.id);
    const productTypes = data?.productTypes?.map(type => type?.id);
    const productBrands = data?.productBrands?.map(brand => brand?.id);
    const vendors = data?.vendors?.map(vendor => vendor?.id);
    doFilter({
      ...data,
      productTypes: productTypes?.length ? productTypes : [],
      categories: categories?.length ? categories : [],
      branches: branches?.length ? branches : [],
      product: data?.product?.id || undefined,
      productBrands: productBrands?.length ? productBrands : [],
      vendors: vendors?.length ? vendors : [],
      priceFrom: data?.priceFrom ? Number(data?.priceFrom) : null,
      priceTo: data?.priceTo ? Number(data?.priceTo) : null,
    });
  };

  const handleDismissFilters = () => {
    setActiveFilters({});
  };
  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const handleToggleFilters = () => {
    setIsFilterOpened(state => !state);
  };
  const { isColumnsLoading, columns: columnsData, saveTablePreferences } = useTablePreferences(`products-management`, columns);
  const handleColumnsChanged = (data: any) => {
    saveTablePreferences({
      variables: {
        input: {
          view: `products-management`,
          data: JSON.stringify({
            version: "v1",
            columns: data,
          }),
        },
      },
    });
  };

  const handleToggleStocks = () => {
    setHasStocks(prevHasStocks => {
      const newHasStocks = !prevHasStocks;
      doFilter({
        hasAvailableStocks: newHasStocks,
      });
      return newHasStocks;
    });
  };

  return {
    products,
    columns: columnsData || columns,
    isColumnsLoading,
    handleColumnsChanged,
    isLoading,
    pageSize,
    pageInfo,
    dataError,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    handleSortData,
    isFilterOpened,
    handleToggleFilters,
    totalCount,
    handleClose,
    isOpen,
    handleModalToggle,
    handleAddNew,
    handleEditRow,
    handleApplyFilters,
    activeFilters,
    handleDismissFilters,
    fields,
    handleToggleStocks,
    hasStocks,
    t,
  };
};

export default useProductsManagementState;
