/* eslint-disable max-lines */
import { UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import {
  AppRoleTypes,
  AppTypes,
  formatGraphQLError,
  formatMessageErrors,
  nextSortDirection,
  OrderDirection,
  useDataGridState,
  User,
  UserSortField,
  UsersQueryVariables,
  UserStatus,
  useUsersQuery,
  useVendorUserDeleteMutation,
  VendorUserDeleteMutation,
} from "@health/queries";
import { useToasts } from "@health/ui";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "shared/constants";
import { UsersColumns } from "../components/UserList.columns";
import { fieldsFilter } from "../components/UserListFilters";
import { getValue } from "./constants";

export const useUsersListHook = () => {
  const { t } = useTranslation("vendor");
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [columns, setColumns] = React.useState(UsersColumns(t));
  const [activeFilters, setActiveFilters] = React.useState<Record<string, any>>({});
  const [isFilterOpened, setIsFilterOpened] = React.useState(false);
  const { user } = useContext(UserContext);
  const input: UsersQueryVariables = {
    first: 10,
    after: null,
    before: null,
    last: null,
    sortBy: {
      field: UserSortField.DateJoined,
      direction: OrderDirection.Desc,
    },
    filter: null,
  };
  const { data, isLoading, variables, setVariables, pageSize, doChangePageSize, doGoToNext, doGoToPrevious, doSort, doFilter } =
    useDataGridState({ useCustomQuery: useUsersQuery, input: input });

  const [deleteUser] = useVendorUserDeleteMutation({
    onCompleted: (data: VendorUserDeleteMutation) => {
      const errors = data?.vendorUserDelete?.accountErrors;
      if (errors?.length === 0) {
        addToast(t("Delete User successfully!"), {
          appearance: "success",
          autoDismiss: false,
        });
        setVariables(state => ({
          ...state,
          first: pageSize,
          after: null,
          last: null,
          before: null,
        }));
      } else {
        const errorMutation = formatMessageErrors(errors);
        addToast(t(errorMutation), {
          appearance: "error",
          autoDismiss: false,
        });
      }
    },
    onError: ({ graphQLErrors }) => {
      const errors = formatGraphQLError(graphQLErrors);
      addToast(t(errors), {
        appearance: "error",
        autoDismiss: false,
      });
    },
  });

  const handleDeleteRow = row => {
    deleteUser({
      variables: { id: row?.id },

      update(cache) {
        const normalizedId = cache.identify({ id: row?.id, __typename: "DELETE" });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };
  const handleGotoNext = () => doGoToNext();

  const handleGoToPrevious = () => doGoToPrevious();

  const handlePageSizeChange = (size: number): void => doChangePageSize(size);

  const handleSortData = data => {
    const sortDirection: any = nextSortDirection(variables.sortBy?.direction);
    setColumns(columns =>
      columns.map(column => {
        if (column.key === data.key) {
          column.sortDirection = sortDirection ? sortDirection : null;
        } else {
          column.sortDirection = undefined;
        }
        return column;
      })
    );
    doSort(data.sortColumnEnum);
  };
  const users = data?.["users"]?.edges?.map(({ node }) => node) as User[];
  const pageInfo = data?.["users"]?.pageInfo;
  const totalCount = data?.["users"]?.totalCount;
  const pagesCount = Math.ceil((totalCount || pageSize) / pageSize);
  const handleAddNew = () => navigate("/" + ROUTE_PATHS?.usersManagement.users.new?.fullPath);

  const handleEdit = ({ id }: User) => navigate("/" + ROUTE_PATHS?.usersManagement.users.edit?.fullPath.replace(":id", id));

  const fields = fieldsFilter(t, activeFilters).filter(field => field);
  const handleApplyFilters = (data: Record<string, unknown>) => {
    const appRole = getValue(AppRoleTypes);
    const status = getValue(UserStatus);
    const appType = getValue(AppTypes);
    // forced to this translation , due to a bug in rendereing autocomplete by static filter dialog , since it get the value from textfield ie what uses sees , and since user see translated text we need to translate back!
    const getStatus = status.find(item => t(item.value) === data.status);
    const getDataRole = appRole.find(item => t(item.value) === data.appRole);
    const getDataType = appType.find(item => t(item.value) === data.appType);
    setActiveFilters(data as any);
    doFilter({
      search: data.search,
      status: getStatus?.value || undefined,
      appType: getDataType?.value || undefined,
      appRole: getDataRole?.value || undefined,
    });
  };
  const handleToggleFilters = () => setIsFilterOpened(state => !state);
  const isAdminVendor = user?.appType === AppTypes.Vendor && user?.appRole === AppRoleTypes.Admin;
  const isUserVendor = user?.appType === AppTypes.Vendor && user?.appRole === AppRoleTypes.User;
  const doesLoggedInUserHaveMorePermissionToDoDeleteActions = row => {
    const isUserSuperVendor = row?.appRole === AppRoleTypes.Admin && row.appType === AppTypes.Vendor;
    const isTryingToDeleteHimself = user.id === row.id;
    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (((isAdminVendor || isUserVendor) && isUserSuperVendor) || isTryingToDeleteHimself) {
      return false;
    }
    return true;
  };
  const doesLoggedInUserHaveMorePermissionToDoUpdateActions = row => {
    const isTryingToUpdateHimself = user.id === row.id;
    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (isUserVendor || isTryingToUpdateHimself) {
      return false;
    }
    return true;
  };
  return {
    data,
    columns,
    isLoading,
    users,
    pageInfo,
    totalCount,
    pageSize,
    pagesCount,
    handleGoToPrevious,
    handleGotoNext,
    handlePageSizeChange,
    handleSortData,
    handleAddNew,
    handleEdit,
    handleDeleteRow,
    isFilterOpened,
    handleApplyFilters,
    handleToggleFilters,
    fields,
    activeFilters,
    doesLoggedInUserHaveMorePermissionToDoDeleteActions,
    doesLoggedInUserHaveMorePermissionToDoUpdateActions,
  };
};
