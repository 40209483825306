import { darkBlue } from "@health/ui";

export const useDrugIdentification = () => {
  const getGenericDrugProperties = genericDrugProperties =>
    genericDrugProperties ? Object.keys(genericDrugProperties).find(i => genericDrugProperties[i] === true) : "";

  const handleMedication = medication => {
    switch (medication) {
      case "isLASA":
        return { color: darkBlue, weight: "bold" };
      case "isHighAlert":
        return { color: "#EA3428", weight: "inherit" };
      case "isHazardous":
        return { color: "#D59236", weight: "inherit" };
      default:
        return { color: darkBlue, weight: "inherit" };
    }
  };

  return {
    getGenericDrugProperties,
    handleMedication,
  };
};
