import { formGirdSpacing } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Money, ProductVariant } from "@health/queries";
import { Button, CustomIcon, FormActions, FormCard, Grid, PageWrapper, TruncateTypography, white } from "@health/ui";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import PriceInformation from "./PriceInformation/PriceInformation";
import { useProductFormState } from "./ProductForm.hook";
import Warehouse from "./Warehouse/Warehouse.component";

interface ProductFormType {}

export const ProductForm: FC<ProductFormType> = () => {
  const { t } = useTranslation("vendor");

  const {
    onSubmit,
    vendorProductVariantId,
    productVariant,
    isLoading,
    handleCancel,
    methods,
    handleSubmit,
    handleAddWarehouseRow,
    fields,
    handleRemoveRow,
  } = useProductFormState();

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          start={<TruncateTypography marginLeft='45px' text={productVariant?.name!} />}
          actions={
            <FormActions
              hasCancel
              hasSave={Boolean(!vendorProductVariantId)}
              hasEdit={Boolean(vendorProductVariantId)}
              onSave={handleSubmit(onSubmit)}
              onEditItem={handleSubmit(onSubmit)}
              onNavigation={handleCancel}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12} spacing={formGirdSpacing}>
              <FormCard title={t("Price")} doYouHaveData={true} loading={isLoading}>
                <PriceInformation price={productVariant?.vendorProductVariant?.price as Money} />
              </FormCard>
            </Grid>
            <Grid item xs={12} spacing={formGirdSpacing}>
              <FormCard
                title={t("Stock per branch")}
                loading={isLoading}
                doYouHaveData={true}
                action={
                  <Button
                    onClick={handleAddWarehouseRow}
                    startIcon={<CustomIcon icon={"add"} width={30} height={26} viewBox='1 1 24 24' color={white} />}
                  >
                    {t("Add")}
                  </Button>
                }
              >
                <Warehouse defaultData={productVariant as ProductVariant} fields={fields} onRemoveRow={handleRemoveRow} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
