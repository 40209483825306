import { i18n, useTranslation } from "@health/i18n";
import { Order, OrderStatus } from "@health/queries";
import {
  Box,
  Card,
  Divider,
  getTranslatedNameField,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  Typography,
} from "@health/ui";
import moment from "moment";
import DeliveryWorkflow from "pages/Prescriptions/components/delivery-workflow/delivery-workflow.component";
import { getTypeEvent, isDeliveryStatus } from "pages/Prescriptions/components/utils";
import React, { FC } from "react";
import CardContent from "./AuditHistory/CardContent.component";
import { useLifeCycleStyles } from "./AuditHistory/LifeCycle.styles";

type OrdersEventsProps = {
  order: Order;
  deliveryWorkflowId: string | null | undefined;
};

export const OrdersEvents: FC<OrdersEventsProps> = ({ order, deliveryWorkflowId }) => {
  const { t } = useTranslation("vendor");
  const { classes } = useLifeCycleStyles();

  const branchName = getTranslatedNameField(order.branch);

  return (
    <Timeline sx={{ margin: 0, padding: "0px 16px" }}>
      {order.events?.map(event => {
        return (
          <TimelineItem key={event?.id}>
            <TimelineOppositeContent className={classes.content}>
              <Typography className={classes.dateBold}> {moment(event?.date).locale(i18n.language).format("D MMM YYYY")} </Typography>
            </TimelineOppositeContent>

            <TimelineSeparator sx={{ position: "relative" }}>
              <TimelineDot variant='outlined' className={classes.dot} />
              <TimelineConnector className={classes.separator} />
            </TimelineSeparator>

            <TimelineContent className={classes.position}>
              <Card className={classes.card}>
                <div className={classes.flexColumn}>
                  <div className={classes.flex}>
                    <Typography className={classes.textBold}> {getTypeEvent(event?.type!)} </Typography>
                    <Typography className={classes.date}> {moment(event?.date).format("h:mm:ss a")} </Typography>
                  </div>

                  {isDeliveryStatus(event?.type!) && deliveryWorkflowId && (
                    <div className={classes.deliveryWorkflowWrapper}>
                      <DeliveryWorkflow deliveryWorkflowId={deliveryWorkflowId} />
                    </div>
                  )}
                </div>

                <Divider className={classes.line} />

                <div className={classes.flex}>
                  <div>
                    <CardContent title={t("Order ID")} subTitle={order.number} />
                  </div>

                  <div>
                    <CardContent title={t("Pharmacy")} subTitle={branchName} />
                  </div>
                </div>

                {order.status === OrderStatus.Failed && (
                  <Box sx={{ mt: 1 }}>
                    <CardContent title={t("Fail Reason")} subTitle={order.failReason} />
                  </Box>
                )}

                {order.helpDiskTicketId && (
                  <Box>
                    <CardContent title={t("Help Disk Ticket Id")} subTitle={order.helpDiskTicketId} />
                  </Box>
                )}
              </Card>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};
