import { ApolloProvider } from "@apollo/client";
import { getEnvVariable, NotificationsServiceProvider, UserContextProvider } from "@health/common";
import { i18n, I18nextProvider } from "@health/i18n";
import { SiteSettingsProvider } from "@health/smart-ui";
import { AppThemeProvider, SmallAlert, ToastProvider } from "@health/ui";
import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { client } from "shared/configs/apollo";
import { RootRouter } from "./shared/components/Root/RootRoutes";

const eventSourceUrl = getEnvVariable("SUBSCRIPTION_URL");
const AppRootProviders: FC = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <SiteSettingsProvider>
        <I18nextProvider i18n={i18n}>
          <UserContextProvider apolloClient={client}>
            <NotificationsServiceProvider eventSourceUrl={eventSourceUrl}>
              <BrowserRouter>
                <AppThemeProvider>
                  <ToastProvider
                    components={{ Toast: SmallAlert }}
                    placement={i18n.dir(i18n.language) === "rtl" ? "top-left" : "top-right"}
                  >
                    {children}
                  </ToastProvider>
                </AppThemeProvider>
              </BrowserRouter>
            </NotificationsServiceProvider>
          </UserContextProvider>
        </I18nextProvider>
      </SiteSettingsProvider>
    </ApolloProvider>
  );
};

function App(): React.ReactElement {
  window["disableAportSSEOnTabChange"] = true; // to not cancel on out of focus

  return (
    <AppRootProviders>
      <RootRouter />
    </AppRootProviders>
  );
}

export default App;
