import { makeStyles } from "@health/ui";

export const useCommunicationsStyles = makeStyles()({
  root: {
    height: "calc(84vh - 148px)",
    width: "100%",
    overflowY: "auto",
  },
  loading: { display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", height: "50vh" },
});
