import { useReactiveVar } from "@apollo/client";
import {
  EVENT_TOPIC,
  OrderDeletedNotificationEvent,
  OrderStatusChangedNotificationEvent,
  toConstantCase,
  TOPIC_LISTENER,
  useSubscribeToNotificationTopic,
} from "@health/common";
import {
  handleDeleteEntityFromCache,
  OrderDeliveryStatusEnum,
  OrderStatus,
  useOrdersTabLazyQuery,
  useOrderTimeoutsQuery,
} from "@health/queries";
import { useMediaQuery } from "@health/ui";
import { FC, useCallback, useEffect } from "react";
import { pharmacyUserPreferencesVar } from "shared/components";
import { ORDERS_STATUS, statuses } from "../statuses";
import { shouldRefetchDelivered, shouldRefetchDispensing, shouldRefetchNew, shouldRefetchPending } from "../utils";
import { ActionsEnum } from "./actions";
import { OrderPageContext } from "./context";
import { handleGetOrderDataResponse } from "./helpers";
import { useOrderPageReducer } from "./reducer";
import { KeyOfStatuses } from "./types";

export const OrderPageProvider: FC = ({ children }) => {
  const [state, dispatch] = useOrderPageReducer();
  const userPreferences = useReactiveVar(pharmacyUserPreferencesVar);
  const isMatch = useMediaQuery("(max-width:1200px)");
  const [getOrderData, { loading, client }] = useOrdersTabLazyQuery({});
  const { data } = useOrderTimeoutsQuery();
  const time = data?.siteSettings;

  const onOrderDeletedListener: TOPIC_LISTENER<OrderDeletedNotificationEvent>[EVENT_TOPIC.ORDER_DELETED] = ({ data }) => {
    fetchDataByStatus(
      ORDERS_STATUS.NEW,
      state.filter,
      userPreferences?.branch?.map(b => b.id)
    );
    handleDeleteEntityFromCache("Order", data.id, client);
  };

  const onOrderDeleted: TOPIC_LISTENER<OrderDeletedNotificationEvent>[EVENT_TOPIC.ORDER_DELETED] = useCallback(onOrderDeletedListener, [
    state.filter,
    userPreferences?.branch?.map(b => b.id),
  ]);
  useSubscribeToNotificationTopic(EVENT_TOPIC.ORDER_DELETED, onOrderDeleted);

  const onOrderStatusChangedListener: TOPIC_LISTENER<OrderStatusChangedNotificationEvent>[EVENT_TOPIC.ORDER_STATUS_UPDATE] = ({ data }) => {
    const orderStatus = toConstantCase(data?.status) as OrderStatus;
    const deliveryStatus = toConstantCase(data?.deliveryStatus) as OrderDeliveryStatusEnum;
    shouldRefetchNew(orderStatus) &&
      fetchDataByStatus(
        ORDERS_STATUS.NEW,
        state.filter,
        userPreferences?.branch?.map(b => b.id)
      );
    shouldRefetchPending(orderStatus) &&
      fetchDataByStatus(
        ORDERS_STATUS.PENDING,
        state.filter,
        userPreferences?.branch?.map(b => b.id)
      );
    shouldRefetchDispensing(orderStatus) &&
      fetchDataByStatus(
        ORDERS_STATUS.DISPENSING,
        state.filter,
        userPreferences?.branch?.map(b => b.id)
      );
    shouldRefetchDelivered(deliveryStatus, orderStatus) &&
      fetchDataByStatus(
        ORDERS_STATUS.DELIVERING,
        state.filter,
        userPreferences?.branch?.map(b => b.id)
      );
  };

  const onOrderStatusChanged: TOPIC_LISTENER<OrderStatusChangedNotificationEvent>[EVENT_TOPIC.ORDER_STATUS_UPDATE] = useCallback(
    onOrderStatusChangedListener,
    [state.filter, userPreferences?.branch?.map(b => b.id)]
  );
  useSubscribeToNotificationTopic(EVENT_TOPIC.ORDER_STATUS_UPDATE, onOrderStatusChanged);

  const handleChangeView = () => {
    dispatch({ type: ActionsEnum.ChangeView });
  };

  useEffect(() => {
    isMatch && state.selectedView === "Queue" && handleChangeView();
  }, [isMatch]);
  const handleToggleDeliveringColumnVisibility = () => {
    dispatch({ type: ActionsEnum.ToggleDeliveringColumnVisible });
  };
  const fetchDataByStatus = (status: string, filters?, branches?) => {
    const selectedStatuses = statuses?.[status];
    getOrderData({
      variables: {
        first: 3,
        filter: {
          status: selectedStatuses,
          numbers: [].concat(filters?.numbers || []),
          branches: [].concat(branches || []),
          source: filters?.source?.id || undefined,
          deliveryTimeSlot: filters?.deliveryTimeSlot?.id,
          deliveryDate: filters?.deliveryDate || undefined,
          isParentFailed: false,
        },
      },
    }).then(handleGetOrderDataResponse(dispatch, status, filters, branches, loading));
  };

  const handleChangeSelectedTab = (status: KeyOfStatuses) => {
    dispatch({ type: ActionsEnum.ChangeSelectedTab, value: status });
    fetchDataByStatus(
      status,
      state.filter,
      userPreferences?.branch?.map(b => b.id)
    ); // Todo Remove it
  };
  const fetchDataBySelectedTab = () => {
    fetchDataByStatus(
      state.selectedTab,
      state.filter,
      userPreferences?.branch?.map(b => b.id)
    );
  };

  const handleFilter = value => {
    dispatch({ type: ActionsEnum.FilterData, value: value });
  };

  return (
    <OrderPageContext.Provider
      value={{
        ...state,
        time,
        onFilter: handleFilter,
        onChangeView: handleChangeView,
        onToggleDeliveringColumnVisibility: handleToggleDeliveringColumnVisibility,
        onChangeSelectedTab: handleChangeSelectedTab,
        fetchDataBySelectedTab,
        fetchDataByStatus,
      }}
    >
      {children}
    </OrderPageContext.Provider>
  );
};
