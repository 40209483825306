import { combineErrors, getRequiredValidation, UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import { User } from "@health/queries";
import { doesBranchHasAcceptDeliveryFlag, doesBranchHasAcceptPickupFlag } from "@health/smart-ui";
import { CustomDatePicker, Grid, ToggleButtonController } from "@health/ui";
import React, { FC, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CustomTextField } from "shared/components";
import { formGirdBreakPoints, formGirdSpacing } from "shared/constants";
import { GeneralInfoProps } from "../../BranchesForm.types";
import { BranchesType } from "./BranchesType.component";
import { generalInformationFields } from "./generalInformationUtils";

export const GeneralInformation: FC<GeneralInfoProps> = props => {
  const { t } = useTranslation("vendor");
  const { defaultValue, errorsInput } = props;
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const { user } = useContext(UserContext) as { user: User };
  const formErrors = combineErrors(errors, errorsInput);

  const branchType = watch("type")?.value || defaultValue?.type || user?.vendor?.type;

  return (
    <Grid container spacing={formGirdSpacing}>
      {generalInformationFields(t, defaultValue)?.map(item => (
        <Grid item {...formGirdBreakPoints} key={item.name}>
          <CustomTextField
            name={item.name}
            label={item.label}
            defaultValue={item.defaultValue}
            pattern={item?.pattern}
            errorsInput={errorsInput}
            minLength={item?.minLength}
            maxLength={item?.maxLength}
          />
        </Grid>
      ))}

      <Grid item {...formGirdBreakPoints}>
        <CustomDatePicker
          controllerProps={{
            control: control,
            rules: { required: getRequiredValidation(t, true) },
            defaultValue: defaultValue?.healthLicenseStartDate,
          }}
          name='healthLicenseStartDate'
          label={t("Health License Start Date")}
          placeholder={t("Health License Start Date")}
          error={Boolean(formErrors?.healthLicenseStartDate?.message)}
          helperText={t(formErrors?.healthLicenseStartDate?.message)}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <CustomDatePicker
          controllerProps={{
            control: control,
            rules: { required: getRequiredValidation(t, true) },
            defaultValue: defaultValue?.healthLicenseEndDate,
          }}
          name='healthLicenseEndDate'
          label={t("Health License End Date")}
          placeholder={t("Health License End Date")}
          error={Boolean(formErrors?.healthLicenseEndDate?.message)}
          helperText={t(formErrors?.healthLicenseEndDate?.message)}
        />
      </Grid>
      <BranchesType providerType={user?.vendor?.type} defaultValue={defaultValue} />

      {doesBranchHasAcceptDeliveryFlag(branchType) && (
        <Grid item {...formGirdBreakPoints}>
          <ToggleButtonController
            control={control}
            name='acceptsDelivery'
            label={t("Accepts Delivery")}
            defaultValue={defaultValue?.acceptsDelivery ?? false}
          />
        </Grid>
      )}
      {doesBranchHasAcceptPickupFlag(branchType) && (
        <Grid item {...formGirdBreakPoints}>
          <ToggleButtonController
            control={control}
            name='acceptsPickup'
            label={t("Accepts Pickup")}
            defaultValue={defaultValue?.acceptsPickup ?? false}
          />
        </Grid>
      )}
    </Grid>
  );
};
