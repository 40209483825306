/* eslint-disable max-lines */
import { useTranslation } from "@health/i18n";
import { useDeliveryTimeSlotsQuery } from "@health/queries";
import { FieldsTypes, FilterField, getTranslatedNameField } from "@health/ui";
import { formatDeliveryTimeSlot } from "pages/Orders/utils";
import { deliveryStatus, statuses } from "./components/utils";

export const usePrescriptionsFilter = (activeFilters?: any, branches?, pharmacyBranch?) => {
  const { t, i18n } = useTranslation("vendor");

  const { data: deliveryTimeSlotsData, loading: isLoadingTimeSlots } = useDeliveryTimeSlotsQuery({
    variables: {
      first: 100,
      filter: {
        isActive: true,
        deliveryType: null,
      },
    },
  });
  const timeSlotsData = deliveryTimeSlotsData?.deliveryTimeSlots?.edges?.map(item => item?.node);
  const deliveryTypeOptions = [
    { label: t("PICKUP"), value: false },
    { label: t("DELIVERY"), value: true },
  ];
  const filters: FilterField[] = [
    {
      id: "1",
      grid: 6,
      name: "ordersNumbers",
      type: FieldsTypes.input,
      fieldProps: {
        placeholder: t("Order Number"),
        label: t("Order Number"),
        name: "ordersNumbers",
        defaultValue: activeFilters?.ordersNumbers,
      },
    },
    {
      id: "2",
      type: FieldsTypes.input,
      name: "referenceNumber",
      fieldProps: {
        defaultValue: activeFilters?.referenceNumber_Icontains,
        placeholder: t("eRx Number"),
        label: t("eRx Number"),
        name: "referenceNumber",
      },
    },
    {
      id: "3",
      grid: 6,
      name: "status",
      type: FieldsTypes.autocomplete,
      fieldProps: {
        placeholder: t("Status"),
        label: t("Status"),
        name: "status",
        defaultValue: statuses?.find(item => item?.display === activeFilters?.status),
        getOptionLabel: o => o.display,
        isOptionEqualToValue: (option, value) => option.id === value.id,
        options: statuses,
      },
    },
    {
      id: "4",
      grid: 6,
      name: "deliveryStatus",
      type: FieldsTypes.autocomplete,
      fieldProps: {
        placeholder: t("Delivery Status"),
        label: t("Delivery Status"),
        name: "deliveryStatus",
        defaultValue: deliveryStatus?.find(item => item?.display === activeFilters?.deliveryStatus),
        getOptionLabel: o => o.display,
        isOptionEqualToValue: (option, value) => option.id === value.id,
        options: deliveryStatus,
      },
    },
    {
      id: "5",
      grid: 6,
      name: "created",
      type: FieldsTypes.date,
      fieldProps: {
        placeholder: t("Created Date From"),
        label: t("Created Date From"),
        name: "created",
        defaultValue: activeFilters?.created,
      },
    },
    {
      id: "6",
      grid: 6,
      name: "createdTo",
      type: FieldsTypes.date,
      fieldProps: {
        placeholder: t("Created Date To"),
        label: t("Created Date To"),
        name: "createdTo",
        defaultValue: activeFilters?.createdTo,
      },
    },
    {
      id: "7",
      grid: 6,
      name: pharmacyBranch?.branches ? "pharmacyBranch" : "branches",
      type: FieldsTypes.autocomplete,
      fieldProps: {
        placeholder: t("Branches"),
        label: t("Branches"),
        name: "branches",
        defaultValue: pharmacyBranch?.branches || branches?.find(item => getTranslatedNameField(item) === activeFilters?.branches),
        getOptionLabel: o => getTranslatedNameField(o) || o.name,
        options: branches,
        disabled: pharmacyBranch?.branches,
      },
    },
    {
      id: "8",
      grid: 6,
      name: "helpDiskTicketId_Icontains",
      type: FieldsTypes.input,
      fieldProps: {
        placeholder: t("Help Disk Ticket Id"),
        label: t("Help Disk Ticket Id"),
        name: "helpDiskTicketId_Icontains",
        defaultValue: activeFilters?.helpDiskTicketId_Icontains,
      },
    },
    {
      id: "deliveryTimeSlot",
      grid: 6,
      type: FieldsTypes.lazyAutocomplete,
      name: "deliveryTimeSlot",
      fieldProps: {
        placeholder: t("Delivery Time Slot"),
        getOptionLabel: o => `${t(o.deliveryType)}: ${formatDeliveryTimeSlot(o?.startTime, o?.endTime, i18n.language)} `,
        isOptionEqualToValue: (option, value) => option.id === value.id || option.id === value,
        name: "deliveryTimeSlot",
        defaultValue: activeFilters?.deliveryTimeSlot || undefined,
        hasMore: false,
        onFetchMore: () => {},
        loading: isLoadingTimeSlots,
        options: timeSlotsData || [],
      },
    },
    {
      id: "deliveryDate",
      grid: 6,
      type: FieldsTypes.date,
      name: "deliveryDate",
      fieldProps: {
        placeholder: t("Delivery Date"),
        label: t("Delivery Date"),
        name: "deliveryDate",
        defaultValue: null,
      },
    },
    {
      id: "10",
      grid: 6,
      name: "orderType",
      type: FieldsTypes.autocomplete,
      fieldProps: {
        placeholder: t("Order Type"),
        label: t("Order Type"),
        name: "orderType",
        getOptionLabel: o => o.label,
        defaultValue: deliveryTypeOptions.find(item => item.label === activeFilters?.[t("orderType")]),
        isOptionEqualToValue: (option, value) => {
          return option.value === value.value;
        },
        options: deliveryTypeOptions,
      },
    },
    {
      id: "9",
      grid: 6,
      name: "hasHelpDiskTicket",
      type: FieldsTypes.checkbox,
      fieldProps: {
        defaultChecked: activeFilters?.hasHelpDiskTicket ?? undefined,
        label: t("Has Help Disk Ticket"),
        name: "hasHelpDiskTicket",
      },
    },
  ];

  return {
    deliveryTypeOptions,
    filters,
  };
};
