import { makeVar } from "@apollo/client";
import { selectedBranchData } from "./utils";

type pharmacyUserPreferencesType = {
  branch: [
    {
      name: string;
      id: string;
    }
  ];
  city: {
    name: string;
    id: string;
  };
};
const loadedPrefData = JSON.parse(String(selectedBranchData));
export const pharmacyUserPreferencesVar = makeVar<pharmacyUserPreferencesType | undefined>(
  loadedPrefData ? { ...loadedPrefData, branch: loadedPrefData.branch ? [].concat(loadedPrefData.branch) : [] } : undefined
);
