import { useReactiveVar } from "@apollo/client";
import { darkBlue, isOpenOrderCardDialog, Typography } from "@health/ui";
import React, { FC } from "react";
import { useOrderSectionStyles } from "./OrderSection.styles";

type InformationCardProps = {
  title?: string;
  subTitle?: string | null;
  color?: string;
  hasBG?: boolean;
  hasAction?: boolean;
};

export const OrderSection: FC<InformationCardProps> = props => {
  const { title, subTitle, color, children, hasBG, hasAction } = props;
  const open = useReactiveVar(isOpenOrderCardDialog);
  const { classes } = useOrderSectionStyles({ color, hasBG, hasAction, open });

  return (
    <div className={classes.section}>
      <Typography className={classes.title}>{title}</Typography>
      {subTitle && <Typography className={classes.subTitle}>{String(subTitle)}</Typography>}
      {children}
    </div>
  );
};

OrderSection.defaultProps = {
  title: undefined,
  subTitle: undefined,
  color: darkBlue,
  hasBG: false,
  hasAction: undefined,
};
