import React, { FC } from "react";
import { UserForm } from "../components/UserForm.component";
import { useUserAddHook } from "./UserAdd.hook";

const UserFormNewPage: FC = () => {
  const { handleSubmit, errors } = useUserAddHook();

  return <UserForm onDone={handleSubmit} errors={errors} />;
};
export default UserFormNewPage;
