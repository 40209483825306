import { makeStyles } from "@health/ui";

export const useSendFieldStyles = makeStyles()({
  root: {
    "& .MuiOutlinedInput-root": {
      boxShadow: "0px 3px 15px #64646f33",
      borderRadius: "50px",
      height: "45px",
    },
    padding: 8,
    "& input": {
      paddingLeft: 5,
    },
  },
});
