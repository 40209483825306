import { User } from "@health/queries";
import { Box, CircularProgress } from "@health/ui";
import React, { FC, useMemo } from "react";
import { CommunicationList } from "../CommunicationList";
import { useCommunicationsStyles } from "./Communications.styles";

export const Communications: FC<any> = ({
  selectedClient,
  clients,
  isCustomersLoading,
  onSelectClient,
  onFetchMoreCustomers,
  hasMoreCustomers,
  branchId,
}) => {
  const { classes } = useCommunicationsStyles();
  const communications =
    (clients &&
      (clients as User[]).map(client => ({
        title: client.firstName && client.lastName ? `${client.firstName} ${client.lastName}` : client.email,
        avatar: client.avatar,
        ...client,
      }))) ||
    [];

  const handleSelectClient = client => {
    onSelectClient?.(client);
  };

  const handleFetchMoreCustomers = () => {
    onFetchMoreCustomers?.();
  };
  return (
    <Box className={classes.root} id='scrollableCustomersDiv'>
      {useMemo(
        () =>
          isCustomersLoading ? (
            <Box className={classes.loading}>
              <CircularProgress />
            </Box>
          ) : (
            <CommunicationList
              client={selectedClient}
              data={communications as any}
              onClick={handleSelectClient}
              onFetchMoreCustomers={handleFetchMoreCustomers}
              hasMoreCustomers={hasMoreCustomers}
              branchId={branchId}
            />
          ),
        [JSON.stringify(clients), isCustomersLoading, selectedClient, branchId]
      )}
    </Box>
  );
};
