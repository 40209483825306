import { ForgetPasswordPage as CustomForgetPasswordPage } from "@health/entry";
import { AppTypes } from "@health/queries";
import React from "react";
import { useNavigate } from "react-router-dom";

export const ForgetPasswordPage = () => {
  const navigate = useNavigate();

  const handleRedirect = (value): void => {
    navigate("/" + value);
  };

  return <CustomForgetPasswordPage app={AppTypes.Vendor} onRedirect={handleRedirect} />;
};
