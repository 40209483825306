import { UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import { BranchesDataQueryVariables, BranchOrderField, OrderDirection, useBranchesDataQuery, useDataGridState } from "@health/queries";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "shared/constants";
import { getBranchesColumns } from "./components/BranchesColumns.component";
import { useBranchFields } from "./components/BranchFields";
import { useVendorBranchTypesEnumOptions } from "./components/useVendorBranchTypesEnum";

export const useBranchesInfoHook = () => {
  const { t } = useTranslation("vendor");
  const [activeFilters, setActiveFilters] = useState<Record<string, any>>({});
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [columns] = useState(getBranchesColumns(t));
  const navigate = useNavigate();

  const inputBranches: BranchesDataQueryVariables = {
    first: 10,
    sortBy: {
      field: BranchOrderField.Name,
      direction: OrderDirection.Desc,
    },
  };

  const {
    data = {} as any,
    isLoading,
    doFilter,
    doSort,
    pageSize,
    doChangePageSize,
    doGoToNext,
    doGoToPrevious,
  } = useDataGridState({
    useCustomQuery: useBranchesDataQuery,
    input: inputBranches,
  });
  const handleGotoNext = () => {
    doGoToNext();
  };
  const handleGoToPrevious = () => {
    doGoToPrevious();
  };

  const handlePageSizeChange = (size: number): void => {
    doChangePageSize(size);
  };

  const branches = data?.branches?.edges?.map(branch => branch?.node);
  const pageInfo = data?.branches?.pageInfo;
  const totalCount = data?.branches?.totalCount;

  const handleSortData = data => {
    doSort(data.sortColumnEnum);
  };

  const handleAddBranch = () => {
    navigate("/" + ROUTE_PATHS.branches?.branches?.new.fullPath);
  };

  const handleEdit = row => {
    navigate("/" + ROUTE_PATHS.branches?.branches?.edit.fullPath.replace(":id", row.id));
  };
  const filteredFields = useBranchFields(t, activeFilters);
  const { getEnumForTranslatedLabel } = useVendorBranchTypesEnumOptions();
  const fields = filteredFields.filter(field => field);
  const handleApplyFilters = (data: any) => {
    setActiveFilters(data);
    debugger;
    doFilter({
      name_Icontains: data?.name || null,
      description_Icontains: data?.description || null,
      contactNumber_Icontains: data?.contactNumber || null,
      type: data?.type ? [getEnumForTranslatedLabel(data?.type)] : null,
      isActive: data?.isActive === undefined ? null : !!data?.isActive,
      acceptsDelivery: data?.acceptsDelivery === undefined ? null : !!data?.isActive,
      acceptsPickup: data?.acceptsPickup === undefined ? null : !!data?.isActive,
    });
  };
  const handleDismissFilters = () => {
    setActiveFilters({});
  };
  const handleToggleFilters = () => {
    setIsFilterOpened(state => !state);
  };

  const { user } = useContext(UserContext);
  const hasMultipleBranch = user?.vendor?.hasMultipleBranches;
  return {
    isLoading,
    columns,
    pageSize,
    totalCount,
    branches,
    pageInfo,
    handleEdit,
    handleAddBranch,
    handleSortData,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    handleDismissFilters,
    handleToggleFilters,
    isFilterOpened,
    fields,
    handleApplyFilters,
    activeFilters,
    hasMultipleBranch,
  };
};
