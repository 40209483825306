import { i18next } from "@health/i18n";
import { darkBlue, darkGrey, darkRed, makeStyles, success } from "@health/ui";

export const useMedicationsSectionStyles = makeStyles()(() => ({
  name: {
    fontSize: 14,
    fontFamily: "Airbnb Cereal App Book, Arab Kufi Regular",
  },
  authStatus: {
    color: darkRed,
  },
  authStatusApproved: {
    color: success,
  },
  reject: {
    fontSize: 13,
    fontFamily: "Airbnb Cereal App Book, Arab Kufi Regular",
    color: darkRed,
  },
  authStatusBold: {
    fontFamily: "Airbnb Cereal App Medium, Arab Kufi Medium",
    color: darkBlue,
  },
  nameBold: {
    fontFamily: "Airbnb Cereal App Medium, Arab Kufi Medium",
  },
  list: {
    fontSize: 12,
    color: darkGrey,
  },
  medicationName: {
    margin: 0,
    padding: 0,
    position: "relative",
    "& li": {
      listStyle: "none",
    },
    "& li::marker": {
      fontSize: 26,
    },
    "& li::before": {
      content: "''",
      border: "5px solid !important",
      borderRadius: 50,
      marginTop: 5,
      marginLeft: i18next.default.dir() === "ltr" ? -15 : 0,
      marginRight: i18next.default.dir() === "rtl" ? -15 : 0,
      position: "absolute",
    },
  },
}));
