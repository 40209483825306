import { useTranslation } from "@health/i18n";
import { OrderSourcesEnum, useDeliveryTimeSlotsQuery } from "@health/queries";
import { FieldsTypes, FilterField, StaticFilterDialog } from "@health/ui";
import { useOrderPageContext } from "../state";
import { formatDeliveryTimeSlot } from "../utils";

export const useFilterFields = (activeFilters?: Record<string, any>): FilterField[] => {
  const { t, i18n } = useTranslation("vendor");

  const { data: deliveryTimeSlotsData, loading: isLoadingTimeSlots } = useDeliveryTimeSlotsQuery({
    variables: {
      first: 100,
      filter: {
        isActive: true,
        deliveryType: null,
      },
    },
  });
  const timeSlotsData = deliveryTimeSlotsData?.deliveryTimeSlots?.edges?.map(item => item?.node);
  const orderSourceData = [
    {
      id: OrderSourcesEnum.Checkout,
      name: t("Marketplace"),
    },
    {
      id: OrderSourcesEnum.PrescriptionDispense,
      name: t("Prescription Dispense"),
    },
    {
      id: OrderSourcesEnum.PrescriptionRefill,
      name: t("Prescription Refill"),
    },
  ];
  return [
    {
      id: "numbers",
      grid: 6,
      type: FieldsTypes.input,
      name: "numbers",
      fieldProps: {
        placeholder: t("Order Number"),
        label: t("Order Number"),
        name: "numbers",
        defaultValue: null,
      },
    },
    {
      id: "deliveryTimeSlot",
      grid: 6,
      type: FieldsTypes.lazyAutocomplete,
      name: "deliveryTimeSlot",
      fieldProps: {
        placeholder: t("Delivery Time Slot"),
        getOptionLabel: o => `${t(o.deliveryType)}: ${formatDeliveryTimeSlot(o?.startTime, o?.endTime, i18n.language)} `,
        isOptionEqualToValue: (option, value) => option.id === value.id || option.id === value,
        name: "deliveryTimeSlot",
        defaultValue: activeFilters?.deliveryTimeSlot || undefined,
        hasMore: false,
        onFetchMore: () => {},
        loading: isLoadingTimeSlots,
        options: timeSlotsData || [],
      },
    },
    {
      id: "deliveryDate",
      grid: 6,
      type: FieldsTypes.date,
      name: "deliveryDate",
      fieldProps: {
        placeholder: t("Delivery Date"),
        label: t("Delivery Date"),
        name: "deliveryDate",
        defaultValue: null,
      },
    },
    {
      id: "source",
      grid: 6,
      type: FieldsTypes.lazyAutocomplete,
      // eslint-disable-next-line sonarjs/no-duplicate-string
      name: "source",
      fieldProps: {
        placeholder: t("Order Source"),
        // label: t("Order Source"),
        name: "source",
        getOptionLabel: o => o.name,
        isOptionEqualToValue: (option, value) => option.id === value.id || option.id === value,
        defaultValue: activeFilters?.source || undefined,
        hasMore: false,
        onFetchMore: () => {},
        options: orderSourceData || [],
      },
    },
  ];
};

export const OrdersFilter = ({ open, onHandleToggle: handleToggle }: { open: boolean; onHandleToggle: () => void }) => {
  const { onFilter: handleApplyFilters, filter } = useOrderPageContext();
  const filters = useFilterFields(filter);
  return <StaticFilterDialog isOpen={open} onToggleDialog={handleToggle} filterFields={filters} onApplyFilters={handleApplyFilters} />;
};
