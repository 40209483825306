import { useTranslation } from "@health/i18n";
import { Box, Typography, WasfatyLogo, white } from "@health/ui";
import QRCode from "qrcode.react";
import React, { useEffect } from "react";
import { useDispenseModelStyles } from "./DispenseModel.styles";

type OrderToPrintProps = {
  refForPrint: React.RefObject<HTMLDivElement>;
  number: string;
  toPrint: Date | undefined;
  awb?: string;
  setPrintReady: React.Dispatch<React.SetStateAction<Date | undefined>>;
};

const OrderQR = ({ refForPrint, toPrint, setPrintReady, number, awb }: OrderToPrintProps) => {
  const { classes } = useDispenseModelStyles();
  const { t, i18n } = useTranslation("vendor");

  useEffect(() => {
    if (toPrint) {
      setPrintReady(new Date());
    }
  }, [toPrint]);

  return (
    <Box display={window.matchMedia("print") ? "none" : "flex"}>
      <div dir={i18n.dir(i18n.language)} ref={refForPrint}>
        <Box height='100vh' bgcolor='transparent' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
          <Typography className={classes.id}>
            {t("Order ID")} - #{number}
          </Typography>
          <Box sx={{ position: "relative", margin: "0px" }}>
            <Box
              sx={{
                zIndex: 1,
                background: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <WasfatyLogo height={47} width={73} />
            </Box>
            <Box
              sx={{
                margin: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <QRCode id={String(number)} value={String(number)} bgColor={white} size={350} level={"H"} includeMargin={true} />
            </Box>
          </Box>
          {awb && (
            <Box className={classes.box}>
              <Typography dir={i18n.dir(i18n.language)} className={classes.id} gutterBottom>
                {t("AWB Number")} - {awb}
              </Typography>
            </Box>
          )}
        </Box>
      </div>
    </Box>
  );
};

export default OrderQR;

OrderQR.defaultProps = {
  awb: false,
};
