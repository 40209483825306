import { CameraIcon } from "@health/ui";
import React, { FC } from "react";
import { useImageUploadButtonStyles } from "./ImageUploadButton.styles";

export const ImageUploadButton: FC<any> = ({ onChange }) => {
  const { classes } = useImageUploadButtonStyles();

  const handleInputChange = e => {
    onChange(e);
    e.target.value = null as any;
  };

  return (
    <div className={classes.root}>
      <input
        accept='image/jpeg ,image/png ,image/svg+xml ,image/apng ,image/bmp ,image/gif'
        className={classes.input}
        id={`chat-image-upload`}
        multiple
        type='file'
        onChange={handleInputChange}
      />
      <label className={classes.label} htmlFor={`chat-image-upload`}>
        <CameraIcon />
      </label>
    </div>
  );
};
