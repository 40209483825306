import { getMinValueValidation, getRequiredValidation } from "@health/common";
import { i18n, useTranslation } from "@health/i18n";
import { AutocompleteController, CustomIcon, Grid, IconButton, TextField } from "@health/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { WarehouseRawProps } from "./Warehouse.type";

export const WarehouseRaw: FC<WarehouseRawProps> = ({
  index,
  handleRemoveRow,
  branches,
  stocks,
  hasRemove,
  onFetchMoreBranches,
  hasMoreBranches,
  handleSearchBranch,
}) => {
  const { t } = useTranslation("vendor");
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const watchedCurrentBranch = watch(`stocks.${index}.branch`);
  const filterBranches = branches?.filter(val => val?.id !== watchedCurrentBranch?.id);
  return (
    <>
      <Grid item xs={12} sm={5} md={4}>
        <AutocompleteController
          ControllerProps={{
            control: control,
            name: `stocks.${index}.branch`,
            defaultValue: stocks[index]?.branch,
          }}
          TextFieldProps={{
            placeholder: t("Branches"),
            error: Boolean(errors?.stocks ? errors?.stocks[index]?.branch?.message : null),
            helperText: errors?.stocks ? errors?.stocks[index]?.branch?.message : null,
          }}
          options={filterBranches || []}
          hasMore={hasMoreBranches}
          fetchMoreData={onFetchMoreBranches}
          onSearchInput={handleSearchBranch}
          getOptionLabel={option => (i18n?.language === "en" ? option?.name : option?.nameAr ?? option?.name)}
        />
      </Grid>
      <Grid item xs={12} sm={5} md={4}>
        <TextField
          label={t("Quantity")}
          placeholder={t("Quantity")}
          fullWidth
          type={"number"}
          error={Boolean(errors?.stocks ? errors?.stocks[index]?.quantity?.message : null)}
          helperText={errors?.stocks ? errors?.stocks[index]?.quantity?.message : null}
          {...register(`stocks.${index}.quantity`, {
            required: getRequiredValidation(t, true),
            min: getMinValueValidation(t, 0),
          })}
        />
      </Grid>
      <Grid container justifyContent='flex-start' alignContent='center' item xs={12} sm md>
        <IconButton size={"small"} onClick={() => handleRemoveRow(index)} disabled={hasRemove}>
          <CustomIcon icon={"close"} width={30} height={26} viewBox='1 1 24 24' />
        </IconButton>
      </Grid>
    </>
  );
};
