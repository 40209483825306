import { OrdersTabQuery } from "@health/queries";
import { statuses } from "../statuses";
import { ActionsEnum } from "./actions";
import { IDispatch } from "./types";

export const handleGetOrderDataResponse =
  (dispatch: IDispatch, status: string, filters, branches: string, loading: boolean) => response => {
    const orders = response?.data?.orders?.edges?.map(edges => edges?.node) || [];
    const hasNextPage = response?.data?.orders?.pageInfo?.hasNextPage;
    const totalCount = response?.data?.orders?.totalCount;
    const endCursor = response?.data?.orders?.pageInfo?.endCursor;

    dispatch({
      type: ActionsEnum.GetOrders,
      value: {
        data: orders,
        column: status,
        loading: loading,
        info: {
          fetchMore: () => onFetchMore(dispatch, response.fetchMore, status, endCursor, filters, branches, loading),
          totalCount,
          hasNextPage,
        },
      },
    });
  };

const onFetchMore = (dispatch: IDispatch, fetchMore, status: string, endCursor: string, filters, branches: string, loading) => {
  const selectedStatuses = statuses?.[status];
  fetchMore({
    variables: {
      first: 3,
      filter: {
        status: selectedStatuses,
        numbers: [].concat(filters?.number || []),
        branches: [].concat(branches || ([] as any)),
        deliveryTimeSlot: filters?.deliveryTimeSlot?.id,
        source: filters?.source?.id || undefined,
        deliveryDate: filters?.deliveryDate || undefined,
        isParentFailed: false,
      },
      after: endCursor,
    },
  }).then(response => {
    const fetchMoreData = response?.data as OrdersTabQuery;

    const newData = fetchMoreData?.orders?.edges?.map(edges => edges?.node) || [];
    const newHasNextPage = fetchMoreData?.orders?.pageInfo?.hasNextPage!;
    const newEndCursor = fetchMoreData?.orders?.pageInfo?.endCursor!;

    dispatch({
      type: ActionsEnum.ColumnFetchMore,
      value: {
        data: newData,
        info: {
          hasNextPage: newHasNextPage,
          fetchMore: () => onFetchMore(dispatch, fetchMore, status, newEndCursor, filters, branches, loading),
        },
        column: status,
      },
    });
  });
};
