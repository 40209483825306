import { useTranslation } from "@health/i18n";
import { CircularProgress, Typography } from "@health/ui";
import { FC, Fragment } from "react";

export const MedicationsMappingDialogContentWrapper: FC<{ loading: boolean; isEmpty: boolean; errors: string }> = ({
  loading,
  isEmpty,
  errors,
  children,
}) => {
  const { t } = useTranslation("vendor");
  return (
    <Fragment>
      {loading ? (
        <CircularProgress />
      ) : (
        <Fragment>
          {isEmpty ? (
            <Typography>{errors || t("There is no medications")}</Typography>
          ) : (
            <Fragment>
              {errors ? t("We can't dispense this Drug, for more information please call the customer service") : children}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
