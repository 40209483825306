import { bg, inputBorderColorDefault, makeStyles } from "@health/ui";

const border = "1px solid" + inputBorderColorDefault;

export const useStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: {
    width: 100,
    height: 350,
    overflowY: "initial",
  },
  noPhoto: {
    width: 500,
    padding: 10,
  },
  imageListItem: {
    "&.MuiImageListItem-img": {
      width: "359px",
      height: "370px",
      margin: "10px",
      objectFit: "contain",
    },
    margin: "0px 5px 10px 0px",
  },
  imageContainer: { width: "40px", height: "40px", border: border, borderRadius: 10, padding: "5px", cursor: "pointer" },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: bg,
    width: "40px",
    height: "40px",
    border: border,
    borderRadius: 2,
    padding: "5px",
    cursor: "pointer",
  },
}));
