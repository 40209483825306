import React, { FC } from "react";
import BranchForm from "../BranchForm.component";
import { useBranchEditPageHook } from "./BranchEditPage.hook";

const EditBranch: FC = () => {
  const { branch, errors, loading, handleUpdateBranch } = useBranchEditPageHook();

  return <BranchForm isEditMode loading={loading} branch={branch} onSubmit={handleUpdateBranch} errors={errors} />;
};

export default EditBranch;
