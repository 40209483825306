import { useTranslation } from "@health/i18n";
import { darkBlue, IconButton, ProviderIcon, Tooltip, white } from "@health/ui";
import React from "react";
import { PharmacyBranchDialog } from "./PharmacyBranchDialog";
import { usePharmacyBranchHook } from "./usePharmacyBranchHook";

export const PharmacyBranch = () => {
  const { t } = useTranslation("vendor");
  const { handleToggle } = usePharmacyBranchHook();

  return (
    <>
      <Tooltip title={t("Select Pharmacy Branch")}>
        <IconButton
          onClick={handleToggle}
          sx={{
            border: `1px solid ${darkBlue}`,
            "&:hover": {
              background: darkBlue,
              "& svg": {
                fill: white,
              },
            },
          }}
        >
          <ProviderIcon />
        </IconButton>
      </Tooltip>

      <PharmacyBranchDialog />
    </>
  );
};
