import { useTranslation } from "@health/i18n";
import { OrderTypesEnum } from "@health/queries";
import { getTranslatedNameField } from "@health/ui";
import React, { FC } from "react";
import { IOrderContainer } from "../actions/types";
import { MedicalAlerts } from "./eRxSections/MedicalAlerts";
import { useeRxSections } from "./eRxSections/useeRxSections";
import { OrderSection } from "./OrderSection";
import { OrdersFromCheckout } from "./OrdersFromCheckout";

export const OrdersContainer: FC<IOrderContainer> = ({ data, hasAction }) => {
  const { deliveryStatus, color, number, type, products, medicalAlerts, isMarketPlaceOrder, iseRxOrder = true } = data;
  const { t } = useTranslation("vendor");
  const { eRxSections, address } = useeRxSections(data);

  return (
    <>
      {iseRxOrder && Boolean(medicalAlerts?.length) && <MedicalAlerts medicalAlerts={medicalAlerts} />}

      <OrderSection
        color={color}
        title={
          isMarketPlaceOrder
            ? t("Market Place Order")
            : t(type === OrderTypesEnum.PickupFromPharmacy ? t("Pickup Order") : t("Delivery Order"))
        }
      />

      {iseRxOrder ? (
        <>
          {eRxSections
            .filter(item => item.hasData)
            .map((item, index) => (
              <OrderSection {...item} key={index} color={item.color} hasBG={index % 2 == 0} hasAction={hasAction} />
            ))}
        </>
      ) : (
        <OrdersFromCheckout
          branchName={getTranslatedNameField(data.branch!)}
          city={address}
          deliveryStatus={deliveryStatus!}
          color={color}
          number={number}
          products={products}
        />
      )}
    </>
  );
};
