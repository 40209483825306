import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "@health/i18n";
import { FC, memo, useEffect } from "react";
import { pharmacyUserPreferencesVar } from "shared/components";
import { OrdersBody } from "../components";
import { useOrderPageContext } from "../state";
import { mapStatusToName } from "../statuses";
import { ColumnHeader } from "./ColumnHeader";

interface IOrderColumn {
  status: string;
}

export const OrderColumn: FC<IOrderColumn> = memo(({ status }: IOrderColumn) => {
  const { t } = useTranslation();
  const { data, fetchDataByStatus, filter } = useOrderPageContext();

  const column = data?.[status];
  const { totalCount: count } = column;
  const name = t(mapStatusToName(status));

  const userPreferences = useReactiveVar(pharmacyUserPreferencesVar);

  useEffect(() => {
    fetchDataByStatus(
      status,
      filter,
      userPreferences?.branch?.map(b => b.id)
    );
  }, [status, filter, userPreferences?.branch?.map(b => b.id)?.join(",")]);

  return (
    <>
      <ColumnHeader name={name} count={count} status={status} />
      <OrdersBody status={status} />
    </>
  );
});
