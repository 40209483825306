import { UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import {
  AccountError,
  AppRoleTypes,
  formatGraphQLError,
  formatMessageErrors,
  User,
  useVendorUserByIdQuery,
  useVendorUserUpdateMutation,
} from "@health/queries";
import { useAddToast } from "@health/ui";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_PATHS } from "shared/constants";

export const useUserEditHook = () => {
  const { t } = useTranslation("vendor");
  const navigate = useNavigate();
  const params = useParams();
  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = React.useState<AccountError[]>([]);
  const { user: currentUser } = useContext(UserContext);
  useEffect(() => {
    if (currentUser?.id === params?.id) {
      failed(t("you can't update yourself"));
      navigate("/" + ROUTE_PATHS?.usersManagement?.fullPath);
    }
  }, []);
  const [updateUser] = useVendorUserUpdateMutation({
    onCompleted: data => {
      const error = data?.vendorUserUpdate?.accountErrors;
      if (error && error.length > 0) {
        const mutationError = formatMessageErrors(error);
        if (mutationError?.length > 1) {
          mutationError?.map(item => {
            return failed(t(item));
          });
        } else {
          failed(t(mutationError));
        }
        setErrors(error as AccountError[]);
      } else {
        succeeded(t("User updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedMessage = formatGraphQLError(graphQLErrors);
      failed(t(formattedMessage));
    },
  });
  const { data, loading: isLoading } = useVendorUserByIdQuery({
    variables: {
      id: params.id as string,
    },
  });

  const handleUpdate = data => {
    const request = {
      ...data,
      branches: data?.appRole === AppRoleTypes.Admin ? [] : data?.branches,
      healthLicenseStartDate: data?.healthLicenseNumber ? data?.healthLicenseStartDate : null,
      healthLicenseEndDate: data?.healthLicenseNumber ? data?.healthLicenseEndDate : null,
    };
    updateUser({
      variables: {
        id: String(params.id),
        input: request,
      },
    });
  };
  const user = data?.user as User;

  return {
    user,
    errors,
    handleUpdate,
    isLoading,
  };
};
