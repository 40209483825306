import {
  formGirdBreakPoints,
  formGirdSpacing,
  getMaxLengthValidation,
  getMinLengthValidation,
  getPatternMobileErrorMessage,
  getRequiredValidation,
  patternEmail,
  patternMobile,
} from "@health/common";
import { useTranslation } from "@health/i18n";
import { CustomToggleButton, FormControlLabel, Grid, TextField } from "@health/ui";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AppRoleComponent } from "./AppRole.component";
import { FromProps } from "./UserForm.types";

export const GeneralInformation: FC<FromProps> = props => {
  const { defaultValues, errors } = props;
  const { t } = useTranslation("vendor");
  const { register, control } = useFormContext();
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("First Name")}
          error={Boolean(errors?.firstName?.message)}
          helperText={t(errors?.firstName?.message)}
          {...register("firstName", {
            required: getRequiredValidation(t, true),
            minLength: getMinLengthValidation(t, 0),
            maxLength: getMaxLengthValidation(t, 256),
          })}
          defaultValue={defaultValues?.firstName}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Last Name")}
          error={Boolean(errors?.lastName?.message)}
          helperText={t(errors?.lastName?.message)}
          {...register("lastName", {
            required: getRequiredValidation(t, true),
            minLength: getMinLengthValidation(t, 0),
            maxLength: getMaxLengthValidation(t, 256),
          })}
          defaultValue={defaultValues?.lastName}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Email")}
          error={Boolean(errors?.email?.message)}
          helperText={t(errors?.email?.message)}
          {...register("email", {
            required: getRequiredValidation(t, !defaultValues),
            pattern: patternEmail,
          })}
          defaultValue={defaultValues?.email}
          disabled={Boolean(defaultValues?.email)}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Mobile")}
          error={Boolean(errors?.mobile?.message)}
          helperText={getPatternMobileErrorMessage(t, errors?.mobile?.message)}
          defaultValue={defaultValues?.mobile}
          {...register("mobile", {
            required: getRequiredValidation(t, !defaultValues),
            pattern: patternMobile,
          })}
          disabled={Boolean(defaultValues?.mobile)}
        />
      </Grid>

      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Password")}
          type={"password"}
          error={Boolean(errors?.password?.message)}
          helperText={t(errors?.password?.message)}
          {...register("password", {
            required: getRequiredValidation(t, !defaultValues),
          })}
        />
      </Grid>

      <AppRoleComponent defaultValues={defaultValues} errors={errors} />

      <Grid item {...formGirdBreakPoints}>
        <Controller
          name='isActive'
          defaultValue={defaultValues?.isActive}
          control={control}
          render={({ field: { onChange: handleC, value } }) => (
            <FormControlLabel
              control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
              label={t<string>("Is Active")}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
