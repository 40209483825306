/* eslint-disable max-lines */
import { useTranslation } from "@health/i18n";
import {
  AdminDropDownProductsQuery,
  AdminProductTypesQuery,
  BranchesQuery,
  CategoriesQuery,
  GetVendorProductBrandsQuery,
  useAdminDropDownProductsQuery,
  useAdminProductTypesQuery,
  useBranchesQuery,
  useCategoriesQuery,
  useGetVendorProductBrandsQuery,
  useVendorsDropDownOptionsQuery,
  VendorBranchTypeEnum,
  VendorsDropDownOptionsQuery,
} from "@health/queries";
import { FieldsTypes, FilterField } from "@health/ui";

const useFilterFields = activeFilters => {
  const { t } = useTranslation("vendor");

  const {
    data: productsData,
    loading: isLoadingProduct,
    fetchMore: fetchMoreProducts,
    refetch: refetchProducts,
  } = useAdminDropDownProductsQuery({
    variables: {
      first: 10,
    },
  });

  const products = productsData?.products?.edges?.map(item => item?.node);
  const productsPageInfo = productsData?.products?.pageInfo;
  const onfetchMoreProducts = () => {
    if (productsPageInfo?.hasNextPage) {
      fetchMoreProducts({
        variables: {
          first: 5,
          after: productsPageInfo?.endCursor,
        },
        updateQuery: (prev: AdminDropDownProductsQuery, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            products: {
              ...fetchMoreResult.products,
              edges: [...(prev?.products?.edges || []), ...(fetchMoreResult?.products?.edges || [])],
            },
          });
        },
      });
    }
  };
  const handleSearchProducts = (input: string) => {
    refetchProducts({
      filter: {
        search: input,
      },
    });
  };
  const {
    data: productTypeData,
    fetchMore: fetchMoreTypes,
    refetch: refetchProductType,
    loading: isLoadingProductType,
  } = useAdminProductTypesQuery({
    variables: {
      first: 10,
    },
  });
  const productTypes = productTypeData?.productTypes?.edges?.map(item => item?.node);
  const productTypePageInfo = productTypeData?.productTypes?.pageInfo;
  const onfetchMoreProductTypes = () => {
    if (productTypePageInfo?.hasNextPage) {
      fetchMoreTypes({
        variables: {
          first: 5,
          after: productTypePageInfo?.endCursor,
        },
        updateQuery: (prev: AdminProductTypesQuery, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            productTypes: {
              ...fetchMoreResult.productTypes,
              edges: [...(prev?.productTypes?.edges || []), ...(fetchMoreResult?.productTypes?.edges || [])],
            },
          });
        },
      });
    }
  };

  const handleSearchTypes = (inputValue: string) => {
    refetchProductType({
      filter: {
        search: inputValue,
      },
    });
  };
  const {
    data: categoriesData,
    loading: isCategoriesLoading,
    fetchMore: onFetchCategories,
    refetch: refetchCategories,
  } = useCategoriesQuery({
    variables: {
      first: 10,
    },
  });
  const categories = categoriesData?.categories?.edges?.map(item => item?.node);
  const categoriesPageInfo = categoriesData?.categories?.pageInfo;
  const fetchMoreCategories = () => {
    if (categoriesPageInfo?.hasNextPage) {
      onFetchCategories({
        variables: {
          first: 5,
          after: categoriesPageInfo?.endCursor,
        },
        updateQuery: (prev: CategoriesQuery, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            categories: {
              ...fetchMoreResult.categories,
              edges: [...(prev?.categories?.edges || []), ...(fetchMoreResult?.categories?.edges || [])],
            },
          });
        },
      });
    }
  };
  const handleSearchCategories = (inputValue: string) => {
    refetchCategories({
      filter: {
        search: inputValue,
      },
    });
  };
  const {
    data: branchesData,
    loading: isBranchesLoading,
    fetchMore: onFetchMoreBranches,
    refetch: refetchBranch,
  } = useBranchesQuery({
    variables: {
      first: 10,
      filter: {
        isActive: true,
      },
    },
  });
  const branches = branchesData?.branches?.edges?.map(item => item?.node);
  const branchesPageInfo = branchesData?.branches?.pageInfo;

  const fetchMoreBranches = () => {
    if (branchesPageInfo?.hasNextPage) {
      onFetchMoreBranches({
        variables: {
          first: 5,
          after: branchesPageInfo?.endCursor,
        },
        updateQuery: (prev: BranchesQuery, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            branches: {
              ...fetchMoreResult.branches,
              edges: [...(prev?.branches?.edges || []), ...(fetchMoreResult?.branches?.edges || [])],
            },
          });
        },
      });
    }
  };
  const handleSearchBranches = (inputValue: string) => {
    refetchBranch({
      filter: {
        name_Icontains: inputValue,
        type: [VendorBranchTypeEnum.Pharmacy],
        isActive: true,
      },
    });
  };
  const {
    data: productBrands,
    loading: isProductBrandsLoading,
    fetchMore: onfetchMoreBrands,
    refetch: refetchBrands,
  } = useGetVendorProductBrandsQuery({
    variables: {
      first: 10,
    },
  });
  const brands = productBrands?.productBrands?.edges?.map(item => item?.node);
  const brandsPageInfo = productBrands?.productBrands?.pageInfo;
  const fetchMoreBrands = () => {
    if (brandsPageInfo?.hasNextPage) {
      onfetchMoreBrands({
        variables: {
          first: 5,
          after: brandsPageInfo?.endCursor,
        },
        updateQuery: (prev: GetVendorProductBrandsQuery, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            productBrands: {
              ...fetchMoreResult.productBrands,
              edges: [...(prev?.productBrands?.edges || []), ...(fetchMoreResult?.productBrands?.edges || [])],
            },
          });
        },
      });
    }
  };
  const handleSearchBrands = (input: string) => {
    refetchBrands({
      filter: {
        search: input,
      },
    });
  };
  const {
    data: vendorsData,
    loading: isVendorsLoading,
    fetchMore: onFetchMoreVendors,
    refetch,
  } = useVendorsDropDownOptionsQuery({
    variables: {
      first: 10,
    },
  });
  const vendors = vendorsData?.vendors?.edges?.map(item => item?.node);
  const vendorsPageInfo = vendorsData?.vendors?.pageInfo;
  const fetchMoreVendors = () => {
    if (vendorsPageInfo?.hasNextPage) {
      onFetchMoreVendors({
        variables: {
          first: 5,
          after: vendorsPageInfo?.endCursor,
        },
        updateQuery: (prev: VendorsDropDownOptionsQuery, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            vendors: {
              ...fetchMoreResult.vendors,
              edges: [...(prev?.vendors?.edges || []), ...(fetchMoreResult?.vendors?.edges || [])],
            },
          });
        },
      });
    }
  };
  const handleSearchProvider = (input: string) => {
    refetch({
      filter: {
        search: input,
      },
    });
  };

  const fields: FilterField[] = [
    {
      id: "1",
      grid: 6,
      type: FieldsTypes.input,
      name: "search",
      fieldProps: {
        placeholder: t("Search"),
        label: t("Search"),
        name: "search",
        defaultValue: activeFilters?.search,
      },
    },
    {
      id: "2",
      type: FieldsTypes.input,
      name: "nameAr",
      fieldProps: {
        placeholder: t("Arabic Name"),
        label: t("Arabic Name"),
        name: "nameAr",
        defaultValue: activeFilters?.nameAr,
      },
    },
    {
      id: "3",
      type: FieldsTypes.input,
      name: "name",
      fieldProps: {
        placeholder: t("Name"),
        label: t("Name"),
        name: "name",
        defaultValue: activeFilters?.name,
      },
    },
    {
      id: "4",
      grid: 6,
      type: FieldsTypes.input,
      name: "sku",
      fieldProps: {
        placeholder: t("Sku"),
        label: t("Sku"),
        name: "sku",
        defaultValue: activeFilters?.sku,
      },
    },
    {
      id: "5",
      grid: 6,
      name: "product",
      type: FieldsTypes.lazyAutocomplete,
      fieldProps: {
        placeholder: t("Product"),
        name: "product",
        defaultValue: activeFilters?.product || undefined,
        getOptionLabel: o => {
          return o?.name ?? o;
        },
        isOptionEqualToValue: (option, value) => {
          return option.id === value.id || option?.name === value;
        },
        hasMore: productsPageInfo?.hasNextPage,
        onFetchMore: onfetchMoreProducts,
        loading: isLoadingProduct,
        options: products || [],
        handleInputChange: input => handleSearchProducts(input),
      },
    },
    {
      id: "6",
      grid: 6,
      name: "productTypes",
      type: FieldsTypes.lazyAutocomplete,
      fieldProps: {
        multiple: true,
        placeholder: t("Product Types"),
        name: "productTypes",
        defaultValue: activeFilters?.productTypes || undefined,
        getOptionLabel: o => {
          return o?.name ?? o;
        },
        isOptionEqualToValue: (option, value) => {
          return option.id === value.id || option?.name === value;
        },
        hasMore: productsPageInfo?.hasNextPage,
        onFetchMore: onfetchMoreProductTypes,
        loading: isLoadingProductType,
        options: productTypes || [],
        handleInputChange: (input: string) => handleSearchTypes(input),
      },
    },
    {
      id: "7",
      grid: 6,
      name: "categories",
      type: FieldsTypes.lazyAutocomplete,
      fieldProps: {
        multiple: true,
        placeholder: t("Categories"),
        name: "categories",
        defaultValue: activeFilters?.categories || undefined,
        getOptionLabel: o => {
          return o?.name ?? o;
        },
        isOptionEqualToValue: (option, value) => {
          return option.id === value.id || option?.name === value;
        },
        hasMore: categoriesPageInfo?.hasNextPage,
        onFetchMore: fetchMoreCategories,
        loading: isCategoriesLoading,
        options: categories || [],
        handleInputChange: (input: string) => handleSearchCategories(input),
      },
    },
    {
      id: "8",
      grid: 6,
      name: "branches",
      type: FieldsTypes.lazyAutocomplete,
      fieldProps: {
        multiple: true,
        placeholder: t("Branches"),
        loading: isBranchesLoading,
        name: "branches",
        onFetchMore: fetchMoreBranches,
        hasMore: branchesPageInfo?.hasNextPage,
        defaultValue: activeFilters?.branches || undefined,
        isOptionEqualToValue: (option, value) => option === value,
        getOptionLabel: option => option?.name ?? option,
        options: branches,

        handleInputChange: input => handleSearchBranches(input),
      },
    },
    {
      id: "9",
      grid: 6,
      name: "productBrands",
      type: FieldsTypes.lazyAutocomplete,
      fieldProps: {
        multiple: true,
        placeholder: t("Product Brands"),
        loading: isProductBrandsLoading,
        name: "productBrands",
        onFetchMore: fetchMoreBrands,
        hasMore: brandsPageInfo?.hasNextPage,
        defaultValue: activeFilters?.productBrands || undefined,
        isOptionEqualToValue: (option, value) => option === value,
        getOptionLabel: option => option?.name ?? option,
        options: brands,
        handleInputChange: input => handleSearchBrands(input),
      },
    },
    {
      id: "10",
      grid: 6,
      name: "vendors",
      type: FieldsTypes.lazyAutocomplete,
      fieldProps: {
        multiple: true,
        placeholder: t("Health Providers"),
        loading: isVendorsLoading,
        name: "vendors",
        onFetchMore: fetchMoreVendors,
        hasMore: vendorsPageInfo?.hasNextPage,
        defaultValue: activeFilters?.vendors || undefined,
        isOptionEqualToValue: (option, value) => option === value,
        getOptionLabel: option => option?.name ?? option,
        options: vendors,
        handleInputChange: input => handleSearchProvider(input),
      },
    },
    {
      id: "11",
      grid: 6,
      type: FieldsTypes.input,
      name: "priceFrom",
      fieldProps: {
        placeholder: t("From Price"),
        label: t("From Price"),
        name: "priceFrom",
        type: "number",
        defaultValue: activeFilters?.priceFrom,
      },
    },
    {
      id: "12",
      grid: 6,
      type: FieldsTypes.input,
      name: "priceTo",
      fieldProps: {
        placeholder: t("To Price"),
        label: t("To Price"),
        name: "priceTo",
        type: "number",
        defaultValue: activeFilters?.priceTo,
      },
    },
    {
      id: "13",
      grid: 6,
      name: "isPublishedProduct",
      type: FieldsTypes.checkbox,
      fieldProps: {
        label: t("is Product Published"),
        name: "isPublishedProduct",
        defaultChecked: activeFilters?.isPublishedProduct,
      },
    },
    {
      id: "14",
      grid: 6,
      name: "onSale",
      type: FieldsTypes.checkbox,
      fieldProps: {
        label: t("On Sale"),
        name: "onSale",
        defaultChecked: activeFilters?.onSale,
      },
    },
    {
      id: "15",
      grid: 6,
      name: "hasAvailableStocks",
      type: FieldsTypes.checkbox,
      fieldProps: {
        label: t("Has Available Stocks"),
        name: "hasAvailableStocks",
        defaultChecked: activeFilters?.hasAvailableStocks,
      },
    },
  ];
  return fields;
};

export default useFilterFields;
