import { OrderPageContextStateType } from "./types";

export const initialState: OrderPageContextStateType = {
  selectedView: "Queue",
  selectedTab: "new",
  time: {
    orderAcceptanceTimeout: 0,
    orderErxHubApprovalTimeout: 0,
    orderErxHubApprovalResubmitTimeout: 0,
    orderDispensingTimeout: 0,
    orderDeliveryTimeout: 0,
  },
  isDeliveringColumnVisible: true,
  loading: false,
  filter: {},
  layoutGridWidth: 3,
  data: {
    delivering: {
      // TODO
      data: [],
      loading: true,
      fetchMore: () => {},
      hasNextPage: false,
      totalCount: 0,
    },
    dispensing: {
      data: [],
      loading: true,
      fetchMore: () => {},
      hasNextPage: false,
      totalCount: 0,
    },
    new: {
      data: [],
      loading: true,
      fetchMore: () => {},
      hasNextPage: false,
      totalCount: 0,
    },
    pending: {
      loading: true,
      data: [],
      fetchMore: () => {},
      hasNextPage: false,
      totalCount: 0,
    },
  },
  fetchDataBySelectedTab: () => {},
  onChangeView: () => {},
  onFilter: () => {},
  fetchDataByStatus: status => {
    console.info(status);
  },
  onToggleDeliveringColumnVisibility: () => {},
  onChangeSelectedTab: status => {
    console.info(status);
  },
};
