import { useTranslation } from "@health/i18n";
import { AccountError, formatGraphQLError, formatMessageErrors, useVendorUserCreateMutation } from "@health/queries";
import { useAddToast } from "@health/ui";
import React from "react";
import { useNavigate } from "react-router-dom";

export const useUserAddHook = () => {
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const { t } = useTranslation("vendor");
  const [errors, setErrors] = React.useState<AccountError[]>([]);

  const [createUser] = useVendorUserCreateMutation({
    onCompleted: data => {
      const error = data?.vendorUserCreate?.accountErrors;
      if (error && error.length > 0) {
        const mutationError = formatMessageErrors(error);
        if (mutationError?.length > 1) {
          mutationError?.map(item => {
            return failed(t(item));
          });
        } else {
          failed(t(mutationError));
        }
        setErrors(error as AccountError[]);
      } else {
        succeeded(t("User created successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedMessage = formatGraphQLError(graphQLErrors);
      failed(t(formattedMessage));
    },
  });
  const handleSubmit = data => {
    createUser({
      variables: {
        input: {
          ...data,
          healthLicenseStartDate: data?.healthLicenseNumber ? data?.healthLicenseStartDate : null,
          healthLicenseEndDate: data?.healthLicenseNumber ? data?.healthLicenseEndDate : null,
        },
      },
    });
  };
  return {
    errors,
    handleSubmit,
  };
};
