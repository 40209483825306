import { i18next } from "@health/i18n";
import { Branch, User, UserSortField } from "@health/queries";
import { CustomTableColumnProps } from "@health/ui";
import { ActiveToggleButton } from "./ActiveToggle.component";

export const UsersColumns = (t: i18next.TFunction): CustomTableColumnProps<User>[] => [
  {
    key: "firstName",
    header: t("First Name"),
    accessor: "firstName",
    isSortable: true,
    sortDirection: undefined,
    hideFromSettings: true,
    disableToggleVisibility: true,
    sortColumnEnum: UserSortField.FirstName,
  },
  {
    key: "LastName",
    header: t("Last Name"),
    accessor: "lastName",
  },
  {
    key: "Email",
    header: t("Email"),
    accessor: "email",
    isSortable: true,
    sortDirection: undefined,
    sortColumnEnum: UserSortField.Email,
  },
  {
    key: "Mobile",
    header: t("Mobile"),
    accessor: "mobile",
  },
  {
    key: "healthLicenseStartDate",
    header: t("Health License Start Date"),
    accessor: ({ healthLicenseStartDate }) => healthLicenseStartDate ?? "--",
  },
  {
    key: "healthLicenseEndDate",
    header: t("Health License End Date"),
    accessor: ({ healthLicenseEndDate }) => healthLicenseEndDate ?? "--",
  },
  {
    key: "appRole",
    header: t("App Role"),
    accessor: ({ appRole }) => appRole ?? "--",
  },
  {
    key: "isActive",
    header: t("Is Active"),
    accessor: row => (
      <ActiveToggleButton
        id={row?.id}
        isActive={row?.isActive}
        appRole={row?.appRole!}
        branches={row?.branches as Branch[]}
        appType={row?.appType!}
      />
    ),
  },
];
