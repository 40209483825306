import { getMediaLink } from "@health/common";
import { ZoomUploadedImage } from "@health/ui";
import React, { FC, useState } from "react";
import { useImagesMessageStyles } from "./ImageMessage.styles";
import { ImageMessageProps } from "./ImageMessage.types";

export const ImageMessage: FC<ImageMessageProps> = ({ image }) => {
  const { classes } = useImagesMessageStyles();
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const imageURL = getMediaLink(image?.file);

  return (
    <React.Fragment>
      <img src={imageURL} alt={image?.alt || "image"} className={classes.img} onClick={handleOpen} />

      <ZoomUploadedImage open={open} handleClose={onClose} uploadedImage={imageURL} />
    </React.Fragment>
  );
};
