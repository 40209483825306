import { getMediaLink } from "@health/common";
import { useTranslation } from "@health/i18n";
import { ProductVariant } from "@health/queries";
import { CustomDialog, EmptyCard, ShowButton } from "@health/ui";
import { FC, Fragment, useState } from "react";
import { useStyles } from "./ProductImage.styles";

export const ShowProductImages: FC<{ row: ProductVariant }> = ({ row }) => {
  const { t } = useTranslation("vendor");
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(!open);
  };
  const { classes } = useStyles();
  return (
    <CustomDialog open={open} title={t("Product Images")} onCloseModal={handleClickOpen} button={<ShowButton onClick={handleClickOpen} />}>
      {row?.product?.images?.length != 0 ? (
        row?.product?.images?.map((image: any) => [
          <Fragment key={image?.id!}>
            <img
              className={classes.imageListItem}
              height={row?.product?.images?.length === 1 || row?.product?.images?.length === 2 ? 300 : 150}
              width={"300px"}
              loading='lazy'
              src={getMediaLink(image?.url)}
              alt={image?.alt + "product-image" + image?.id}
            />
          </Fragment>,
        ])
      ) : (
        <EmptyCard title={t("There are no product images")} message='' />
      )}
    </CustomDialog>
  );
};
