import { useOpenStateController } from "@health/common";
import { useTranslation } from "@health/i18n";
import { formatMessageErrors, useGetRejectionReasonsQuery, useVendorOrderRejectMutation } from "@health/queries";
import {
  Button,
  CircularProgress,
  CustomMuiDialog,
  darkBlue,
  darkGrey,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useAddToast,
} from "@health/ui";
import React, { FC } from "react";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderActionStyles } from "./OrderAction.styles";

type RejectReasonModelProps = {
  id: string;
};

export const RejectReasonButton: FC<RejectReasonModelProps> = ({ id }) => {
  const { failed, succeeded } = useAddToast();

  const { t } = useTranslation("vendor");
  const { classes } = useOrderActionStyles();
  const [rejectionReason, setRejectionReason] = React.useState("");
  const { open: isOpen, handleOpen, handleClose } = useOpenStateController();
  const { data, loading } = useGetRejectionReasonsQuery({
    skip: !isOpen,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectionReason((event.target as HTMLInputElement).value);
  };

  const orderRejectionReasons = data?.getRejectionReasons?.map(reason => reason);

  const [orderVendorReject, { loading: loadingReject }] = useVendorOrderRejectMutation({
    onCompleted: data => {
      if (data?.vendorOrderReject?.orderErrors?.length == 0) {
        succeeded(t("Order Rejected Successfully"));
        handleClose();
      } else {
        const msg = formatMessageErrors(data?.vendorOrderReject?.orderErrors);
        failed(msg);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatMessageErrors(graphQLErrors));
    },
  });
  const handleOrderRejected = (): void => {
    orderVendorReject({
      variables: {
        id,
        rejectionReason,
      },
    });
  };

  return (
    <CustomMuiDialog
      open={isOpen}
      onBackdropClick={handleClose}
      DialogTitleProps={{
        title: t("Reject Request Reason"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        children: (
          <CircularIntegration loading={loadingReject}>
            <Button onClick={handleOrderRejected} disabled={!rejectionReason} className={classes.reject}>
              {t("Reject")}
            </Button>
          </CircularIntegration>
        ),
      }}
      button={
        <Button color='error' variant='outlined' onClick={handleOpen}>
          {t("Reject")}
        </Button>
      }
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <RadioGroup value={rejectionReason} onChange={handleChange}>
          {orderRejectionReasons?.length ? (
            orderRejectionReasons?.map(reason => (
              <FormControlLabel
                key={reason?.id}
                value={reason?.display}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: rejectionReason !== reason?.display ? darkGrey : darkBlue,
                    }}
                  >
                    {String(reason?.display)}
                  </Typography>
                }
              />
            ))
          ) : (
            <Typography>{t("There is no Reject Request Reason")}</Typography>
          )}
        </RadioGroup>
      )}
    </CustomMuiDialog>
  );
};
