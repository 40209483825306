import { formatAddressFull } from "@health/common";
import { useTranslation } from "@health/i18n";
import { OrderTypesEnum } from "@health/queries";
import { Typography } from "@health/ui";
import React from "react";
import { IOrderSection } from "../../actions/types";
import { BeneficiaryInfo } from "./BeneficiaryInfo.components";
import { ChatButton } from "./ChatButton";
import { DiagnosisInformation } from "./DiagnosisInformation";
import { MedicationsSection } from "./MedicationsSection";
import { OrderDetails } from "./OrderDetails";

export const useeRxSections = (data: IOrderSection) => {
  const { t, i18n } = useTranslation("vendor");
  const {
    branch,
    status,
    number,
    deliveryDate,
    deliveryTimeSlot,
    type,
    referenceNumber,
    shippingAddress,
    patient,
    hasChat = false,
    diagnosis,
    medications,
    headerComponent,
    timeOutComponent,
  } = data;

  const address = formatAddressFull(shippingAddress);

  const eRxSections: Array<{
    hasData?: boolean;
    children?: JSX.Element;
    title?: string;
    color?: string;
    subTitle?: string;
  }> = [
    {
      hasData: hasChat,
      children: <ChatButton />,
    },

    {
      hasData: Boolean(headerComponent),
      children: headerComponent,
    },
    {
      hasData: Boolean(timeOutComponent || number || referenceNumber),
      children: (
        <OrderDetails
          deliveryMethod={type as OrderTypesEnum}
          referenceNumber={referenceNumber!}
          number={number}
          timeOutComponent={timeOutComponent!}
          deliveryDate={deliveryDate}
          deliveryTimeSlot={deliveryTimeSlot}
        />
      ),
    },
    {
      title: t("Branch"),
      hasData: !!branch,
      children: <Typography variant='body2'>{(i18n.language === "ar" && branch?.nameAr) || branch?.name}</Typography>,
    },
    {
      hasData: true,
      title: t("Beneficiary info"),
      children: <BeneficiaryInfo patient={patient} status={status} />,
    },
    {
      title: t("Medications"),
      hasData: Boolean(medications?.length),
      children: <MedicationsSection medications={medications} status={status} />,
    },
    {
      title: t("Diagnosis Information"),
      hasData: Boolean(diagnosis?.length),
      children: <DiagnosisInformation diagnosis={diagnosis} />,
    },
    {
      title: t("Pickup from pharmacy"),
      hasData: type === OrderTypesEnum.PickupFromPharmacy,
    },
  ];

  return {
    address,
    eRxSections,
  };
};
