import { useToasts } from "@health/ui";
import { useReducer, useState } from "react";

type ReturnedSig = {
  recorder: MediaRecorder | null;
  start: () => Promise<void>;
  stop: () => void;
  isRecording: boolean;
  isFailed: boolean;
};

type State = {
  isRecording: boolean;
  recorder: MediaRecorder | null;
  data: Blob | null;
};

type Actions = { type: "start" } | { type: "startRecording"; payload: { recorder: MediaRecorder } } | { type: "stop" } | { type: "cancel" };

const initState: State = {
  isRecording: false,
  recorder: null,
  data: null,
};

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "start":
      return { ...state, isRecording: true };
    case "stop":
      return { ...state, isRecording: false };
    case "cancel":
      return { ...state, isRecording: false };
    case "startRecording":
      return { ...state, isRecording: true, recorder: action.payload.recorder };
    default:
      return state;
  }
};

export const useVoiceRecorder = (cb: (result: Blob) => void): ReturnedSig => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const { addToast } = useToasts();
  const finishRecording = ({ data }: { data: Blob }) => {
    cb(data);
  };

  const start = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true }).catch(err => {
      console.error(err.message);
      addToast(err.message, {
        appearance: "error",
        autoDismiss: true,
      });
      setIsFailed(true);
    });
    if (stream) {
      setIsFailed(false);
      dispatch({ type: "start" });
      const recorder = new MediaRecorder(stream);
      dispatch({ type: "startRecording", payload: { recorder } });
      recorder.start();
      recorder.addEventListener("dataavailable", finishRecording);
    }
  };

  const stop = () => {
    const { recorder } = state;
    dispatch({ type: "stop" });
    if (recorder) {
      recorder.stop();
      recorder.removeEventListener("dataavailable", finishRecording);
      recorder.stream.getTracks().forEach(t => {
        t.stop();
      });
    }
  };

  return {
    start,
    stop,
    recorder: state.recorder,
    isRecording: state.isRecording,
    isFailed,
  };
};
