import { LoginPage as CustomLoginPage } from "@health/entry";
import { AppTypes } from "@health/queries";
import { useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const navigate = useNavigate();

  const redirectLogin = () => {
    navigate("/", { replace: true });
  };

  return <CustomLoginPage app={AppTypes.Vendor} afterSubmitSuccess={() => redirectLogin()} />;
};
