import { makeStyles } from "@health/ui";

export const useOrdersTabStyles = makeStyles<{ color: string }>()((_, { color }) => ({
  button: {
    color: `${color} !important`,
    borderRadius: 0,
    height: 50,
    fontSize: 16,
    margin: 0,
    "& .MuiButton-startIcon": {
      margin: 5,
    },
  },
  selected: {
    opacity: 1,
    fontWeight: "bold",
    backgroundColor: `#ECF0F5 !important`,
    margin: 0,
    height: 50,
    borderRadius: 0,
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      fill: color,
    },
  },
  count: {
    background: color,
    paddingInline: 12,
    height: 22,
    fontSize: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginInline: 10,
    color: "white",
    borderRadius: "20px",
  },
}));
