import { combineErrors, getRequiredValidation } from "@health/common";
import { useTranslation } from "@health/i18n";
import { LocationPicker } from "@health/maps";
import { CitiesAutocomplete } from "@health/smart-ui";
import { FormHelperText, Grid, Typography } from "@health/ui";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomTextField } from "shared/components";
import { formGirdBreakPoints, formGirdSpacing } from "shared/constants";
import { LocationProps } from "../../BranchesForm.types";
import { locationFields } from "./locationUtils";

const Address: FC<LocationProps> = props => {
  const { t } = useTranslation("vendor");
  const { errorsInput, defaultValues } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const formErrors = combineErrors(errors, errorsInput);

  return (
    <Grid container spacing={formGirdSpacing}>
      {locationFields(t, defaultValues)?.map(item => (
        <Grid item {...formGirdBreakPoints} key={item.name}>
          <CustomTextField
            name={item.name}
            label={item.label}
            defaultValue={item?.defaultValue}
            pattern={item.pattern}
            isRequired={!!item?.isRequired}
            errorsInput={formErrors}
            minLength={item?.minLength}
            maxLength={item?.maxLength}
          />
        </Grid>
      ))}
      <Grid item {...formGirdBreakPoints}>
        <CitiesAutocomplete
          rules={{ required: getRequiredValidation(t, true) }}
          errors={formErrors?.addressInput}
          control={control}
          cityItem={defaultValues?.address?.city || null}
          name='addressInput.city'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1' gutterBottom>
          {t("Please Click on Map to detect your Coordinates")}
        </Typography>
        <Controller
          name='addressInput.coordinates'
          rules={{ required: getRequiredValidation(t, true) }}
          render={({ field }) => (
            <>
              {errors.addressInput?.coordinates?.message && (
                <FormHelperText error>
                  {errors.addressInput?.coordinates?.message}, {t("Please Click on Map to detect your Coordinates")}
                </FormHelperText>
              )}

              <LocationPicker
                hasSearch
                defaultCenter={{ lat: 24.750643, lng: 46.707766 }}
                location={field.value}
                // eslint-disable-next-line react/jsx-handler-names
                onLocationChange={location => {
                  field.onChange(location?.coordinates);
                }}
                icon={"markerMap"}
              />
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};
export default Address;
