import { useTranslation } from "@health/i18n";
import { Branch, BranchError, formatMessageErrors, useBranchQuery, useBranchUpdateMutation } from "@health/queries";
import { doesBranchHasAcceptDeliveryFlag, doesBranchHasAcceptPickupFlag } from "@health/smart-ui";
import { useAddToast } from "@health/ui";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_PATHS } from "shared/constants";

export const useBranchEditPageHook = () => {
  const { t } = useTranslation("vendor");
  const navigate = useNavigate();
  const params = useParams();
  const { succeeded, failed } = useAddToast();
  const [errors, setError] = useState<BranchError[]>();

  const [branchUpdate] = useBranchUpdateMutation({
    onError: () => {
      failed(t("Branch Update Failed"));
    },
  });
  const { data, loading } = useBranchQuery({
    variables: {
      id: String(params?.id),
    },
  });

  const handleUpdateBranch = request => {
    branchUpdate({
      variables: {
        id: params.id as string,
        branch: {
          ...request,
          acceptsDelivery: doesBranchHasAcceptDeliveryFlag(request?.type) && request?.acceptsDelivery,
          acceptsPickup: doesBranchHasAcceptPickupFlag(request?.type) && request?.acceptsPickup,
        },
      },
    }).then(response => {
      const branchErrors = response?.data?.branchUpdate?.branchErrors;
      if (branchErrors?.length === 0) {
        succeeded(t("Branch Update Successfully"));
        navigate("/" + ROUTE_PATHS?.branches?.path);
      } else {
        const error = formatMessageErrors(branchErrors);
        failed(t(error));
        setError(branchErrors as BranchError[]);
      }
    });
  };

  const branch = data?.branch as Branch;

  return {
    branch,
    errors,
    loading,
    handleUpdateBranch,
  };
};
