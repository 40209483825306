import { ActiveFilters, CustomTable, PageWrapper, StaticFilterDialog } from "@health/ui";
import { PharmacyBranch } from "shared/components";
import { usePrescriptionsPage } from "./Prescriptions.hook";

export const PrescriptionsScreen = () => {
  const {
    filters,
    columns,
    prescriptions,
    isLoading,
    pageSize,
    pageInfo,
    totalCount,
    activeFilters,
    headerActiveFilters,
    isFilterOpened,
    handleSortData,
    handleGotoNext,
    handleApplyFilters,
    handleGoToPrevious,
    handlePageSizeChange,
    handleToggleFilters,
  } = usePrescriptionsPage();

  return (
    <PageWrapper
      start={<ActiveFilters onOpenFilters={handleToggleFilters} activeFilters={headerActiveFilters || {}} i18nNs='vendor' />}
      actions={
        <>
          <PharmacyBranch />
          <StaticFilterDialog
            isOpen={isFilterOpened}
            onToggleDialog={handleToggleFilters}
            activeFilters={activeFilters}
            filterFields={filters}
            onApplyFilters={handleApplyFilters}
          />
        </>
      }
    >
      <CustomTable
        totalCount={totalCount}
        data={prescriptions || []}
        columns={columns}
        isLoading={isLoading}
        pageSize={pageSize}
        pageIndex={1}
        pagesCount={1}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        onGoToNext={handleGotoNext}
        onSortColumn={handleSortData}
        onGoToPrevious={handleGoToPrevious}
        onPageSizeChange={handlePageSizeChange}
      />
    </PageWrapper>
  );
};
