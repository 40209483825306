/* eslint-disable max-lines */
import { useReactiveVar } from "@apollo/client";
import {
  capitalize,
  ChatMessageReceivedNotificationEvent,
  EVENT_TOPIC,
  UserContext,
  useSubscribeToNotificationTopic,
} from "@health/common";
import { useTranslation } from "@health/i18n";
import { AppRoleTypes, openVar, useUnreadMessagesCountQuery } from "@health/queries";
import {
  AccountDropdownMenu,
  CustomerIcon,
  cyan,
  darkBlue,
  getTranslatedNameField,
  IconButton,
  Layout,
  openPharmacyBranch,
  SidebarDataType,
  SpeechIcon,
  useMediaQuery,
} from "@health/ui";
import NotificationForm from "pages/Settings/components/NotificationForm/NotificationForm.component";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { client } from "../../configs/apollo";
import { Notifications } from "../Notifications";
import { PharmacyBranchDialog, pharmacyUserPreferencesVar, removeSelectedBranchFromLocalStorage } from "../PharmacyBranch";
import { handleAddClientNewMessage, useAppRoutes } from "./useAppRoutes";

export function CustomAppLayout() {
  const { t } = useTranslation("vendor");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const isMatch = useMediaQuery("(max-width:1200px)");

  const pharmacyUserPreferences = useReactiveVar(pharmacyUserPreferencesVar);

  const { logout, user, isAuthenticated } = useContext(UserContext);

  const routes = useAppRoutes();

  const { data: msg, refetch } = useUnreadMessagesCountQuery();

  const onChatMessageSent = useCallback(
    ({ data }: ChatMessageReceivedNotificationEvent) => {
      const messageData = {
        ...data,
        __typename: "Message",
        content: data?.content,
        created: data?.created,
        branch: { __typename: "Branch", ...data?.branch },
        sender: { __typename: "User", ...data?.sender },
        attachments: data?.attachments?.map(attachment => ({ __typename: "Attachment", ...attachment })),
      };
      handleAddClientNewMessage(client?.cache, messageData, messageData?.branch);
      refetch();
    },
    [client, user]
  );

  useSubscribeToNotificationTopic(EVENT_TOPIC.CHAT_MESSAGE_RECEIVED, onChatMessageSent);

  const unReadMessagesCount = msg?.unreadMessagesCount?.unReadMessagesCount || 0;

  const routeWithSelectedItems = (routes: SidebarDataType[]) => {
    return routes
      .map(route => ({
        ...route,
        selected: location.pathname.split("/").some(el => el === route?.route),
        subItems: route.subItems ? routeWithSelectedItems(route?.subItems) : undefined,
      }))
      .filter(Boolean);
  };
  const handleNavigate = (route: string) => navigate(route);
  const handleGoToHome = () => navigate("/");
  const handleGoToChat = () => navigate("/chat");
  const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    pharmacyUserPreferencesVar(undefined);
    removeSelectedBranchFromLocalStorage();
    logout();
    window?.["sseControllerRef"]?.abort();

    window.location.href = "/login";
    client.clearStore();
  };
  const handleClickClose = () => {
    setAnchorEl(null);
  };

  const open = useReactiveVar(openVar);

  const handleToggleDrawer = () => {
    openVar(!openVar());
  };

  const breadCrumb = useMemo(() => {
    const paramsObject: any = params;
    delete paramsObject?.["*"];
    const paramsArray = Object.values(paramsObject);
    const splittedPath = location.pathname.substring(1).split("/");
    return splittedPath
      .filter(item => !paramsArray.includes(item))
      .map((path, i) => ({
        id: path,
        name: capitalize(path.split("-")),
        path: path,
        fullPath: "/" + splittedPath.slice(0, i + 1).join("/"),
        onClick: fullPath => {
          const i = splittedPath.indexOf(path);
          navigate(fullPath || "/" + splittedPath.slice(0, i + 1).join("/"));
        },
      }));
  }, [location, params]);

  const getBranchName = () => {
    return pharmacyUserPreferences?.branch?.map(b => b && getTranslatedNameField(b)).join(", ");
  };
  useEffect(() => {
    user?.appRole === AppRoleTypes.User && !pharmacyUserPreferences?.branch && openPharmacyBranch(true);
  }, [pharmacyUserPreferences?.branch?.map(b => b.id)?.join(",")]);
  useEffect(() => {
    isMatch && openVar(false);
  }, [isMatch]);
  const paths = location?.pathname !== "/Orders-history" && location?.pathname !== "/orders" && location?.pathname !== "/prescriptions";
  return (
    <>
      {paths && <PharmacyBranchDialog />}
      <Layout
        isOpen={open}
        branchName={getBranchName()}
        title={t(breadCrumb[0]?.name)}
        breadCrumb={breadCrumb.slice(1, breadCrumb?.length)}
        drawerItems={routeWithSelectedItems(routes)}
        onGoToHome={handleGoToHome}
        onNavigate={handleNavigate}
        onToggleDrawer={handleToggleDrawer}
        rightItems={[
          {
            id: "notifications",
            icon: <Notifications />,
          },
          {
            id: "messages",
            name: "",
            count: unReadMessagesCount,
            icon: isAuthenticated && <SpeechIcon fill={location?.pathname.includes("chat") ? cyan : darkBlue} />,
            onClick: handleGoToChat,
          },
          {
            id: "vendor",
            icon: isAuthenticated && (
              <IconButton onClick={handleClickOpen} color={isOpen ? "info" : "primary"}>
                <CustomerIcon />
              </IconButton>
            ),
          },
        ]}
      >
        <Outlet />
      </Layout>
      {isOpen && (
        <AccountDropdownMenu
          username={[user?.firstName, user?.lastName].filter(Boolean).join(" ") || "User"}
          open={isOpen}
          onLogout={handleLogout}
          items={[
            {
              component: <NotificationForm />,
            },
          ]}
          anchorEl={anchorEl}
          onClose={handleClickClose}
        />
      )}
    </>
  );
}
