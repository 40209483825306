import { getMaxLengthValidation, getMinLengthValidation, patternMobile } from "@health/common";
import { LanguageCodeEnum } from "@health/queries";
import { getTranslationForObjProperty } from "@health/ui";

export const locationFields = (t, defaultValue) => [
  {
    name: "addressInput.area",
    label: t("Area"),
    defaultValue: defaultValue?.address?.area,
    maxLength: getMaxLengthValidation(t, 128),
    minLength: getMinLengthValidation(t, 3),
    isRequired: true,
  },
  {
    name: "addressInput.streetAddress1",
    label: t("English Street Address 1"),
    maxLength: getMaxLengthValidation(t, 256),
    minLength: getMinLengthValidation(t, 3),
    defaultValue: getTranslationForObjProperty(defaultValue?.address, "streetAddress1", LanguageCodeEnum.En),
    isRequired: true,
  },
  {
    name: "arabicStreetAddress",
    label: t("Arabic Street Address 1"),
    maxLength: getMaxLengthValidation(t, 255),
    minLength: getMinLengthValidation(t, 3),
    defaultValue: getTranslationForObjProperty(defaultValue?.address, "streetAddress1", LanguageCodeEnum.Ar),
    isRequired: true,
  },
  {
    name: "addressInput.streetAddress2",
    label: t("English Street Address 2"),
    maxLength: getMaxLengthValidation(t, 255),
    minLength: getMinLengthValidation(t, 3),
    defaultValue: getTranslationForObjProperty(defaultValue?.address, "streetAddress2", LanguageCodeEnum.En),
  },
  {
    name: "arabicCityName",
    label: t("Arabic Street Address 2"),
    maxLength: getMaxLengthValidation(t, 255),
    minLength: getMinLengthValidation(t, 3),
    defaultValue: getTranslationForObjProperty(defaultValue?.address, "streetAddress2", LanguageCodeEnum.Ar),
  },
  {
    name: "addressInput.phone",
    label: t("Phone Number"),
    defaultValue: defaultValue?.address?.phone,
    pattern: patternMobile,
  },
];
