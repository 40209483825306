import { UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Box, CircularProgress, darkGrey, Grid, InfiniteScroll, Typography, white } from "@health/ui";
import React, { FC, useContext } from "react";
import { getMessageAvatar } from "../../pages/utils";
import { Message } from "../Message";
import { useConversationStyles } from "./Conversation.styles";
import { ConversationProps } from "./Conversation.types";

export const Conversation: FC<ConversationProps> = ({
  client = undefined,
  messages,
  loading = true,
  height = 700,
  error,
  onLoadMore,
  hasMore = false,
  isFetchingMore = false,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { classes } = useConversationStyles();
  return (
    <Box id={"conversation"} sx={{ height, minHeight: height, width: "100%", overflow: "auto", background: white }}>
      {!error ? (
        <Grid container direction='column'>
          <Box
            id='scrollableDiv'
            sx={{
              height: height,
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            {loading ? (
              <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <InfiniteScroll
                dataLength={messages?.length || 0}
                next={onLoadMore}
                className={classes.scroll}
                inverse={true}
                hasMore={hasMore}
                loader={
                  isFetchingMore && (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <CircularProgress />
                    </Box>
                  )
                }
                scrollableTarget='scrollableDiv'
              >
                {messages?.length ? (
                  messages.map(element => {
                    const isSender = element.sender.email == user?.email;
                    return (
                      <Message
                        key={`${element.id}`}
                        message={element.content}
                        created={element.created}
                        isSender={isSender}
                        attachments={element.attachments}
                        avatar={getMessageAvatar(element, client)}
                      />
                    );
                  })
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography color={darkGrey}>{t("No Messages")}</Typography>
                    <Typography color={darkGrey}>{t("Start Conversation by sending new Message")}</Typography>
                  </Box>
                )}
              </InfiniteScroll>
            )}
          </Box>
        </Grid>
      ) : (
        <Typography>{t("Error Load Messages")}</Typography>
      )}
    </Box>
  );
};
