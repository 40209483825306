import { branchesRoutes } from "./branches";
import { medicalDeliveryRequestRoutes } from "./medicalDeliveryRequest";
import { ordersManagementRoutes, ordersRoutes } from "./orderHistory";
import { productsManagementVendorRoutes } from "./product";
import { settingsRoutes } from "./settings";
import { UsersManagementRoutes } from "./user";

const dashboard = "dashboard";
const chat = "chat";

export const ROUTE_PATHS = {
  dashboard: {
    name: "Dashboard",
    path: dashboard,
  },
  chat: {
    name: "Chat",
    path: chat,
    fullPath: chat,
  },
  settings: settingsRoutes,
  orderHistory: ordersRoutes,
  branches: branchesRoutes,
  productsManagementVendorRoutes: productsManagementVendorRoutes,
  usersManagement: UsersManagementRoutes,
  orders: ordersManagementRoutes,
  medicalDeliveryRequest: medicalDeliveryRequestRoutes,
};
