import { combineErrors, getPatternMobileErrorMessage, getRequiredValidation } from "@health/common";
import { useTranslation } from "@health/i18n";
import { OptionalLabel, StandardTextFieldProps, TextField } from "@health/ui";
import React, { FC } from "react";
import { useFormContext, ValidationRule } from "react-hook-form";

export interface CustomTextFieldProps extends StandardTextFieldProps {
  name: string;
  label: string;
  isRequired?: boolean;
  defaultValue?: string | null;
  pattern?: ValidationRule<RegExp>;
  errorsInput?;
  minLength?: ValidationRule<number>;
  maxLength?: ValidationRule<number>;
}

export const CustomTextField: FC<CustomTextFieldProps> = ({
  name,
  label,
  defaultValue,
  pattern,
  isRequired = true,
  errorsInput,
  minLength,
  maxLength,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const formErrors = combineErrors(errors, errorsInput);

  const newName = name.split(".");
  const errorMsg = name.includes(".") ? formErrors?.[newName[0]]?.[newName[1]]?.message : formErrors?.[name]?.message;

  return (
    <TextField
      fullWidth
      label={isRequired ? label : <OptionalLabel label={String(label)} />}
      defaultValue={defaultValue}
      error={Boolean(errorMsg)}
      helperText={getPatternMobileErrorMessage(t, errorMsg)}
      {...register(name, {
        required: getRequiredValidation(t, isRequired),
        pattern: pattern,
        maxLength: maxLength,
        minLength: minLength,
      })}
      {...props}
    />
  );
};

CustomTextField.defaultProps = {
  isRequired: undefined,
  pattern: undefined,
  defaultValue: undefined,
  errorsInput: undefined,
  label: undefined,
  minLength: undefined,
  maxLength: undefined,
};
