import { useTranslation } from "@health/i18n";
import { VendorBranchTypeEnum, VendorType } from "@health/queries";
import { AutocompleteController, Grid } from "@health/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { formGirdBreakPoints } from "shared/constants";

const getAllowedBranchTypes = (type: string) => {
  switch (type) {
    case VendorType.Pharmacy:
      return [VendorBranchTypeEnum.Pharmacy, VendorBranchTypeEnum.MedicalDeliveryOnly, VendorBranchTypeEnum.ReferencePharmacy];
    case VendorType.PartnerWarehouse:
      return [VendorBranchTypeEnum.PartnerWarehouse];
    case VendorType.Phc:
      return [VendorBranchTypeEnum.Phc];
    default:
      return [];
  }
};
export const BranchesType: FC<{ providerType; defaultValue }> = ({ providerType, defaultValue }) => {
  const { t } = useTranslation("vendor");
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();

  if (!providerType) return <></>;

  const types = Object.keys(VendorBranchTypeEnum)
    .map(key => ({ value: VendorBranchTypeEnum[key], name: key }))
    .filter(item => getAllowedBranchTypes(providerType).includes(item?.value));
  const defaultTypeValue = types?.find(item => item.value === (defaultValue ? defaultValue?.type : providerType));

  return (
    <Grid item {...formGirdBreakPoints}>
      <AutocompleteController
        options={types || []}
        ControllerProps={{
          name: "type",
          control: control,
          defaultValue: defaultTypeValue,
        }}
        getOptionLabel={option => t(option.name, { context: "en" })}
        TextFieldProps={{
          placeholder: t("Type"),
          error: Boolean(formErrors?.type?.message),
          helperText: t(formErrors?.type?.message),
        }}
      />
    </Grid>
  );
};
