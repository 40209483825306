/* eslint-disable react/default-props-match-prop-types */
import { Avatar, Box, CustomIcon, Typography } from "@health/ui";
import React, { FC } from "react";
import { useCommunicationSelectedUserStyles } from "./CommunicationSelectedUser.styles";
import { CommunicationSelectedUserProps } from "./CommunicationSelectedUser.types";

const defaultAvatar = <CustomIcon icon={"changeUser"} color={"#fff"} width={27} height={27} viewBox='0 0 32 32' />;

export const CommunicationSelectedUser: FC<CommunicationSelectedUserProps> = ({ pharmacyId, title, subTitle, avatar, onClick }) => {
  const { classes } = useCommunicationSelectedUserStyles();

  const handleClick = () => {
    onClick?.(pharmacyId);
  };

  return (
    <Box className={`${classes.root} ${classes.selectedUserRoot}`} onClick={handleClick}>
      <div>
        <Avatar className={classes.avatar} src={avatar?.url || avatar || ""} />
      </div>
      <div className={classes.container}>
        <div>
          <div className={classes.title}>{title}</div>
          <Typography className={classes.massage}>{subTitle}</Typography>
        </div>
      </div>
    </Box>
  );
};

CommunicationSelectedUser.defaultProps = {
  avatar: defaultAvatar,
};
