import { useTranslation } from "@health/i18n";
import { Box, CustomIcon, darkRed, OrderColorEnum, Typography } from "@health/ui";
import React, { FC } from "react";
import { OrderSection } from "../OrderSection";

export const MedicalAlerts: FC<{ medicalAlerts: string[] }> = ({ medicalAlerts }) => {
  const { t } = useTranslation("vendor");

  return (
    <Box display='flex'>
      <Box m='10px 5px'>
        <CustomIcon icon='error' color={darkRed} height={22} />
      </Box>
      <OrderSection color={OrderColorEnum.error} title={t("Medical Alerts")}>
        {medicalAlerts?.map((item, index) => (
          <Typography
            key={item + index}
            sx={{
              fontSize: 14,
              color: darkRed,
            }}
          >
            {item}
          </Typography>
        ))}
      </OrderSection>
    </Box>
  );
};
