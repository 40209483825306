import { makeStyles, white } from "@health/ui";
import { isMobile } from "react-device-detect";

export const useAttachmentSendPreviewerStyles = makeStyles()({
  root: {
    background: white,
    padding: 5,
    margin: "0 15px",
    display: "flex",
    alignItems: "center",
    marginBottom: 1,
    flexDirection: isMobile ? "column" : "row",
  },
  audio: {
    marginTop: 5,
    outline: "none",
    height: 45,
    width: isMobile ? 184 : "100%",
  },
  imgWrapper: {
    display: "inline-flex",
    position: "relative",
    width: 80,
    height: 80,
    margin: 4,
    borderRadius: 8,
    "&:hover": {
      "& button": {
        display: "block",
      },
    },
  },
  imgRemoveButton: {
    display: "none",
    position: "absolute",
    right: 0,
    top: 0,
    margin: "auto",
    width: "100%",
    height: "100%",
  },
  img: {
    height: "100%",
    width: "100%",
  },
});
