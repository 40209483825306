import { formGirdBreakPoints, formGirdSpacing, getMinValueValidation, getRequiredValidation } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Money } from "@health/queries";
import { Grid, InputAdornment, TextField } from "@health/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

const PriceInformation: FC<{ price: Money | null | undefined }> = ({ price }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation("vendor");
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          placeholder={t("Price")}
          label={t("Price")}
          fullWidth
          type='number'
          defaultValue={price?.amount}
          error={Boolean(errors?.price?.message)}
          helperText={errors?.price?.message}
          InputProps={{
            endAdornment: <InputAdornment position='end'>{t(price?.currency as string)}</InputAdornment>,
          }}
          {...register("price", { required: getRequiredValidation(t, true), min: getMinValueValidation(t, 0) })}
        />
      </Grid>
    </Grid>
  );
};

export default PriceInformation;
