/* eslint-disable max-lines */
import { NetworkStatus } from "@apollo/client";
import { ChatMessageReceivedNotificationEvent, EVENT_TOPIC, TOPIC_LISTENER, useSubscribeToNotificationTopic } from "@health/common";
import {
  Attachment,
  Branch,
  MessageAttachmentInput,
  useBranchMessagesLazyQuery,
  useChatBranchesListQuery,
  useChatCustomersListQuery,
  useFileUpload,
  User,
  useVendorSendMessageMutation,
} from "@health/queries";
import { useCallback, useEffect, useState } from "react";
import { handleAddVendorNewMessage } from "../utils";
import { useChatQueries } from "./useChatQueries";

export const useVendorChatPageHooks = () => {
  const [branch, setBranch] = useState<Branch | undefined>(undefined);
  const [client, setClient] = useState<User | undefined | null>(undefined);

  const [attachment, setAttachment] = useState<Array<Attachment | undefined | null>>([]);

  const { vendorMarkAsSeen, uploadAttachmentMutation } = useChatQueries();

  const addNewMessageToState = () => {
    setAttachment([]);
  };

  const [fetchBranchMessages, { data: branchMessagesData, fetchMore: fetchMoreBranchMessages, networkStatus: messageNetworkStatus }] =
    useBranchMessagesLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const branchMessages = branchMessagesData?.branchMessages?.messages?.edges.map(messages => messages.node);

  const [vendorSendMessageMutation] = useVendorSendMessageMutation({
    update: (cache, message) => handleAddVendorNewMessage(cache, message?.data?.vendorSendMessage?.message, branch, client),
  });

  const {
    data: branchesQueryData,
    loading: branchesQueryLoading,
    fetchMore: fetchMoreBranches,
  } = useChatBranchesListQuery({
    variables: {
      first: 10,
    },
    onCompleted: data => {
      const currentBranch = data.chatBranchesList?.edges.map(branches => branches.node)[0] as Branch;
      setBranch(currentBranch);
    },
  });

  const {
    data: customersQueryData,
    loading: customersQueryLoading,
    refetch: refetchCustomers,
    fetchMore: fetchMoreCustomers,
  } = useChatCustomersListQuery({
    fetchPolicy: "network-only",
    variables: {
      first: 10,
      branch: branch?.id,
    },
    skip: Boolean(!branch?.id),
  });
  const branches = branchesQueryData?.chatBranchesList?.edges.map(branchItem => branchItem.node);
  const { hasNextPage: hasMoreBranches, endCursor: branchesEndCursor } = branchesQueryData?.chatBranchesList?.pageInfo || {};

  const customers = customersQueryData?.chatCustomersList?.edges.map(customer => customer.node);
  const { hasNextPage: hasMoreCustomers, endCursor: customersEndCursor } = customersQueryData?.chatCustomersList?.pageInfo || {};

  const { hasNextPage: hasMoreMessages, endCursor: messagesEndCursor } = branchMessagesData?.branchMessages?.messages?.pageInfo || {};
  const branchMessagesTotalCount = branchMessagesData?.branchMessages?.messages?.totalCount;
  const { fetchUploadFile } = useFileUpload();

  useEffect(() => {
    if (branch && client) {
      fetchBranchMessages({
        variables: {
          first: 10,
          branchId: branch.id,
          customerId: client.id,
        },
      });
    }
  }, [client, branch]);

  const handleSendMessage = (content: string) => {
    if (branch && branch.id && client) {
      let messageAttachments: MessageAttachmentInput[] = [];
      if (attachment.length) {
        messageAttachments = attachment.map(image => ({
          attachment: image?.id || "",
          sortOrder: image?.sortOrder || 0,
        }));
      }
      vendorSendMessageMutation({
        variables: {
          branch: branch?.id,
          content: content,
          recipient: client.id,
          attachments: messageAttachments,
        },
      }).then(addNewMessageToState);
    }
  };

  const handleLoadMoreMessages = () => {
    hasMoreMessages &&
      fetchMoreBranchMessages({
        variables: {
          first: 10,
          after: messagesEndCursor,
        },
      });
  };

  const handleDiscardAttachment = () => {
    setAttachment([]);
  };
  const handleDiscardAttachmentItem = (id: string) => {
    const filtered = attachment?.filter(att => att?.id != id);
    setAttachment(filtered);
  };
  const handleChangeBranches = (nextBranch: Branch) => {
    setClient(null);
    setBranch(nextBranch);
  };

  const handleSelectClient = (clientUser?: User) => {
    setClient(clientUser);
    clientUser?.id &&
      vendorMarkAsSeen({
        variables: {
          branchId: branch?.id,
          customerId: clientUser?.id,
        },
      });
  };

  const handleUploadRecord = (file: File) => {
    handleUploadFile(file);
  };

  const handleUploadImage = (files: File[]) => {
    for (const file of files) {
      handleUploadFile(file);
    }
  };

  const handleUploadFile = file => {
    fetchUploadFile({
      files: [file],
      onComplete: fileName => {
        uploadAttachmentMutation({
          variables: {
            file: fileName,
            alt: file.name,
            contentType: file.type,
          },
        }).then(e => {
          if (e.data.uploadAttachment.chatErrors.length) {
            throw "upload error";
          }
          setAttachment(atts => [
            ...(atts || []),
            {
              id: e.data?.uploadAttachment?.attachment?.id,
              file: fileName,
              contentType: file.type,
              alt: "Attachment",
            } as Attachment,
          ]);
        });
      },
    });
  };

  const onFetchMoreBranches = () => {
    hasMoreBranches &&
      fetchMoreBranches({
        variables: {
          first: 10,
          after: branchesEndCursor,
        },
      });
  };

  const handleFetchMoreCustomers = () => {
    hasMoreCustomers &&
      fetchMoreCustomers({
        variables: {
          first: 10,
          after: customersEndCursor,
        },
      });
  };
  const onMessageReceived: TOPIC_LISTENER<ChatMessageReceivedNotificationEvent>[EVENT_TOPIC.CHAT_MESSAGE_RECEIVED] = useCallback(() => {
    refetchCustomers();
  }, []);
  useSubscribeToNotificationTopic(EVENT_TOPIC.CHAT_MESSAGE_RECEIVED, onMessageReceived);

  const handleBranchChanged = (_, data) => handleChangeBranches(data as any);

  return {
    branch,
    branches,
    customers,
    client,
    hasMoreMessages,
    branchMessagesTotalCount,
    isMessagesLoading: NetworkStatus.loading === messageNetworkStatus,
    isMessagesFetchingMore: NetworkStatus.fetchMore === messageNetworkStatus,
    isBranchesLoading: branchesQueryLoading,
    isCustomersLoading: customersQueryLoading,
    branchMessages,
    attachment,
    hasMoreBranches,
    hasMoreCustomers,
    handleChangeBranches,
    handleSendMessage,
    handleSelectClient,
    handleLoadMoreMessages,
    handleUploadImage,
    handleUploadRecord,
    handleDiscardAttachment,
    handleDiscardAttachmentItem,
    onFetchMoreBranches,
    handleFetchMoreCustomers,
    handleBranchChanged,
  };
};
