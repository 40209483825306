import { ActiveFilters, CustomTable, MainListActions, PageWrapper, StaticFilterDialog } from "@health/ui";
import React, { FC } from "react";
import { useBranchesInfoHook } from "./BranchesTable.hook";

const BranchesScreen: FC = () => {
  const {
    branches,
    columns,
    pageSize,
    isLoading,
    totalCount,
    pageInfo,
    handleEdit,
    handleAddBranch,
    handleGoToPrevious,
    handleGotoNext,
    handlePageSizeChange,
    handleSortData,
    activeFilters,
    fields,
    handleApplyFilters,
    handleToggleFilters,
    isFilterOpened,
    hasMultipleBranch,
  } = useBranchesInfoHook();
  return (
    <PageWrapper
      start={<ActiveFilters onOpenFilters={handleToggleFilters} activeFilters={activeFilters ? activeFilters : []} />}
      actions={
        <>
          <MainListActions hasAddNew={hasMultipleBranch} onAddNewItem={handleAddBranch} />
          <StaticFilterDialog
            isOpen={isFilterOpened}
            onToggleDialog={handleToggleFilters}
            activeFilters={activeFilters}
            filterFields={fields}
            onApplyFilters={handleApplyFilters}
          />
        </>
      }
    >
      <CustomTable
        isEditVisible
        data={branches}
        columns={columns}
        pageSize={pageSize}
        isLoading={isLoading}
        onEditRow={handleEdit}
        onPageSizeChange={handlePageSizeChange}
        pagesCount={Math.ceil((totalCount || pageSize) / pageSize)}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        onSortColumn={handleSortData}
        onGoToNext={handleGotoNext}
        onGoToPrevious={handleGoToPrevious}
        pageIndex={1}
      />
    </PageWrapper>
  );
};

export default BranchesScreen;
