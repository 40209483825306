import { useTranslation } from "@health/i18n";
import { Typography } from "@health/ui";
import { FC } from "react";

type DeliveryWorkflowProps = {
  deliveryWorkflowId: string;
};

const DeliveryWorkflow: FC<DeliveryWorkflowProps> = props => {
  const { deliveryWorkflowId } = props;
  const { t } = useTranslation("vendor");

  return (
    <>
      <Typography fontSize={13} fontWeight={"bold"}>
        {t("Delivery Workflow Id")}
      </Typography>
      <Typography fontSize={12}> {deliveryWorkflowId} </Typography>
    </>
  );
};

export default DeliveryWorkflow;
