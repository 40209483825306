import {
  ActiveFilters,
  CustomTable,
  CustomToggleButton,
  FormControlLabel,
  getTranslatedNameField,
  PageWrapper,
  StaticFilterDialog,
  TablePreferencesDialog,
} from "@health/ui";
import { FC } from "react";
import useProductsManagementState from "./Products.hook";

const ProductsList: FC = () => {
  const {
    products,
    columns,
    isLoading,
    pageSize,
    pageInfo,
    dataError,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    handleSortData,
    totalCount,
    handleEditRow,
    handleApplyFilters,
    fields,
    activeFilters,
    isFilterOpened,
    handleToggleFilters,
    handleColumnsChanged,
    hasStocks,
    handleToggleStocks,
    t,
  } = useProductsManagementState();
  return (
    <PageWrapper
      start={
        <ActiveFilters
          onOpenFilters={handleToggleFilters}
          activeFilters={
            activeFilters
              ? {
                  ...activeFilters,
                  categories: activeFilters?.categories && activeFilters?.categories.map(cat => getTranslatedNameField(cat)).join(", "),
                  branches: activeFilters?.branches && activeFilters?.branches.map(branch => getTranslatedNameField(branch)).join(", "),
                  productTypes:
                    activeFilters?.productTypes && activeFilters?.productTypes.map(type => getTranslatedNameField(type)).join(", "),
                  productBrands:
                    activeFilters?.productBrands && activeFilters?.productBrands.map(brand => getTranslatedNameField(brand)).join(", "),
                  vendors: activeFilters?.vendors && activeFilters?.vendors.map(vendor => getTranslatedNameField(vendor)).join(", "),
                  product: activeFilters?.product?.name,
                }
              : []
          }
        />
      }
      actions={
        <>
          <FormControlLabel
            label={t("In Stock")}
            sx={{ fontSize: 12, minWidth: 120, margin: 1 }}
            control={<CustomToggleButton value={hasStocks} defaultChecked={hasStocks} onChange={handleToggleStocks} />}
          />
          <StaticFilterDialog
            isOpen={isFilterOpened}
            onToggleDialog={handleToggleFilters}
            activeFilters={activeFilters}
            filterFields={fields}
            onApplyFilters={handleApplyFilters}
          />
          <TablePreferencesDialog columnsPreferences={columns} onApplyPreferences={handleColumnsChanged} />
        </>
      }
    >
      <CustomTable
        data={dataError ? [] : products}
        columns={dataError ? [] : columns}
        isLoading={isLoading}
        isEditVisible={true}
        onEditRow={handleEditRow}
        pageSize={pageSize}
        pageIndex={1}
        pagesCount={Math.ceil((totalCount || pageSize) / pageSize)}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        onGoToNext={handleGotoNext}
        onGoToPrevious={handleGoToPrevious}
        onPageSizeChange={handlePageSizeChange}
        onSortColumn={handleSortData}
      />
    </PageWrapper>
  );
};
export default ProductsList;
