/* eslint-disable react/require-default-props */
import { useTranslation } from "@health/i18n";
import { DeliveryTimeSlot, OrderTypesEnum } from "@health/queries";
import { darkGrey, makeStyles, Typography } from "@health/ui";
import moment from "moment";
import { formatDeliveryTimeSlot } from "pages/Orders/utils";
import React, { FC } from "react";

const useOrderDetailsStyles = makeStyles()(() => ({
  title: {
    color: darkGrey,
    fontSize: 12,
  },
}));

type IOrderDetails = {
  timeOutComponent: JSX.Element | boolean;
  referenceNumber: string;
  number: string | number;
  deliveryDate?: string;
  deliveryMethod?: OrderTypesEnum;
  deliveryTimeSlot?: DeliveryTimeSlot;
};

export const OrderDetails: FC<IOrderDetails> = ({
  timeOutComponent,
  referenceNumber,
  number,
  deliveryDate,
  deliveryTimeSlot,
  deliveryMethod,
}) => {
  const { t, i18n } = useTranslation("vendor");
  const { classes } = useOrderDetailsStyles();

  const isPickupOrder = deliveryMethod === OrderTypesEnum.PickupFromPharmacy;
  const deliveryDateString = moment(deliveryDate?.toString(), "YYYY-MM-DD");

  return (
    <>
      <Typography className={classes.title}>
        {t(isPickupOrder ? "Pickup Time" : "Delivery Time")}:{" "}
        <Typography className={classes.title} fontWeight={"bold"} component={"span"}>
          {deliveryDate ? (
            <>
              {`
              ${deliveryDateString.locale(i18n.language).format(i18n.language === "ar" ? "dddd" : "ddd")},
              ${deliveryDateString.locale("en").format(i18n.language === "ar" ? "DD/MM" : "MM/DD")}
              ${formatDeliveryTimeSlot(deliveryTimeSlot?.startTime, deliveryTimeSlot?.endTime, i18n.language)}
              `}
            </>
          ) : (
            <>{isPickupOrder ? t("Fast Pickup ( Within 4 Hours )") : t("Fast Delivery ( Within 4 Hours )")}</>
          )}
        </Typography>
      </Typography>
      {timeOutComponent}
      <Typography className={classes.title}>
        {t("Order ID")}: {number}
      </Typography>

      {referenceNumber && (
        <Typography className={classes.title}>
          {t("eRx Number")} : {referenceNumber}
        </Typography>
      )}
    </>
  );
};
