import { darkBlue, darkRed, inputBorderColorDefault, makeStyles } from "@health/ui";

export const usePickupVerificationButtonStyle = makeStyles()(() => ({
  confirm: {
    margin: "10px 0px",
    borderRadius: 15,
    "--ReactInputVerificationCode-itemWidth": "70px",
    "--ReactInputVerificationCode-itemHeight": "70px",
    "--ReactInputVerificationCode-itemSpacing": "2rem",
    display: "flex",
    justifyContent: "center",
    "& .ReactInputVerificationCode__item": {
      borderRadius: 10,
      fontSize: 40,
      border: `2px solid ${inputBorderColorDefault}`,
      boxShadow: "none",
    },
    "& .ReactInputVerificationCode__item.is-active": {
      boxShadow: "none",
      border: `2px solid ${darkBlue}`,
    },
  },
  confirmError: {
    "& .ReactInputVerificationCode__item": {
      borderRadius: 10,
      color: darkRed,
      border: `2px solid ${darkRed} !important`,
      boxShadow: "none",
    },
    "& .ReactInputVerificationCode__item.is-active": {
      boxShadow: "none",
      border: `2px solid ${darkRed}`,
    },
  },
}));
