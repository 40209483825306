import { useTranslation } from "@health/i18n";
import { FormActions, FormCard, Grid, PageWrapper, WorkingHoursCard } from "@health/ui";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import { formGirdSpacing } from "shared/constants";
import { useBranchFormHook } from "./Branch.hook";
import { BranchFormProps } from "./BranchesForm.types";
import { GeneralInformation } from "./components/GeneralInformation/BranchesGeneralInfo.component";
import Address from "./components/Location/BranchesAddress.component";

const BranchForm: FC<BranchFormProps> = ({ isEditMode, branch, onSubmit: handleOnSubmit, loading, errors }) => {
  const { t } = useTranslation("vendor");

  const {
    methods,
    workingHours,
    onSubmit,
    handleSubmit,
    handleCancel,
    setWorkingHours: handleWorkingHours,
  } = useBranchFormHook({
    branch,
    handleOnSubmit,
  });

  const doYouHaveBranch = isEditMode ? branch != undefined : true;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions hasCancel hasSave={!isEditMode} hasEdit={isEditMode} onSave={handleSubmit(onSubmit)} onNavigation={handleCancel} />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("General Information")} loading={loading} doYouHaveData={doYouHaveBranch}>
                <GeneralInformation defaultValue={branch} errorsInput={errors} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Working Hours")} loading={loading} doYouHaveData={doYouHaveBranch}>
                <WorkingHoursCard workingHours={workingHours} onChange={handleWorkingHours} withTitle={false} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Address And Location")} loading={loading} doYouHaveData={doYouHaveBranch}>
                <Address defaultValues={branch} errorsInput={errors} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};

export default BranchForm;
