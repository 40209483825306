import { formGirdSpacing } from "@health/common";
import { VendorWebhook } from "@health/queries";
import { Button, Card, Grid, Typography } from "@health/ui";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import Information from "./Information.component";
import { useVendorWebHookFormHooks } from "./VendorWebHookForm.hooks";

const VendorWebHookForm: FC = () => {
  const { t, methods, onSubmit, webHook, loading, isSubmitting } = useVendorWebHookFormHooks();

  return (
    <Card
      elevation={0}
      sx={{
        padding: "15px",
        boxShadow: "none",
      }}
    >
      <Typography fontSize='21px'>{t("Provider Webhook")}</Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={formGirdSpacing} mb={10} mt='2px'>
            <Grid item width='100%'>
              {!loading && <Information webHook={webHook?.vendorWebHook as VendorWebhook} loading={Boolean(loading)} />}
            </Grid>
          </Grid>
          <Button variant={"contained"} color={"primary"} type='submit' disabled={isSubmitting}>
            {t("Save")}
          </Button>
        </form>
      </FormProvider>
    </Card>
  );
};

export default VendorWebHookForm;
