import { InMemoryCacheConfig } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const cacheUtils: InMemoryCacheConfig = {
  typePolicies: {
    User: {
      merge: true,
      fields: {
        lastMessage: {
          merge: false,
        },
      },
    },
    Branch: {
      merge: true,
      fields: {
        messages: relayStylePagination(["id", "customerId"]),
      },
    },
    Query: {
      fields: {
        chatBranchesList: relayStylePagination(["filter"]),
        chatCustomersList: relayStylePagination(["branch"]),
        vendors: relayStylePagination(),
        categories: relayStylePagination(),
      },
    },
  },
};
