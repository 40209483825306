import { formGirdBreakPoints } from "@health/common";
import { useTranslation } from "@health/i18n";
import { AppRoleTypes } from "@health/queries";
import { AutocompleteController, Grid } from "@health/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FromProps } from "./UserForm.types";

export const AppRoleComponent: FC<FromProps> = ({ defaultValues }) => {
  const { t } = useTranslation("vendor");
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();

  const appRoles = Object.keys(AppRoleTypes).map(key => ({ value: AppRoleTypes[key], name: key }));
  const appRoleValue = appRoles?.find(item => item.value === defaultValues?.appRole);

  return (
    <Grid item {...formGirdBreakPoints}>
      <AutocompleteController
        options={appRoles || []}
        ControllerProps={{
          name: "appRole",
          control: control,
          defaultValue: appRoleValue || appRoles[1],
        }}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        getOptionLabel={option => t(option.name)}
        TextFieldProps={{
          placeholder: t("App Role"),
          error: Boolean(formErrors?.appRole?.message),
          helperText: t(formErrors?.appRole?.message),
        }}
      />
    </Grid>
  );
};
