import { formatGraphQLError, useGetTradeDrugQuantityQuery } from "@health/queries";
import { useState } from "react";

export const useMedicationsMappingHook = (medications, referenceNumber, patientIdentifier, open) => {
  const [acceptedMedications, setAcceptedMedications] = useState<any>([]);
  const [errors, setErrors] = useState<string>("");

  const { data, loading } = useGetTradeDrugQuantityQuery({
    variables: {
      erxReference: referenceNumber,
      patientIdentifier,
    },
    fetchPolicy: "no-cache",
    skip: !referenceNumber || !patientIdentifier || !open,
    onError: ({ graphQLErrors }) => {
      const formattedErrors: string = formatGraphQLError(graphQLErrors);
      setErrors(formattedErrors);
      console.error("getTradeDrugQuantity error", formattedErrors);
    },
  });

  const tradeDrug = data?.getTradeDrugQuantity?.map((item, index) => {
    const medication = medications?.find(medication => medication?.code === item?.code);
    return {
      ...item,
      lineId: medication?.lineId + index,
      id: medication?.id,
    };
  });

  const medicationsData = acceptedMedications?.map(item => ({
    id: item?.id,
    tradeCode: item?.tradeCode,
    quantity: item?.quantity ? Number(item?.quantity) : Number(item?.quantityItem),
  }));

  const handleValue = (e, value, lineId) => {
    const values = acceptedMedications?.map(item =>
      item.lineId === lineId
        ? { ...item, tradeCode: value.code, quantityItem: value.quantity, quantity: value.quantity, display: value?.display }
        : item
    );
    setAcceptedMedications([...values]);
  };

  const handleQuantity = (e, lineId) => {
    const values = acceptedMedications?.map(item =>
      item.lineId === lineId ? { ...item, quantity: e.target.value, quantityItemSave: item?.quantityItem, isTouch: true } : item
    );
    setAcceptedMedications([...values]);
  };

  const handleSelectedValue = (e, lineId) => {
    const values = tradeDrug
      ?.filter(item => item.lineId === lineId)
      .map(item => ({
        id: item?.id,
        tradeCode: item?.tradeDrugs?.[0]?.code,
        display: item?.tradeDrugs?.[0]?.display,
        quantityItem: item?.tradeDrugs?.[0]?.quantity,
      }))[0];
    let newArray = [...acceptedMedications, { lineId, isChecked: e.target.checked, ...values }];
    if (acceptedMedications.some(item => item?.lineId === lineId)) {
      newArray = newArray.filter(medication => medication?.lineId !== lineId);
    }
    setAcceptedMedications(newArray);
  };

  return {
    loading,
    acceptedMedications,
    medicationsData,
    tradeDrug,
    errors,
    handleValue,
    handleQuantity,
    handleSelectedValue,
  };
};
