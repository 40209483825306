import { useOpenStateController } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Maybe, WorkingHour } from "@health/queries";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CustomDialog,
  CustomIcon,
  formatTime,
  getDayString,
  Grid,
  makeStyles,
  ShowButton,
  TextField,
  Typography,
} from "@health/ui";
import React, { FC } from "react";

export const useWorkingHoursStyles = makeStyles()(() => ({
  input: {},
  hiddenInput: {
    visibility: "hidden",
  },
}));

type WorkingHoursBranchProps = {
  workingHours?: Maybe<Maybe<WorkingHour>[]> | null;
};

export const WorkingHoursBranch: FC<WorkingHoursBranchProps> = props => {
  const { workingHours } = props;
  const { t } = useTranslation("vendor");
  const { open, handleOpen, handleClose } = useOpenStateController();
  const { classes } = useWorkingHoursStyles();

  return (
    <React.Fragment>
      <ShowButton onClick={handleOpen} />
      {open && (
        <CustomDialog
          open={open}
          onCloseModal={handleClose}
          title={t("Branch Working Hours Info")}
          DialogContentProps={{
            sx: { minHeight: !workingHours?.length ? " 50px" : "inherit" },
          }}
        >
          {!workingHours?.length ? (
            <Typography textAlign='center' fontFamily='Airbnb Cereal App Medium' fontSize='18px' variant='body1'>
              {t("There is no working hours for this branch")}
            </Typography>
          ) : (
            <Accordion square elevation={0}>
              <AccordionSummary expandIcon={<CustomIcon icon={"expandArrow"} />} aria-controls='WorkingHours' id='WorkingHours'>
                <Typography variant='h6'>{t("Working Hours for Branch")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {workingHours?.map(workingHours =>
                    workingHours?.openTimeRanges?.map((timeRange, index: number) => (
                      <React.Fragment key={String(workingHours?.day) + index}>
                        <Grid item xs={4}>
                          <TextField
                            disabled
                            fullWidth
                            size='small'
                            label={t("Day")}
                            value={t(getDayString(String(workingHours?.day)))}
                            className={index !== 0 ? classes.hiddenInput : classes.input}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            disabled
                            fullWidth
                            size='small'
                            placeholder={t(timeRange?.openTime)}
                            label={t("Open Time")}
                            value={formatTime(timeRange?.openTime)}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            disabled
                            fullWidth
                            size='small'
                            placeholder={t(timeRange?.closeTime)}
                            label={t("Close Time")}
                            value={formatTime(timeRange?.closeTime)}
                          />
                        </Grid>
                      </React.Fragment>
                    ))
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </CustomDialog>
      )}
    </React.Fragment>
  );
};

WorkingHoursBranch.defaultProps = {
  workingHours: undefined,
};
