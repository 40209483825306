import { formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@health/common";
import { useTranslation } from "@health/i18n";
import { CustomDatePicker, Grid, TextField } from "@health/ui";
import moment from "moment";
import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FromProps } from "./UserForm.types";

export const HealthLicense: FC<FromProps> = props => {
  const { defaultValues, errors } = props;
  const { t } = useTranslation("vendor");
  const { control, register, setValue, watch } = useFormContext();

  useEffect(() => {
    setValue("healthLicenseStartDate", defaultValues ? defaultValues?.healthLicenseStartDate : moment());
    setValue("healthLicenseEndDate", defaultValues ? defaultValues?.healthLicenseEndDate : moment());
  }, [defaultValues]);
  const licenseValue = watch("healthLicenseNumber");
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Health License Number")}
          error={Boolean(errors?.healthLicenseNumber?.message)}
          helperText={t(errors?.healthLicenseNumber?.message)}
          {...register("healthLicenseNumber")}
          defaultValue={defaultValues?.healthLicenseNumber ?? ""}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <CustomDatePicker
          control={control}
          label={t("Health License Start Date")}
          placeholder={t("Health License Start Date")}
          {...register("healthLicenseStartDate", {
            required: getRequiredValidation(t, Boolean(licenseValue)),
          })}
          error={Boolean(errors?.healthLicenseStartDate)}
          helperText={t(errors?.healthLicenseStartDate?.type)}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <CustomDatePicker
          control={control}
          {...register("healthLicenseEndDate", {
            required: getRequiredValidation(t, Boolean(licenseValue)),
          })}
          label={t("Health License End Date")}
          error={Boolean(errors?.healthLicenseEndDate)}
          helperText={t(errors?.healthLicenseEndDate?.type)}
          placeholder={t("Health License End Date")}
        />
      </Grid>
    </Grid>
  );
};
