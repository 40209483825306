import { useTranslation } from "@health/i18n";
import { Typography } from "@health/ui";
import React, { FC } from "react";
import { useMedicationsSectionStyles } from "../Medications.styles";

export const MedicationsInfo: FC<{ medication }> = ({ medication }) => {
  const { t, i18n } = useTranslation("vendor");
  const { classes } = useMedicationsSectionStyles();

  return (
    <>
      <Typography className={classes.list}>
        {t("Instructions")} : {(i18n.language === "ar" && medication?.arabicInstructions) || medication?.instructions || "-"}
      </Typography>

      <Typography className={classes.list}>
        {t("Refills")} : {medication?.refills}
      </Typography>

      <Typography className={classes.list}>
        {t("Duration")} : {medication?.duration || "-"}
      </Typography>

      <Typography className={classes.list}>
        {t("ROA")} : {(i18n.language === "ar" && medication?.roaArName) || medication?.roaEnName || "-"}
      </Typography>
      <Typography className={classes.list}>
        {t("Code")} : {medication?.code}
      </Typography>
    </>
  );
};
