import { Box, OrdersCard } from "@health/ui";
import { FC } from "react";
import { Loading } from "./Loading";

type OrdersLoadingProps = {
  color: string;
  loading: boolean;
};

export const OrdersLoading: FC<OrdersLoadingProps> = props => {
  const { children, color, loading } = props;

  return (
    <>
      {loading ? (
        <Box margin='10px'>
          <OrdersCard color={color}>
            <Loading color={color} />
          </OrdersCard>
        </Box>
      ) : (
        children
      )}
    </>
  );
};
