import { i18n, useTranslation } from "@health/i18n";
import { CustomDialog, EmptyCard, Grid, ShowButton, Typography } from "@health/ui";
import { Fragment, useState } from "react";

const ShowProductStocks = ({ row }: any) => {
  const { t } = useTranslation("vendor");
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(!open);
  };
  return (
    <CustomDialog open={open} title={t("Product Stocks")} onCloseModal={handleClickOpen} button={<ShowButton onClick={handleClickOpen} />}>
      {row?.vendorProductVariant?.stocks == null ? (
        <EmptyCard title={t("There is No Stocks")} />
      ) : (
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <Typography fontFamily='Airbnb Cereal App Medium' color='primary' gutterBottom>
              {t("Branch")}
            </Typography>
          </Grid>
          <Grid xs={6} item>
            <Typography fontFamily='Airbnb Cereal App Medium' color='primary' gutterBottom>
              {t("Quantity")}
            </Typography>
          </Grid>
          {row?.vendorProductVariant?.stocks?.map(stocks => (
            <Fragment key={stocks.branch?.id}>
              <Grid xs={6} item>
                <Typography color='#1B2346'>
                  {i18n?.language === "en" ? stocks?.branch?.name : stocks?.branch?.nameAr ?? stocks?.branch?.name}
                </Typography>
              </Grid>
              <Grid xs={6} item>
                <Typography color='#1B2346'>{stocks?.quantity}</Typography>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      )}
    </CustomDialog>
  );
};
export default ShowProductStocks;
