import { makeStyles, white } from "@health/ui";

export const useColumnHeaderStyles = makeStyles<{ color: string }>()((_, { color }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginInline: 10,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    fontSize: 18,
    fontFamily: "Airbnb Cereal App Medium, Arab Kufi Medium",
    color: color,
  },
  count: {
    color: white,
    fontSize: 14,
    borderRadius: 100,
    marginInline: 10,
    paddingInline: 12,
    height: 22,
    display: "flex",
    justifyContent: "center",
    background: color,
    fontFamily: "Airbnb Cereal App Medium, Arab Kufi Medium",
  },
}));
