import { ChangePasswordPage as ChangePassword } from "@health/entry";
import { useNavigate } from "react-router-dom";

export const ChangePasswordPage = () => {
  const navigate = useNavigate();

  const handleRedirectLogin = () => {
    navigate("/login");
  };

  return <ChangePassword onRedirectLogin={handleRedirectLogin} />;
};
