import { LanguageCodeEnum, VendorAdminInput } from "@health/queries";
import { getDefaultWeekWorkingDays } from "@health/ui";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const useBranchFormHook = ({ branch, handleOnSubmit }) => {
  const navigate = useNavigate();
  const [workingHours, setWorkingHours] = useState<any>(getDefaultWeekWorkingDays());

  const { ...methods } = useForm<
    VendorAdminInput & { userName: string | null | undefined; password: string | null | undefined; token: string | null | undefined }
  >({
    mode: "onChange",
  });
  const {
    setValue,
    handleSubmit,
    formState: { errors: errorsForm },
  } = methods;

  useEffect(() => {
    branch?.address?.coordinates && setValue("addressInput.coordinates", branch?.address?.coordinates);
    branch?.workingHours && setWorkingHours(branch?.workingHours);
  }, [branch]);
  const onSubmit = (data: any) => {
    const {
      healthLicenseStartDate,
      healthLicenseEndDate,
      addressInput,
      arabicCityName,
      arabicStreetAddress,
      acceptsDelivery,
      acceptsPickup,
      ...rest
    } = data;
    delete rest.location;
    const request = {
      ...rest,
      acceptsDelivery,
      acceptsPickup,
      healthLicenseStartDate:
        typeof healthLicenseStartDate == "string" ? healthLicenseStartDate : healthLicenseStartDate?.toISOString().substring(0, 10),
      healthLicenseEndDate:
        typeof healthLicenseEndDate == "string" ? healthLicenseEndDate : healthLicenseEndDate?.toISOString().substring(0, 10),
      type: data?.type?.value,
      addressInput: {
        area: addressInput.area,
        city: addressInput?.city.id,
        phone: addressInput?.phone,
        coordinates: {
          lat: addressInput?.coordinates?.lat,
          lng: addressInput?.coordinates?.lng,
        },
        translations: [
          {
            languageCode: LanguageCodeEnum.Ar,
            streetAddress2: arabicCityName,
            area: addressInput.area,
            streetAddress1: arabicStreetAddress,
          },
          {
            languageCode: LanguageCodeEnum.En,
            streetAddress1: addressInput.streetAddress1,
            area: addressInput.area,
            streetAddress2: addressInput.streetAddress2,
          },
        ],
      },
      workingHours: workingHours?.map((item: any) => ({
        day: item.day,
        openTimeRanges: item.openTimeRanges.map((data: any) => ({
          closeTime: data.closeTime,
          openTime: data.openTime,
        })),
      })),
    };

    handleOnSubmit(request);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return {
    methods,
    errorsForm,
    handleSubmit,
    workingHours,
    setWorkingHours,
    onSubmit,
    handleCancel,
  };
};
