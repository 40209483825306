import { darkGrey, makeStyles } from "@health/ui";

export const useOrdersFromCheckoutStyles = makeStyles()(theme => ({
  title: {
    color: darkGrey,
    fontSize: 12,
  },
  box: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  avatar: {
    height: 65,
    width: 65,
    borderRadius: 10,
    [theme.direction === "rtl" ? "marginLeft" : "marginRight"]: 10,
  },
  text: {
    fontSize: 14,
  },
}));
