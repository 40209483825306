import { darkBlue, darkGrey, makeStyles } from "@health/ui";

export const useOrderSectionStyles = makeStyles<{ color?: string; hasBG?: boolean; hasAction?: boolean; open?: boolean }>()(
  (_, { color, hasBG, hasAction, open }) => ({
    title: {
      fontSize: 14,
      color: color ?? darkBlue,
      fontFamily: "Airbnb Cereal App Medium, Arab Kufi Medium",
    },
    subTitle: {
      fontSize: 14,
      color: darkGrey,
    },
    alerts: {
      fontSize: 14,
    },
    line: {
      marginTop: 5,
      marginBottom: 5,
    },
    section: {
      padding: 10,
      paddingInline: open ? 30 : 10,
      paddingBottom: !hasBG && hasAction ? 0 : 10,
      borderTop: hasBG ? `1px solid #DFE8F2` : "none",
      borderBottom: hasBG ? `1px solid #DFE8F2` : "none",
      background: hasBG ? "#F9FAFB" : "white",
    },
  })
);
