import { useTranslation } from "@health/i18n";
import { useOrderPageContext } from "../state";
import { mapOrderToActionByStatus, mapOrderToColumnByStatus } from "./mapper";

export const useOrderMapper = () => {
  const { t } = useTranslation("vendor");
  const { time } = useOrderPageContext();

  const mapOrderToData = order => mapOrderToColumnByStatus(order, time, t);
  const mapOrderToAction = order => mapOrderToActionByStatus(order, time, t);

  return {
    mapOrderToData,
    mapOrderToAction,
  };
};
